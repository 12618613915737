import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback} from 'react'
import "../css/tracker.css";
import {useStore} from "./store";
import {mtrack} from "../utils/helpers";


//export function TrackerHelp(props) {
	export const TrackerHelp = React.memo((props) => {


	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const stations = new Array(props.stations).fill(1);

	const [hovered, setHovered] = useState(0)
	const [newClick, setNewClick] = useState(false)

	const hoveredHelp = useStore(state => state.hoveredHelp);
	const setHoveredHelp = useStore(state => state.setHoveredHelp);

	const handleClick = (e) => {
		e.stopPropagation();
//		mtrack('tracker '+ parseInt(e.currentTarget.id))
//		console.log(e.currentTarget.id)
		setHovered(parseInt(e.currentTarget.id))
		setNewClick(true)
	}

	useEffect(()=>{
		if (newClick) {
			setHoveredHelp(hovered)
		}
		setNewClick(false)
	}, [newClick])

	useEffect(()=>{
//		console.log("hovhelp",hoveredHelp)
			setHovered(hoveredHelp)
	}, [hoveredHelp])

	const offset = 20;

	return (
		<div className={'trackerContainer'}>
			<svg x={0} y={0} width={offset/2+offset*stations.length} height={'20px'}>
				{stations.map((d, i) => {
					return (
						<circle className={'trackcircle'} key={"helpid"+i} id={i} onClick={handleClick} cx={offset+i * offset} cy={10} r={8}
						        fill={ i === hovered ? 'white' : 'black'}
								strokeWidth={2}
						        stroke='white'
						></circle>
					)
				})}
			</svg>
		</div>
	)

})

//export function Tracker(props) {
	export const Tracker = React.memo((props) => {

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const stations = new Array(props.stations).fill(1);

	const [hovered, setHovered] = useState(0)
	const [newClick, setNewClick] = useState(false)

	const hoveredModel3D = useStore(state => state.hoveredModel3D);
	const setHoveredModel3D = useStore(state => state.setHoveredModel3D);

	const handleClick = (e) => {
		e.stopPropagation();
		mtrack('model3D circle: '+ parseInt(e.currentTarget.id))
//		console.log("++ click: "+e.currentTarget.id)
		setHovered(parseInt(e.currentTarget.id))
		setNewClick(true)
	}

	useEffect(()=>{
		if (newClick) {
			setHoveredModel3D(hovered)
		}
		setNewClick(false)
	}, [newClick])

	useEffect(()=>{
		setHovered(hoveredModel3D)
	}, [hoveredModel3D])

	const offset = 20;

	return (
		<div className={'trackerContainer'}>
			<svg x={0} y={0} width={offset/2+offset*stations.length} height={'20px'}>
				{stations.map((d, i) => {
					return (
						<circle className={'trackcircle'} key={"helpid"+i} id={i} onClick={handleClick} cx={offset+i * offset} cy={10} r={8}
						        fill={ i === hovered ? 'white' : 'black'}
						        strokeWidth={2}
						        stroke='white'
						></circle>
					)
				})}
			</svg>
		</div>
	)

})
