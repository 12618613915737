import React, {Suspense, useMemo, useRef, useState} from "react";
import {Line, Text} from "@react-three/drei";
import * as THREE from "three";
import {Canvas, useFrame, useThree} from "@react-three/fiber";
import {useStore} from "./store";
import {rescaleYears} from "../utils/helpers";
import Epochs from "./epochs";
import * as d3 from "d3";

const {zerooffset, firstoffset, secondoffset, thirdoffset, fourthoffset, fifthoffset, sixtoffset, seventhoffset} = useStore.getState();

export const TimeLine = React.memo(() => {

		const isMo = useStore(state => state.isMobile);
		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);

//	const arrSize = 12000;
		const arrSize = 2000;
		const size3 = 3 * arrSize;

		const yearTicks = isMo2 ? 2.85 : 2.6;
		const majorTicks = isMo2 ? -0.25 : -0.25;
		const minorTicks = -0.125;

		const tempObject = new THREE.Object3D()
		const tempColor = new THREE.Color()

		const box = useMemo(() => new Float32Array(size3));
		const colorArray = useMemo(() => Float32Array.from(new Array(arrSize).fill().flatMap((_, i) => tempColor.set("white").toArray())), [])

		function Lines() {

			const [init, setInit] = useState(false);

			const meshLinesRef = useRef()
			useFrame((state) => {
					if (meshLinesRef.current && !meshLinesRef.current.instanceMatrix) {
//					setInit(false);
					}
					if (meshLinesRef.current && !init) {
//						console.log("init:", init);
						let i = 0
						let x, y, z;
						let scaleX = 0;
						for (let j = 0; j < 3 * arrSize; j = j + 3) {
							const id = i++
							x = box[j];
							y = box[j + 1];
							z = box[j + 2] + 0.001;
							tempObject.position.set(x, y, z)
//		          			tempColor.set('white')

							// trigger minor ticks by their x value, which is below 1
							// position without values have z = 0
							if (z !== 0) {
								if (x > 1) scaleX = isMo2 ?  7.6 * 0.79 : 7.6;
								if (x === minorTicks) scaleX = isMo2 ? 0.2 : 0.21;
								if (x === majorTicks) scaleX = isMo2 ? 0.2 : 0.5;
							}

							tempObject.scale.set(scaleX, 0.01, 0.007)
							tempObject.updateMatrix()
							meshLinesRef.current.setMatrixAt(id, tempObject.matrix);
						}
						meshLinesRef.current.instanceMatrix.needsUpdate = true
						meshLinesRef.current.geometry.attributes.color.needsUpdate = true
						setInit(true);
					}
				}
			)

			return (
				<instancedMesh ref={meshLinesRef} args={[null, null, arrSize]}>
					<boxBufferGeometry>
						<instancedBufferAttribute attachObject={['attributes', 'color']} args={[colorArray, 3]}/>
					</boxBufferGeometry>
					<meshBasicMaterial/>
				</instancedMesh>
			)
		}

		function LineCloud() {

//			console.log("LineCloud called");

			let holdoffset = 0;
			let i;

			// decades
			var ticksArray = [[2020], [1950], [1850], [1750],
				[-10000],
//				[-50000],
				[-100000],
//				[-500000],
				[-1000000],
//				[-5000000],
				[-10000000],
				[-100000000],
				[-200000000],
				[-300000000],
				[-400000000],
				[-500000000],

			];
			for (i = -0; i < 2030; i = i + 100) ticksArray.push([i]);

			// centuries
			for (i = -10000; i < 0; i = i + 1000) ticksArray.push([i]);

			// major ticks
			var majorTicksArray = [];
			for (i = 0; i < 2025; i = i + 10) majorTicksArray.push([i]);

			// minor ticks
			var minorTicksArray = [];
			for (i = 0; i < 2020; i = i + 10) minorTicksArray.push([i]);

			var arrList = ticksArray.map((d, i) => {
				let year = rescaleYears(d[0]);
				box[i * 3] = yearTicks;  // middle of the box
				box[i * 3 + 1] = year;  // this is rescaled
				box[i * 3 + 2] = 2; // height offset of the box
				return null
			})

			holdoffset = ticksArray.length;
			var majorTicksList = majorTicksArray.map((d, i) => {
				let year = rescaleYears(d[0]);
				box[(i + holdoffset) * 3] = majorTicks;  // middle of the box
				box[(i + holdoffset) * 3 + 1] = year;  // this is rescaled
				box[(i + holdoffset) * 3 + 2] = 2; // height offset of the box
				return null
			})

			holdoffset = ticksArray.length + majorTicksArray.length;
			var minorTicksList = minorTicksArray.map((d, i) => {
				let year = rescaleYears(d[0]);
				box[(i + holdoffset) * 3] = minorTicks;  // middle of the box
				box[(i + holdoffset) * 3 + 1] = year;  // this is rescaled
				box[(i + holdoffset) * 3 + 2] = 2; // height offset of the box
				return null
			})

//			console.log("count timelines: ", holdoffset + minorTicksArray.length)
		}

		const YearsLabel = (args) => {

//			console.log(args.count);

			var years = [];
			var pos = [];

			var predefinedYears = [
				{ year: -10000,    offset:  -1.05},
//				{ year: -50000,    offset:  -1.10},
				{ year: -100000,    offset: -1.15},
//				{ year: -500000,    offset: -1.15},
				{ year: -1000000,   offset: -1.45},
//				{ year: -5000000,   offset: -1.45},
				{ year: -10000000,  offset: -1.65},
				{ year: -100000000, offset: -1.65},
				{ year: -200000000, offset: -1.85},
				{ year: -300000000, offset: -1.85},
				{ year: -400000000, offset: -1.85},
				{ year: -500000000, offset: -1.85},
			]

			predefinedYears.map((d) => {
				years.push(d.year);
				pos.push([d.offset, rescaleYears(d.year), 2.13]);
			})

			for (let x = zerooffset; x > firstoffset; x = x - 10) {
				years.push(x);
				pos.push([isMo2 ? -0.50 : -0.95, rescaleYears(x), 2.13]);
			}

			for (let x = firstoffset; x > secondoffset; x = x - 100) {
				years.push(x);
				pos.push([isMo2 ? -0.50 : -0.95, rescaleYears(x), 2.13]);
			}

			for (let x = secondoffset; x > thirdoffset; x = x - 1000) {
				years.push(x);
				pos.push([isMo2 ? -0.50 : -0.95, rescaleYears(x), 2.13]);
			}

//			for (let x = -thirdoffset; x >= fourthoffset; x = x - 1000000) {
//				years.push(x);
//				pos.push([-0.95, rescaleYears(x), 2.13]);
//			}
//
//			for (let x = -fourthoffset; x >= fifthoffset; x = x - 100000000) {
//				years.push(x);
//				pos.push([-1.35, rescaleYears(x), 2.13]);
//			}
//
//			for (let x = -fifthoffset; x >= lastoffset; x = x - 100000000) {
//				years.push(x);
//				pos.push([-1.35, rescaleYears(x), 2.13]);
//			}


			const niceLabels = (year) => {
				if (year > 0) return year;
//				if (year === 0) return niceLabels(-1);
				if (year === 0) return 0;
				if (year < 0) {
					let newYear;

					if (year < -1000000) {
						newYear = ((year/1000000 * -1).toLocaleString('de')) + " Millionen Jahre v. Chr.";
					}

					else if (year === -1000000) {
						newYear = ((year/1000000 * -1).toLocaleString('de')) + " Million Jahre v. Chr.";
					}

					else if (year <= -10000) {
						newYear = ((year * -1).toLocaleString('de')) + " v. Chr.";
					} else {
						newYear = (year * -1) + " v. Chr.";
					}
					return newYear
				}
			}

			var TextList = pos.map((d, i) => {
				return <Text
					key={"t_" + i}
					position={d}
					rotation={[isMo2 ? 0 : Math.PI / 2, 0 , isMo2 ? Math.PI / 2*1 : 0]}
					fontSize={isMo2 ? 0.32 : 0.25}

				> {niceLabels(years[i])}</Text>;
			})

			return (
				<> {TextList} </>
			);
		};


		const Road = () => {

			let points = [];

			var xx = d3.scaleLinear().domain([0,6]).range([0,7]);

			// long lines
			for (let j = 0; j < 6; j++) {
				points.push([xx(j), 0, 2], [xx(j), rescaleYears(-499999999), 2 - 0.01])
			}

			var hold;

			var LinesList = points.map((d, index) => {
				if (index % 2 === 0) {
					hold = d;
				} else {
					return (
						<Line key={"l1" + index} points={[hold, d]} color="white" lineWidth={0.45} dashed={false}/>
					)
				}
				return null
			})
			return (
				<> {LinesList} </>
			)
		}

		LineCloud();

		return (
			<Suspense fallack={null}>
				<group position={[-3, -20, 0]}>
					<Lines></Lines>
					<YearsLabel count={120}/>
					{!isMo1 && <Epochs></Epochs> }
					<Road></Road>
				</group>
			</Suspense>
		)
	}
)
