import "../css/help.css";
import React, {Suspense, useEffect, useLayoutEffect, useState, useRef} from "react";
import {useStore} from "./store";
import {useWindowDimensions} from "../utils/hooks";
import {TrackerHelp} from "./tracker";
import {mtrack} from "../utils/helpers";


//export function Help(props) {
export const Help = React.memo((props) => {

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const [hovered, setHovered] = useState(false);
	const [isSunburst, setIsSunburst] = useState(0);

	const setShowMediaOverlay = useStore(state => state.setShowMediaOverlay);

	const clickedHelp = useStore(state => state.clickedHelp);
	const setClickedHelp = useStore(state => state.setClickedHelp);

	const hoveredHelpTimeline = useStore(state => state.hoveredHelpTimeline);
	const setHoveredHelpTimeline = useStore(state => state.setHoveredHelpTimeline);

	const hoveredHelpSunburst = useStore(state => state.hoveredHelpSunburst);
	const setHoveredHelpSunburst = useStore(state => state.setHoveredHelpSunburst);

	const hoveredHelp = useStore(state => state.hoveredHelp);
	const setHoveredHelp = useStore(state => state.setHoveredHelp);

//	console.log("hovered Help: ====> ", hoveredHelp, hoveredHelpTimeline, hoveredHelpSunburst)

	let bg = "#000000";


	let contentTimeline = [
		{
			id: 0,
			x: width / 3,
			y: height / 2.5,
			r: height / 4,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width / 1.8,
			textY: height / 3,
			desc: 'In der Zeitleiste finden Sie ausgewählte Objekte aus Museen aller Salzburger Bezirke.'
		},
		{
			id: 1,
			x: width / 2,
			y: height / 1.2,
			r: height / 2,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width / 2.7,
			textY: height / 6,
			desc: 'Bewegen Sie die Maus auf die Würfel, um eine Vorschau und weiterführende Informationen zu erhalten.\n'
		},
		{
			id: 2,
			x: width / 1.4,
			y: height / 100,
			r: height / 8,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width / 1.7,
			textY: height / 4,
			desc: 'Nutzen Sie das Scrollrad Ihrer Maus oder die Pfeile am oberen Bildschirmrand, um sich in der Zeit vorwärts und rückwärts zu bewegen.\n'
		},
		{
			id: 3,
			x: width * 1.05,
			y: -height / 20,
			r: width / 2.5,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width / 3,
			textY: height / 3,
			desc: 'Wählen Sie ein Kreissegment, um Objekte einer bestimmten Kategorie hervorzuheben.\n'
		},
		{
			id: 4,
			x: width / 6,
			y: 0,
			r: height / 5,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width / 3,
			textY: height / 4,
			desc: 'Lassen Sie sich zu verschiedenen Themen durch die Salzburger Regionalmuseen führen!\n'
		},
		{
			id: 5,
			x: width,
			y: height / 1.8,
			r: width / 8,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width / 1.8,
			textY: height / 1.8,
			desc: 'Klicken Sie auf den Pfeil nach rechts, um zu einer alternativen Ansicht zu gelangen.\n'
		},
	]

	let contentTimelineMobilePortrait = [
		{
			id: 0,
			x: width * 0.3,
			y: height * 0.48,
			r: height * 0.25,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.2,
			textY: height * 0.03,
			desc: 'In der Zeitleiste finden Sie ausgewählte Objekte aus Museen aller Salzburger Bezirke.'
		},
		{
			id: 1,
			x: width * 0.5,
			y: height * 0.48,
			r: height * 0.25,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.2,
			textY: height * 0.03,
			desc: 'Wählen Sie einen Würfel aus, um eine Vorschau und weiterführende Informationen zu erhalten.\n'
		},
		{
			id: 2,
			x: width * 0.9,
			y: height / 100,
			r: height / 8,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.2,
			textY: height * 0.2,
			desc: 'Wischen Sie nach oben und unten oder benutzen Sie die Pfeile am oberen Bildschirmrand, um sich in der Zeit vorwärts und rückwärts zu bewegen.\n'
		},
		{
			id: 3,
			x: width * 0.6,
			y: 0,
			r: height / 5,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.2,
			textY: height / 4,
			desc: 'Lassen Sie sich zu verschiedenen Themen durch die Salzburger Regionalmuseen führen!\n'
		},
		{
			id: 4,
			x: width,
			y: height * 0.45,
			r: width * 0.3,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.1,
			textY: height * 0.25,
			desc: 'Klicken Sie auf den Pfeil nach rechts, um zu einer alternativen Ansicht zu gelangen.\n'
		},
	]

	let contentTimelineMobileLandscape = [
		{
			id: 0,
			x: width * 0.4,
			y: height * 0.5,
			r: height * 0.5,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.67,
			textY: height / 3,
			desc: 'In der Zeitleiste finden Sie ausgewählte Objekte aus Museen aller Salzburger Bezirke.'
		},
		{
			id: 1,
			x: width / 2,
			y: height / 1.2,
			r: height / 2,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.35,
			textY: height * 0.05,
			desc: 'Wählen Sie einen Würfel aus, um eine Vorschau und weiterführende Informationen zu erhalten.\n'
		},
		{
			id: 2,
			x: width / 1.4,
			y: height * 0.03,
			r: height / 8,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.4,
			textY: height / 4,
			desc: 'Wischen Sie nach oben und unten oder benutzen Sie die Pfeile am oberen Bildschirmrand, um sich in der Zeit vorwärts und rückwärts zu bewegen.\n'
		},
		{
			id: 3,
			x: width * 0.2,
			y: 0,
			r: height / 5,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width / 3,
			textY: height / 4,
			desc: 'Lassen Sie sich zu verschiedenen Themen durch die Salzburger Regionalmuseen führen!\n'
		},
		{
			id: 4,
			x: width,
			y: height / 1.8,
			r: width / 8,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: width * 0.5,
			textY: height * 0.4,
			desc: 'Klicken Sie auf den Pfeil nach rechts, um zu einer alternativen Ansicht zu gelangen.\n'
		},
	]


	let contentSunburst = [
		{
			id: 0,
			circles: 2,
			x: width * 0.06,
			y: height * 0.05,
			r: width * 0.3,
			x1: width * 0.9,
			y1: height * 0.1,
			r1: width * 0.4,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 0.7,
			textY: height * 0.8,
			desc: 'In dieser Ansicht sehen Sie links die detaillierte Struktur der Sammlung und rechts eine Objektvorschau.'
		},
		{
			id: 1,
			circles: 1,
			x: width * 0.14,
			y: height * 0.9,
			r: height * 0.3,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 0.7,
			textY: height * 0.5,
			desc: 'Nutzen Sie die Suchfunktion, um nach bestimmten Objekten zu suchen oder nach bestimmten Museen zu filtern.'
		},
		{
			id: 2,
			circles: 1,
			x: width * 0.14,
			y: height * 0.1,
			r: height * 0.4,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width / 1.7,
			textY: height / 4,
			desc: 'Verharren Sie mit der Maus, um rechts nur Objekte aus dieser Kategorie zu sehen. Klicken Sie auf eine Kategorie, um tiefer in diese einzutauchen. Sobald Sie eingetaucht sind bringt Sie der Pfeil links oben wieder zurück.'
		},
		{
			id: 3,
			circles: 1,
			x: width * 0.7,
			y: height * 0.1,
			r: width * 0.3,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 0.9,
			textY: height * 0.5,
			desc: 'Klicken Sie auf ein Foto für eine vergrößerte Ansicht und zusätzliche Informationen.'
		},
	]

	let contentSunburstMobilePortrait = [
		{
			id: 0,
			circles: 2,
			x: width * 0.06,
			y: height * 0.05,
			r: width * 0.5,
			x1: width * 0.3,
			y1: height * 0.7,
			r1: width * 0.3,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 1.0,
			textY: height * 0.35,
			desc: 'In dieser Ansicht sehen Sie oben die detaillierte Struktur der Sammlung und unten eine Objektvorschau.'
		},
		{
			id: 1,
			circles: 1,
			x: width * 0.9,
			y: height * 0.4,
			r: height * 0.1,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 1.1,
			textY: height * 0.1,
			desc: 'Nutzen Sie die Filterfunktion, um nach bestimmten Objekten zu suchen oder nach bestimmten Museen zu filtern.'
		},
		{
			id: 2,
			circles: 1,
			x: width * 0.14,
			y: height * 0.1,
			r: height * 0.4,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width / 1.0,
			textY: height * 0.55,
			desc: 'Wählen Sie eine Kategorie, um unten dazu passende Objekte zu sehen. Sobald Sie tiefer eingetaucht sind bringt Sie der Pfeil links oben wieder zurück.'
		},
		{
			id: 3,
			circles: 1,
			x: width * 0.3,
			y: height * 0.65,
			r: width * 0.4,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 1.0,
			textY: height * 0.3,
			desc: 'Klicken Sie auf ein Foto für eine vergrößerte Ansicht und zusätzliche Informationen.'
		},
	]

	let contentSunburstMobileLandscape = [
		{
			id: 0,
			circles: 2,
			x: width * 0.1,
			y: height * 0.0,
			r: width * 0.35,
			x1: width * 0.72,
			y1: height * 0.3,
			r1: width * 0.18,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 0.75,
			textY: height * 0.65,
			desc: 'In dieser Ansicht sehen Sie links die detaillierte Struktur der Sammlung und rechts eine Objektvorschau.'
		},
		{
			id: 1,
			circles: 1,
			x: width * 0.14,
			y: height * 0.9,
			r: height * 0.3,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 0.7,
			textY: height * 0.5,
			desc: 'Nutzen Sie die Suchfunktion, um nach bestimmten Objekten zu suchen oder nach bestimmten Museen zu filtern.'
		},
		{
			id: 2,
			circles: 1,
			x: width * 0.14,
			y: height * 0.1,
			r: height * 0.4,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width / 1.7,
			textY: height / 4,
			desc: 'Wählen Sie eine Kategorie, um rechts dazu passende Objekte zu sehen. Sobald Sie tiefer eingetaucht sind bringt Sie der Pfeil links oben wieder zurück.'
		},
		{
			id: 3,
			circles: 1,
			x: width * 0.7,
			y: height * 0.4,
			r: width * 0.2,
			c1: bg,
			c2: bg,
			c3: bg,
			textX: -width * 0.9,
			textY: height * 0.5,
			desc: 'Klicken Sie auf ein Foto für eine vergrößerte Ansicht und zusätzliche Informationen.'
		},
	]


	let content = props.topic === 'timeline' ?
		isMo1 ? isLandscape ? contentTimelineMobileLandscape : contentTimelineMobilePortrait : contentTimeline
		:
		isMo1 ? isLandscape ? contentSunburstMobileLandscape : contentSunburstMobilePortrait : contentSunburst;

	var style;

	useEffect(() => {
		setClickedHelp(true)
		window.scrollTo(0, 0);
	}, [])

	useEffect(() => {
//		console.log('topic changed')
		setIsSunburst(props.topic === 'sunburst' ? true : false)
		setHovered(true)
	}, [props])

	useLayoutEffect(() => {
		if (!hovered) {
			if (isSunburst) setHoveredHelpSunburst(hoveredHelp)
			if (!isSunburst) setHoveredHelpTimeline(hoveredHelp)
		}
	}, [hoveredHelp])

	useLayoutEffect(() => {
		setHoveredHelp(isSunburst ? hoveredHelpSunburst : hoveredHelpTimeline)
		setHovered(false)
	}, [hoveredHelpSunburst, hoveredHelpTimeline, hovered])


	const handleNextButton = (e) => {
		e.stopPropagation()
		if (isSunburst) {
			mtrack('help sunburst: ' + (hoveredHelpSunburst + 1))
			setHoveredHelpSunburst(hoveredHelpSunburst < content.length - 1 ? hoveredHelpSunburst + 1 : 0)
			if (hoveredHelpSunburst === content.length - 1) {
				setShowMediaOverlay(false);
			}
		} else {
			mtrack('help timeline: ' + (hoveredHelpTimeline + 1))
			setHoveredHelpTimeline(hoveredHelpTimeline < content.length - 1 ? hoveredHelpTimeline + 1 : 0)
			if (hoveredHelpTimeline === content.length - 1) {
				setShowMediaOverlay(false);
			}
		}
	}

	return (
		<div style={{opacity: 0.9}}>

			<div style={isSunburst ? {position: 'absolute', left: -width - 100} : {}}>
				<svg x={0} y={0} width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
					{content.map((d, i) => {
						let index = i;
						return (
							<g key={'lens' + index}
							>
								{hoveredHelp === index &&
									<g>
										<defs>
											<mask id={"hole" + index}>
												<rect width="100%" height="100%" fill="white"/>
												<circle r={d.r} cx={d.x} cy={d.y} fill="black"/>
												{d.circles === 2 && <circle r={d.r1} cx={d.x1} cy={d.y1} fill="black"/>}
											</mask>
										</defs>

										<rect id="donut" x={0} y={0} width={'100%'} height={'100%'}
										      mask={"url(#hole" + index + ")"}/>

										<radialGradient id={"grad" + index}
										                cx={d.x} cy={d.y} r={d.r * 0.9999}
											//										                fx={d.x} fy={d.y} fr={d.r / 1.8}
											            gradientUnits="userSpaceOnUse">
											<stop offset="0" stopColor={d.c1} stopOpacity={0}/>
											<stop offset="0.8" stopColor={d.c2} stopOpacity={0.1}/>
											<stop offset="1" stopColor={d.c3} stopOpacity={1}/>
										</radialGradient>
										<circle id={'anim' + index} fill={"url(#grad" + index + ")"}
										        strokeWidth={1}
										        stroke="#000000ee"
										        strokeMiterlimit="10"
										        cx={d.x} cy={d.y} r={d.r}
											// opacity={HoveredHelp === index ? o.to(d => show === -1 ? d : 1) : 0}/>
											    opacity={1}
										/>

										{d.circles === 2 &&
											<g>
												<radialGradient id={"gradi" + index}
												                cx={d.x1} cy={d.y1} r={d.r1 * 0.9999}
													//											                fx={d.x1} fy={d.y1} fr={d.r1 / 2.5}
													            gradientUnits="userSpaceOnUse">
													<stop offset="0" stopColor={d.c1} stopOpacity={0}/>
													<stop offset="0.80" stopColor={d.c2} stopOpacity={0.1}/>
													<stop offset="1" stopColor={d.c2} stopOpacity={1}/>
												</radialGradient>
												<circle id={'animi' + index} fill={"url(#gradi" + index + ")"}
												        strokeWidth={1}
												        stroke="#000000ee"
												        strokeMiterlimit="10"
												        cx={d.x1} cy={d.y1} r={d.r1}
													// opacity={HoveredHelp === index ? o.to(d => show === -1 ? d : 1) : 0}/>
													    opacity={1}
												/>
											</g>
										}

									</g>
								}
							</g>
						)
					})}
				</svg>
			</div>
			{content.map((d, i) => {
				let index = i;
				return (
					<div key={'hc' + i} className={'helpContainer'} style={{
						left: d.textX, top: d.textY,
						fontSize: isMo1 ? isLandscape ? '1.1em' : '1.1em' : '1.6em',
						width: isMo1 && !isLandscape ? '70vw' : d.textWidth ? d.textWidth : '30vw',
					}}>
						<div className={'helptext'} style={{
							opacity: index === hoveredHelp ? 1 : 0
						}}>
							{d.desc}
						</div>
					</div>
				)
			})}

			{props.topic === 'timeline' ?
				(
					<div className={'helpTimeline'}
					     style={{
						     left: isMo1 ? isLandscape ? '80vw' : '60vw' : '88vw',
						     bottom: isMo1 ? isLandscape ? '3vh' : '3vh' : '7vh',
					     }}
					>
						<div>
							<TrackerHelp
								onClick={(e) => {e.stopPropagation()}}
								stations={content.length}
							/>
						</div>
						<div className={'helpnext'} onClick={handleNextButton}
						     style={{fontSize: isMo1 ? isLandscape ? '1.5em' : '1.5em' : '1.9em'}}
						>weiter
						</div>
					</div>
				) : (
					<div className={'helpSunburst'}
					     style={{
//						     bottom: isMo1 && !isLandscape ? '-100vh' : '1vh',
						     top: isMo1 ? isLandscape ? '95vh' : '95vh' : '95vh',
						     right: isMo1 ? isLandscape ? '15vw' : '40vw' : '12vw',
					     }}
					>
						<div>
							<TrackerHelp
								onClick={(e) => {e.stopPropagation()}}
								stations={content.length}
							/>
						</div>
						<div className={'helpnext'} onClick={handleNextButton}
						     style={{fontSize: isMo1 ? isLandscape ? '1.3em' : '1.3em' : '1.9em'}}
						>weiter
						</div>
					</div>
				)
			}

			<div width='100%'>
				<div className="closeXX" style={{
					left: isMo1 ? isLandscape ?
							(isSunburst ? '-22vw' : '90vw') :
							(isSunburst ? '-42vw' : '85vw') :
						(isSunburst ? '-10vw' : '96vw'),
				}}
					/* onClick={closePopup}*/>
					&times;
				</div>
			</div>
		</div>
	)
})

