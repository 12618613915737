import React, {useRef, useState, useMemo, useEffect, useLayoutEffect, useCallback} from "react";
import {useStore} from './store';
//import 'bootstrap/dist/css/bootstrap.css';

import _ from 'lodash';

import {useWindowDimensions} from "../utils/hooks";
import {useSpring, animated, a} from '@react-spring/web';
import {hexToRgbA} from "../utils/helpers";
import Select from 'react-select';
import "../css/search.css";
import {mtrack} from "../utils/helpers";


//export default function Search() {
	export const Search = React.memo(() => {

	const [isOpen, setIsOpen] = useState(false);

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);
	const isSafari = useStore(state => state.isSafari);

//	const transition = useStore(state => state.transition);

	const museumsList = useStore(state => state.museums);

	const [sum, setSum] = useState(-1);
	const [seloptions, setSeloptions] = useState(-1);
	const [selMuseum, setSelMuseum] = useState({value: 0});

	const [searchTerm, setSearchTerm] = useState("");
	const [filterMuseum, setFilterMuseum] = useState();

	const setSearchFilter = useStore(state => state.setSearchFilter);
	const searchFilter = useStore(state => state.searchFilter);

	const setMuseumFilter = useStore(state => state.setMuseumFilter);
	const museumFilter = useStore(state => state.museumFilter);

	const setFetchTrigger = useStore(state => state.setFetchTrigger);
	const setFetchTriggerNow = useStore(state => state.setFetchTriggerNow);

	const setResetMuseumFilter = useStore(state => state.setResetMuseumFilter);
	const setResetSearchFilter = useStore(state => state.setResetSearchFilter);

	const resetMuseumFilter = useStore(state => state.resetMuseumFilter);
	const resetSearchFilter = useStore(state => state.resetSearchFilter);

//	const [show, setShow] = useState(isMo1 && isLandscape ? false : true);
	// avoid filter icon
	const [show, setShow] = useState(isMo1 && isLandscape ? false : false);

	const museumSelectOpen = useStore(state => state.museumSelectOpen);
	const setMuseumSelectOpen = useStore(state => state.setMuseumSelectOpen);

	const handleShowForm = event => {
//		event.stopPropagation()
//		console.log("search", event.target.value);
		setShow(!show)

		mtrack('search '+event.target.value);
	}

	const handleInputChange = event => {
//		console.log(event.target.value);
		setSearchTerm(event.target.value);

		if (event.target.value.length > 2) {
//			console.log("lengthOK")
			setSearchFilter(event.target.value);
		} else {
			setSearchFilter("");
		}
	}


	const handleResetSearchfilter = () => {
		setSearchTerm("");
		setSearchFilter("");
		mtrack('search reset text');
	}

	const handleResetMuseumfilter = () => {
		setFilterMuseum(0);
		setMuseumFilter(0);
		setSelMuseum(seloptions[0])
		mtrack('search reset museum');
	}


	useEffect(() => {
//		console.log("global: ", searchFilter, museumFilter);
		setFetchTrigger(true);
		setFetchTriggerNow(true);
//		mtrack('search filter museum', museumFilter);
//		mtrack('search filter text', searchFilter);
	}, [searchFilter, museumFilter])

	useEffect(() => {
		if (resetMuseumFilter) {
			handleResetMuseumfilter();
			setResetMuseumFilter(false);
		}
	}, [resetMuseumFilter])

	useEffect(() => {
		if (resetSearchFilter) {
			handleResetSearchfilter()
			setResetSearchFilter(false);
		}
	}, [resetSearchFilter])


	useEffect(() => {
		if (museumsList && sum === -1) {
			let arr = [];
			museumsList.map((d) => arr.push(d.items ? parseInt(d.items) : 0));
			let itemssum = arr.reduce((a, b) => a + b)
			setSum(itemssum);

			let defaultval = {value: '0', label: 'Alle Museen anzeigen (' + itemssum + ' Objekte)'};
			let options = [defaultval];

			museumsList.map((d, i) => {
				if (parseInt(d.items) === 1) {
					options.push({value: d.idMandant, label: d.bezeichnung + ' (' + parseInt(d.items) + ' Objekt)'})
				}
				else if (parseInt(d.items) > 1) {
					options.push({value: d.idMandant, label: d.bezeichnung + ' (' + parseInt(d.items) + ' Objekte)'})
				}
			})
			setSeloptions(options);
			setSelMuseum(defaultval);
		}
	}, [sum, museumsList])

	useEffect(() => {
//		console.log("seloptions: ", selMuseum)
		if (selMuseum && selMuseum.value && museumFilter) {
			mtrack('search museum with id '+parseInt(selMuseum.value));
		}
		setMuseumFilter(selMuseum && selMuseum.value ? parseInt(selMuseum.value) : 0);
		setFilterMuseum(selMuseum && selMuseum.value ? parseInt(selMuseum.value) : 0);
	}, [selMuseum])


	const customStyles = {
		option: (provided, state) => ({
			...provided,
//			borderBottom: '1px dotted gray',
			paddingBottom: 2,
			paddingTop: 3,
			zIndex: '500 !important',
//			opacity: '1 !important',
//			color: '#FFFFFFFF',
//			backgroundColor: '#000000FF',
//			paddingLeft: 10,
//			paddingRight: 10,
		}),
		valueContainer: (provided, state) => ({
			...provided,
//			zIndex: 5000,
			top: '50%',
			transform: 'translateY(-50%)',
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			right: 0,
//			zIndex: 5000,
		}),
		control: (provided, state) => ({
			...provided,
			minHeight: '50%',
			height: 28,
//			margin: 0,
			paddingBottom: 2,
			paddingLeft: 2,
			paddingRight: 28,
//			zIndex: 5000,
		}),
		singleValue: (provided, state) => ({
			...provided,
			padding: 1,
			zIndex: 5000,
//			const opacity = state.isDisabled ? 0.5 : 1;
//			const transition = 'opacity 300ms';
//			return { ...provided, opacity, transition };
		}),
//		MenuList: (provided, state) => ({
//			...provided,
//			padding: 1,
//			backgroundColor: 'red',
//			zIndex: 5000,
//			const opacity = state.isDisabled ? 0.5 : 1;
//			const transition = 'opacity 300ms';
//			return { ...provided, opacity, transition };
//		})

	}

	const selectRef = useRef();

	useEffect(()=>{
//		console.log('menu select: ',useStore.getState().museumSelectOpen, selectRef.current)
		if (useStore.getState().museumSelectOpen) {
			selectRef.current.focus();
		}
	}, [useStore.getState().museumSelectOpen])

	const MuseumSelect = () => (
		<Select
			ref={selectRef}
			styles={customStyles}
			defaultValue={selMuseum}
			onChange={setSelMuseum}
			isDisabled={false}
			isLoading={false}
			openMenuOnFocus={true}
			onMenuOpen={()=> {setMuseumSelectOpen(true)}}
			onMenuClose={()=> {
				setTimeout(()=>{
					setMuseumSelectOpen(false)
				}, 100)

			}}
			//			isClearable={true}
			isRtl={false}
			isSearchable={isMo ? false : true}
			menuPlacement={isMo1 && !isLandscape ? 'top' : 'top'}
			name="selectMuseum"
			options={seloptions}
			theme={(theme) => ({
				...theme,
				borderRadius: 4,
				colors: {
					...theme.colors,
					primary25: 'lightgray',
					primary: 'black',
				},
			})}
		/>
	)

	const {s} = useSpring({
		from: {s: 1},
		to: {s: show && isMo1 ? 0 : 1},
//		config: config.stiff,
		delay: show ? 10 : 100,
		config: {
			duration: show ? 150 : 150,
		}
	})

	const origin = isLandscape ? '100px ' + height * 0.82 + 'px 0' : '100px ' + height * 0.42 + 'px 0'

	return (
		<div
//			style={{zIndex: '11 !important'}}
		>

			<animated.div style={{
				transformOrigin: origin,
				transform: s.to(s => `scale(1,${s})`)
			}}>
				<div className="searchcontainer"
				     style={{
					     top: isMo1 ? isLandscape ? '76vh' : (isSafari ? '40vh' : '38vh') : '82vh',
					     width: isMo1 ? isLandscape ? '45vw' : '90vw' : '45vw',
					     left: isMo1 ? isLandscape ? -width/2 : -width + 40 : -width/2,
//			     display: 'none'
				     }}
				>
					<div className="formcontainer"
						//			     style={{ left: !isLandscape && isMo1 ? '100px' : 0}}
						 style={{
//							 padding: isMo1 ? isLandscape ? '0px' : '5px' : '0px',
							 paddingTop: isMo1 ? isLandscape ? '3vh' : (isSafari ? '0vh' : '3vh') : '0px',
//							 border: isMo1 && !isLandscape ? '1px solid lightgray' : 'none',
							 borderRadius: '5px',
//							 background: isMo1 && !isLandscape ? '#D7D7D744' : 'transparent'
						 }}
					>
						{/*<form>*/}


						{ !isMo1 &&
						<>
						Filter
						{(museumFilter !== 0 || searchFilter !== '') && <span>: aktiv</span>}
						{(museumFilter === 0 && searchFilter === '') && <span> bitte auswählen:</span>}
						</>
						}


							{isMo2 && <div></div>}
							{isMo2 &&
								<div onClick={handleShowForm}
								     style={{
									     width: '25px',
									     height: '25px',
									     alignSelf: 'flex-end',
//									     zIndex: 2000,
								     }}
								>
									<svg width="100%" height="100%" viewBox="0 0 70 80"
									     xmlns="http://www.w3.org/2000/svg">
										<g transform="rotate(-90, 30, 35)">
											<path
												d="M 10 70 L 40 40 L 10 10"
												strokeOpacity={0.9}
												fill="transparent"
												stroke="grey"
												strokeWidth="15"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</g>
									</svg>
								</div>
							}

						<div style={{width: '100%', display: 'flex', alignItems: 'baseline'}}>

							<div className='select'>
								<MuseumSelect></MuseumSelect>
							</div>
							<div style={{flex: '1 1 auto'}}>
							{museumFilter !== 0 &&
								<button
									className="resetFilter"
									type="button"
									title='Filter zurücksetzen'
									style={{marginLeft: "5px"}}
									onClick={handleResetMuseumfilter}
								>
									<b>X</b>
								</button>
							}
							</div>
						</div>
						<div style={{
							display: 'flex', justifyContent: 'space-between',
						}}>
							{ !isMo1 &&
								<div>
									<input
										id="search"
										type="text"
										placeholder="Suche nach Text ..."
										value={searchTerm}
										onChange={handleInputChange}
									/>

									{searchFilter &&
										<button
											className="resetFilter"
											type="button"
											title='Filter zurücksetzen'
											style={{marginLeft: "5px"}}
											onClick={handleResetSearchfilter}
										>
											<b>X</b>
										</button>
									}
								</div>
							}


						</div>
						{!isMo && searchTerm !== '' && searchFilter === '' &&
							<span
								style={{color: "red"}}>&nbsp;Hinweis: Bitte geben Sie bitte mindestens drei Zeichen ein!</span>}
						{/*</form>*/}
					</div>
				</div>
			</animated.div>

			{isMo1 &&
				<div onClick={handleShowForm}
			      style={{
				      width: isLandscape ? '35px' : '40px',
				      position: 'absolute',
				      top: isLandscape ? '80vh' : '38vh',
				      left: isLandscape ? width * 1.4 +16 : width * 1.82,
				      visibility: show ? 'visible' : 'hidden'
			      }}>
				<svg id='filtericon' viewBox="0 0 32 32" width={'100%'} height={'100%'}>
					<path
						d="  M3.241,7.646L13,19v9l6-4v-5l9.759-11.354C29.315,6.996,28.848,6,27.986,6H4.014C3.152,6,2.685,6.996,3.241,7.646z"
						fill="none" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
						strokeWidth="2"/>
				</svg>
			</div>
			}


		</div>
	)
})
//}) // for memo

export default Search;
