import React, {useRef, useState, useMemo, useEffect, useCallback, useLayoutEffect} from "react";
import {useFrame, useThree} from "@react-three/fiber";
//import {MeshStandardMaterial, BoxBufferGeometry} from "three";
//import niceColors from 'nice-color-palettes';
import {scaleLinear} from "d3";
import * as THREE from "three";
import {useStore} from './store';
import {Line} from "@react-three/drei";
import {rescaleYears, mtrack} from "../utils/helpers";
import {rearrangeItems, rearrangeItemsWithHelpLines, addToGroups, checkItems, reArrange} from "./itemsRearrange";
import * as d3 from "d3";
import {useWindowDimensions} from "../utils/hooks";
//import {EffectComposer, Outline} from '@react-three/postprocessing'

export const Items = React.memo(() => {

		const {height, width} = useWindowDimensions();

		const itemsList = useStore(state => state.items);

		const setInitialized3D = useStore(state => state.setInitialized3D);
		const initialized3D = useStore(state => state.initialized3D);

		const mycolors = useStore(state => state.gauColors);
		const debugHelpLines = useStore(state => state.debugHelpLines);
		const overlay = useStore(state => state.overlay);

//	    const color = '#f94144';
//	    const geom = useMemo(() => new BoxBufferGeometry(0.3, 0.3, 0.3), [])
//	    const mat = useMemo(() => new MeshStandardMaterial({color}), [color])

		var linePoints = [];  // start and endpoint for helper lines
		var lineColorArray = []; // color array for lines

//	    const arrSize = 12000;
		const arrSize = 1000;
//	    const arrSize = 5;
		const size3 = 3 * arrSize;

		const tempObject = new THREE.Object3D()
		const tempObjectSelected = new THREE.Object3D()
		const tempColor = new THREE.Color()
		const pos = new THREE.Vector3();
		const holdPos = new THREE.Vector3();

		// XXXX below only for mobile to catch nearest click
		const testpos = new THREE.Vector3();
		// XXXX above only for mobile to catch nearest click

		var dummymatrix = new THREE.Matrix4();
		var dummyposition = new THREE.Vector3();

//	const colors = new Array(12000).fill().map(() => mycolors[Math.floor(Math.random() * 1)])
		const colors = useMemo(() => new Array(arrSize).fill().map(() => mycolors[0]))
		const box = useMemo(() => new Float32Array(size3));

		var connectedGroups = [];

		var xx = d3.scaleLinear().domain([0, 6]).range([0, 7]);

		const setBoxX = (id, val) => box[id * 3] = val;
		const setBoxY = (id, val) => box[id * 3 + 1] = val;
		const setBoxZ = (id, val) => box[id * 3 + 2] = val;

		const shiftBoxArrayX = (arr, connectedGroups) => {
//			console.log(arr);
//			console.log(connectedGroups);
			arr.map((d) => {
				setBoxX(d[0], Math.floor(box[d[0] * 3]) + d[1])
				setBoxZ(d[0], 2 + d[2])
			});

			for (let i = 0; i < 6; i++) {
				shiftNeighbours(i * 1, connectedGroups);
			}

		}

		// shift close neighbours
		const shiftNeighbours = (delay, connectedGroups) => {

			const ingroup = (item) => {
				var found = false;
				connectedGroups.map((x) => {
					if (x.length > 1) {
						if (x.find((f) => f === item)) {
							found = true
							return true
						}
					}
				})
				return found
			}

			setTimeout(() => {
				itemsList.map((d, i) => {
					itemsList.map((dd, ii) => {
						if (i !== ii && d['region'] === dd['region']
							&& Math.abs(box[i * 3 + 1] - box[ii * 3 + 1]) < 0.9
							&& Math.abs(box[i * 3] - box[ii * 3]) < 0.9
							&& (Math.abs(box[i * 3 + 1] - box[ii * 3 + 1]) + Math.abs(box[i * 3] - box[ii * 3])) / 2 < 0.5
						) {
							if (ingroup(i) && !ingroup(ii)) {
								if (box[ii * 3 + 1] <= box[i * 3 + 1]) {
									setBoxX(ii, box[ii * 3] - 0.01)
									setBoxY(ii, box[ii * 3 + 1] - 0.02)
								} else {
									setBoxX(ii, box[ii * 3] + 0.01)
									setBoxY(ii, box[ii * 3 + 1] + 0.02)
								}
							}
							if (!ingroup(i) && ingroup(ii)) {
								if (box[ii * 3 + 1] >= box[i * 3 + 1]) {
									setBoxX(i, box[i * 3] - 0.01)
									setBoxY(i, box[i * 3 + 1] - 0.02)
								} else {
									setBoxX(i, box[i * 3] + 0.01)
									setBoxY(i, box[i * 3 + 1] + 0.02)
								}
							}
							if (!ingroup(i) && !ingroup(ii)) {
								if (box[ii * 3 + 1] > box[i * 3 + 1]) {
									setBoxX(i, box[i * 3] - 0.01)
									setBoxY(i, box[i * 3 + 1] - 0.02)

									setBoxX(ii, box[ii * 3] + 0.01)
									setBoxY(ii, box[ii * 3 + 1] + 0.02)

								} else {
									setBoxX(i, box[i * 3] + 0.01)
									setBoxY(i, box[i * 3 + 1] + 0.02)

									setBoxX(ii, box[ii * 3] - 0.01)
									setBoxY(ii, box[ii * 3 + 1] - 0.02)
								}
							}
						}
					})
				})
				//console.log(itemsList)
			}, delay)
		}

		const items = useStore((state) => state.items);
		const museums = useStore((state) => state.museums);
		const media3D = useStore((state) => state.media3D);
		const tree = useStore((state) => state.tree);

		useEffect(() => {console.log("ITEMS:", items)}, [items]);
		useEffect(() => {console.log("MUSEUMS:", museums)}, [museums]);
		useEffect(() => {console.log("Media3D:", media3D)}, [media3D]);

		var categorySelected = [];
		var categoryHovered = [];


//		function Boxes() {
		const Boxes = React.memo(() => {


			const isMo = useStore(state => state.isMobile);
			const isMo1 = useStore(state => state.isMobile1);
			const isMo2 = useStore(state => state.isMobile2);
			const isLandscape = useStore(state => state.isLandscape);
			const isSafari = useStore(state => state.isSafari);

			const {camera, size} = useThree();

			const colorArray = useMemo(() => Float32Array.from(new Array(12000).fill().flatMap((_, i) => tempColor.set(colors[i]).toArray())), [])
			const meshRef = useRef()

			const [init, setInit] = useState(true);

			const [hovered, setHovered] = useState(-1);
			const [lastHovered, setLastHovered] = useState(-1)
			const [lastHoveredY, setLastHoveredY] = useState(-1)

			const [noHover, setNoHover] = useState(false);

			const [lastSunburstSelected, setLastSunburstSelected] = useState(-1)

			const [animation, setAnimation] = useState('done')

			const [clicked, setClicked] = useState(false)

			const [newClick, setNewClick] = useState(false)

			// XXXX below only for mobile to catch nearest click
			const [clickedEmptyX, setClickedEmptyX] = useState(-1)
			const [clickedEmptyY, setClickedEmptyY] = useState(-1)
			// XXXX above only for mobile to catch nearest click

			const selectedStory = useStore(state => state.selectedStory);

			const sunburstSelected = useStore(state => state.sunburstSelected);
			const sunburstHovered = useStore(state => state.sunburstHovered);

			const itemsList = useStore(state => state.items);

			const noItemClicked = useStore(state => state.noItemClicked);
			const setNoItemClicked = useStore(state => state.setNoItemClicked);

			const setClosePopup = useStore(useCallback(state => state.setClosePopup, [noItemClicked]));

			const setSelected = useStore(useCallback(state => state.setSelected, [hovered]));
			const selected = useStore(state => state.selected);
//			const remote = useStore(state => state.remote);
			const setHoveredCoords = useStore(useCallback(state => state.setHoveredCoords, [hovered]));

			const scr = useStore(state => state.scrolling);
			const scrJump = useStore(state => state.scrollingJump);
			const cameraAnimationStopped = useStore(state => state.cameraAnimationStopped);

			const showTour = useStore(state => state.showTour);

			const setJumpDistance = useStore(state => state.setJumpDistance);
			const setJumpRegion = useStore(state => state.setJumpRegion);
			const setAnimateYearJump = useStore(state => state.setAnimateYearJump);

			const firstModalCompleted = useStore(state => state.firstModalCompleted);

			const setRemotePending = useStore(state => state.setRemotePending);
			const remotePending = useStore(state => state.remotePending);

			const [remote, setRemote] = useState(false);

			const [firstrun, setFirstrun] = useState(true);

//		const prevRef = useRef()
//		useLayoutEffect(() => void (prevRef.current = hovered), [hovered])


			const startjump = (d, r) => {
//				console.log("jump", d, r)
				setJumpDistance(-rescaleYears(d));
				setJumpRegion(isMo1 && !isLandscape ? r : -1);
				setTimeout(() => {
					setAnimateYearJump(true);
					mtrack('URL jump: ' + d);
				}, 1000)
			}

			const fireRemote = () => {
				setTimeout(() => {
					let item = itemsList.find(d => d.id_inventar === useStore.getState().remote)
					if (item) startjump(item.yearfine, item.region);
				}, 1000)
				setTimeout(() => {
//					console.log("triggered hover");
					let id = itemsList.findIndex(d => d.id_inventar === useStore.getState().remote)
//					console.log("#### id", id);
					if (id === -1) alert("Das Objekt " + useStore.getState().remote + " konnte nicht gefunden werden. Entweder liegt ein Tippfehler in der URL vor oder das Objekt ist nicht mehr Teil dieser virtuellen Schau!")
					setHovered(id)
				}, 4000)

			}

			useEffect(() => {
//				console.log("remote", useStore.getState().remote);
				if (!isMo && initialized3D && useStore.getState().remote !== -1) {
					fireRemote();
				}
				if (isMo && firstModalCompleted && useStore.getState().remote !== -1) {
					// wait for modal
					fireRemote();
				}
			}, [initialized3D, firstModalCompleted])


			useEffect(() => {
				if (remotePending) {
					fireRemote();
					setRemotePending(false);
				}

			}, [remotePending])


			// remote hovering when in showTour
			useEffect(() => {
				//console.log('story remote', selected, hovered, lastHovered)
				if (selected > -1 && showTour) {
					//console.log('set hovered', selected, hovered, lastHovered)
//					 setTimeout (()=>{
						 setHovered(selected)
//					 }, 1500);
				}
			}, [selected])

			useEffect(() => {
				if (!isMo && clicked) {
//					console.log("clicked", clicked);
					if (clicked) {
						setHovered(clicked);
					}
					setClicked(false);
				}
			}, [clicked])

			// close popup if empty space was clicked
			useEffect(() => {
				if (noItemClicked) {
//					console.log("no item clicked", noItemClicked);
					setClicked(false);
					setNoItemClicked(false);
					setSelected(-1);
					setClickedEmptyX(-9999);
					setClickedEmptyY(-9999);
					setHovered(-1);
					setClosePopup(true);
				}
			}, [noItemClicked])

			// if sunburst segment was clicked, elevate selection
			useEffect(() => {
				if (!itemsList) return;
				setInit(true);
				if (sunburstSelected !== -1) setLastSunburstSelected(sunburstSelected);
				categorySelected = [];
				itemsList.map((d, i) =>
					d.root1 === sunburstSelected || d.root2 === sunburstSelected || d.root3 === sunburstSelected
						? categorySelected.push(parseInt(i)) : null)
			}, [sunburstSelected])

			// if sunburst segment was hovered, recolor selection
			useEffect(() => {
				if (!itemsList) return;
				setInit(true);
				categoryHovered = [];
				itemsList.map((d, i) =>
					d.root1 === sunburstHovered || d.root2 === sunburstHovered || d.root3 === sunburstHovered
						? categoryHovered.push(parseInt(i)) : null)
//				console.log("categorySelected", categoryHovered, sunburstHovered)
			}, [sunburstHovered])

			useEffect(() => {
//				console.log("animation: ", animation);
			}, [animation])

			useEffect(() => {

				switch (animation) {
					case "done":
						setAnimation(sunburstSelected !== -1 ? "starting" : "done");
						break;
					case "starting":
						setAnimation(sunburstSelected !== -1 ? "starting" : "stopping");
						break;
					case "started":
						setAnimation(sunburstSelected !== -1 ? "started" : "stopping");
						break;
					case "stopping":
						setAnimation(sunburstSelected === -1 ? "stopping" : "done");
						break;
					case "default":
						break;
				}
			}, [sunburstSelected, animation]);

			// change refline position, if hovered or after scroll / animation
			useEffect(() => {

				let mobileoffset = isLandscape ? 15 : 8;

				const width1 = size.width;
				const height1 = size.height - mobileoffset;
				const widthHalf = width1 / 2;
				const heightHalf = height1 / 2;

				tempObjectSelected.updateMatrix()
				tempObjectSelected.getWorldPosition(pos);

				// when deselected or nothing is selected, store position in holdPos
				if ((holdPos.x === 0 && holdPos.y === 0) || hovered !== lastHovered) {
					tempObjectSelected.getWorldPosition(holdPos);
				}

				const distance = pos.y + meshRef.current.matrixWorld.elements[13];

				// adjust group offset and scroll position
//				pos.x = pos.x - 3;
				pos.x = pos.x + meshRef.current.matrixWorld.elements[12];
				pos.y = pos.y + meshRef.current.matrixWorld.elements[13];

				pos.project(camera);

				// correcting positions if referenced item is out of canvas
				// item is out of view when distance > ~23
				if (distance < -26) {
					let corr = 0;
					let overlay_corr = overlay === "vertical" ? -1.5 : 0;
//				let overlay_corr = -Math.PI / 2 * 1.2;
					// correction of x, more correction if x is on the outer lanes
//				if (distance < overlay === "vertical" ? -27 : 32) {
					if (distance < (overlay === "vertical" ? -27 : 32)) {
						if (holdPos.x < 2) corr = 0.5;
						if (holdPos.x < 1.5) corr = 1;
						if (holdPos.x > 3) corr = -0.5;
						if (holdPos.x > 4.5) corr = -1;
					}
//					console.log("holdpos.x:", holdPos.x, "corr", corr, "distance", distance);
//				console.log("pos.y:", pos.y, "pos.x", pos.x, "distance",distance);
					pos.x = Math.round(((holdPos.x - 3 + corr - overlay_corr) * widthHalf) + widthHalf);
					pos.y = Math.round(-(-3 * heightHalf) + heightHalf);
				} else {
					pos.x = Math.round((pos.x * widthHalf) + widthHalf);
					pos.y = Math.round(-(pos.y * heightHalf) + heightHalf);
				}
				setHoveredCoords(pos)
			}, [hovered, scr, scrJump, cameraAnimationStopped])

			useEffect(() => {

				// when mobile avoid hovering of others items after click
				if (noHover) {
//					console.log("no hover script started")
					setClickedEmptyX(-9999)
					setClickedEmptyY(-9999)
					setNoHover(false)
				}

				//console.log("====> H O V E R E D ", noHover);
				setSelected(hovered);

				if (hovered !== -1) {
					setLastHovered(hovered)
					setLastHoveredY(meshRef.current.matrixWorld.elements[13])
				}

//				if (hovered === -1) {
//					//				setSelected(-1);
////					console.log("not hovered");
//				}
//			else setSelected(hovered);
			}, [hovered])

			function count(max) {
				var c = 0;
				return function () {
					if (c < max) {
						c += 1;
						return false;
					} else {
						c = 0;
						return true;
					}
				}
			}

			var every50 = count(50);
			var active, elevated, targetpos;

			// below only for mobile to catch nearest click
			var lastnearest = -1;
			// above only for mobile to catch nearest click

			useEffect(() => {
				if (firstrun && meshRef.current) {
//					console.log("****** first run ****")
					setFirstrun(false);
				}


			}, [firstrun])

			// animate boxes
			useFrame((state) => {

				if (meshRef.current) {
					const time = state.clock.getElapsedTime();
					let delay = 0.4; // 0.5
					let change = 32; // 12
					let i = 0
					let x, y, z;

					// below only for mobile to catch nearest click
					let width2 = width, height2 = height;
					let widthHalf = width2 / 2, heightHalf = height2 / 2;
					let nearest = -1;
					let euler = 0.2;
					let lasteuler = 0.05;
					let logPosX = 0;
					let logPosY = 0;
					// above only for mobile to catch nearest click


					for (let j = 0; j < 3 * arrSize; j = j + 3) {
						const id = i++

						if (every50()) {
//							console.log("echo something every 50 frames");
//							console.log(box)
							if (box[0] === 0 && init) {

								//console.log("re-init items");
								setInit(false)
								setInitialized3D(false)
							}
						}

						x = xx(box[j]);
						y = box[j + 1];
						z = box[j + 2];
						if (animation !== "done") {
							active = categorySelected.indexOf(id) !== -1 ? true : false;
							elevated = (active && animation === "started") ? 0.3 : 0;
							z = box[j + 2] + elevated;
							if (animation === 'starting' && sunburstSelected !== -1) {
								meshRef.current.getMatrixAt(id, dummymatrix);
								dummyposition.setFromMatrixPosition(dummymatrix);
								targetpos = z + 0.3;
//					    		z = THREE.MathUtils.lerp(dummyposition.z, active ? targetpos : z, 0.2);
								z = THREE.MathUtils.damp(dummyposition.z, active ? targetpos : z, 0.5, 0.2);
								if (active && Math.abs(z - targetpos) < 0.01) {
//								console.log("finished starting");
									setAnimation("started");
								}
							}
							if (animation === 'stopping') {
								meshRef.current.getMatrixAt(id, dummymatrix);
								dummyposition.setFromMatrixPosition(dummymatrix);
								targetpos = z;
//			    				z = THREE.MathUtils.lerp(dummyposition.z, active ? z : z, 0.5);
								z = THREE.MathUtils.damp(dummyposition.z, active ? z : z, 1.5, 0.2);
								if (active && Math.abs(z - targetpos) < 0.01) { // Also adjust threshold to match upward animation
									setAnimation("done");
								}
							}
						}
						tempObject.position.set(x, y, z)
						tempObject.rotation.y = Math.sin(x / change + time * delay) + Math.sin(y / change + time * delay) + Math.sin(z / change + time * delay)
						tempObject.rotation.z = delay * (tempObject.rotation.y * 2)
						if (id === hovered) tempObjectSelected.position.set(x, y, z);
						var itemSize = 1;
						if (sunburstSelected !== -1) itemSize = categorySelected.indexOf(id) === -1 ? 0.5 : 1.5;
						if (init) {
							tempColor.set(id === hovered ? 'orange' : colors[id]).toArray(colorArray, id * 3);
						}
						if (sunburstHovered !== -1 && animation != 'starting' && categoryHovered.indexOf(id) > -1) {
							tempColor.set(id === hovered ? 'orange' : 'purple').toArray(colorArray, id * 3);
						}
						const scale = id === hovered ? 2 : itemSize
						tempObject.scale.set(scale, scale, scale)
						tempObject.updateMatrix()
						meshRef.current.setMatrixAt(id, tempObject.matrix);

						if (id === hovered && hovered !== lastHovered) {
//							console.log("***** hovered ***** ", id, x, y, z);
						}


						// XXXX below only for mobile to catch nearest click
						if (isMo && clickedEmptyX > -1 && animation === 'done') {
							tempObject.getWorldPosition(testpos);
//							testpos.x = testpos.x - 3 - meshRef.current.matrixWorld.elements[12];
							testpos.x = testpos.x + meshRef.current.matrixWorld.elements[12];
							testpos.y = testpos.y + meshRef.current.matrixWorld.elements[13];
							testpos.project(camera)
							testpos.x = Math.round((testpos.x * widthHalf) + widthHalf);
							testpos.y = Math.round(-(testpos.y * heightHalf) + heightHalf);
//								console.log("testpos",id, testpos.x, testpos.y, euler)
//								console.log("empty",id, clickedEmptyX, clickedEmptyY)
//							euler = Math.sqrt(Math.pow((testpos.x-clickedEmptyX/width),2) + Math.pow((testpos.y-clickedEmptyY)/height,2));
							euler = Math.sqrt(Math.pow((testpos.x - clickedEmptyX) / width, 2) + Math.pow((testpos.y - clickedEmptyY) / height, 2));
							if (euler < lasteuler) {
								nearest = id;
								lasteuler = euler;
								logPosX = testpos.x;
								logPosY = testpos.y;
//								console.log("nearest",id, testpos.x, testpos.y, euler, lasteuler)
							}
						}
						// XXXX above only for mobile to catch nearest click
					}

					// XXXX below only for mobile to catch nearest click
//						console.log("XXXX nearest", nearest, logPosX, logPosY, lasteuler)
					if (isMo && animation === "done") {
						if (lastnearest !== nearest && lasteuler < 0.05) {
							lastnearest = nearest;
//							console.log("nearest", nearest, logPosX, logPosY, lasteuler)
							setHovered(nearest)
							setNewClick(false)
							setNoHover(true)
						} else if (nearest === -1 && newClick) {
//							console.log("NO NEAREST FOUND")
							setNewClick(false)
							setHovered(-1)
							setNoItemClicked(true)
						}
					}
					// XXXX above only for mobile to catch nearest click

					meshRef.current.instanceMatrix.needsUpdate = true
					meshRef.current.geometry.attributes.color.needsUpdate = true
					if (init) setInit(false);
				}
			})

			var hoverDelay;

			return (
				<instancedMesh
					ref={meshRef} args={[null, null, arrSize]}
					onPointerMove={(e) => {
//					console.log("e:", e, selectedStory);
						e.stopPropagation()
						clearTimeout(hoverDelay)
						hoverDelay = setTimeout(() => {
							if (e.instanceId !== hovered && selectedStory === -1) {
								setHovered(e.instanceId)
							}
						}, 100)

					}}
					onPointerOut={(e) => {
						e.stopPropagation()
						clearTimeout(hoverDelay)
						if (e.instanceId !== hovered) {
							setHovered(-1)
						}
					}}
					onClick={(e) => {
						let hoveredoffset = hovered ? (isSafari ? -45 : -15) : 0;
						let mobileoffset = isLandscape ?
							(isSafari ? hoveredoffset + 55 : 15)
							:
							(isSafari ? hoveredoffset + 45 : 8);
						if (isMo) {
//								setClicked(e.instanceId)
							setClickedEmptyX(e.x);
							setClickedEmptyY(e.y + mobileoffset);
							setNewClick(true);
						}
					}}
					onPointerMissed={(e) => {
						let hoveredoffset = hovered ? (isSafari ? -45 : -15) : 0;
						let mobileoffset = isLandscape ?
							(isSafari ? 55 + hoveredoffset : 15)
							:
							(isSafari ? 45 + hoveredoffset : 8);
						if (isMo) {
							//console.log("animation", animation, isMo)
							// XXXX only for mobile to catch nearest click
//							console.log("clicked2", e.x, e.y, e)
							setClickedEmptyX(e.x);
							setClickedEmptyY(e.y + mobileoffset);
							setNewClick(true)
							// XXXX below commented out only for mobile to catch nearest click
						} else {
//							console.log(">>>>>>>>>>>", useStore.getState().cameraAnimationStopped)
//							console.log(">>>>>>>>>>>", useStore.getState().showPopup)

							if (useStore.getState().cameraAnimationStopped && useStore.getState().showPopup) {
								setNoItemClicked(true)
							}
						}
					}}
				>
					<boxBufferGeometry args={[0.1, 0.1, 0.1]}>
						<instancedBufferAttribute attachObject={['attributes', 'color']} args={[colorArray, 3]}/>
					</boxBufferGeometry>
					<meshPhongMaterial vertexColors={THREE.VertexColors}/>
				</instancedMesh>
			)
		})


		function BoxCloud() {
//			const BoxCloud = React.memo(() => {

//			console.log("BoxCloud called");

			var xx = d3.scaleLinear().domain([0, 6]).range([0, 7]);

			let lanes = 6;

			let length = 300; // 5*60
			let width = 2;
			let xStart = -3;
			let yStart = -20;

			let yearStart = 2020;
			//		let yearStop = 1400;
			let yearStop = 1760;
			//		let yearStop = 2019;

			let heightOffset = 1;

			// lane 1-6
			// year
			// height offset

			var items = [];

//			console.log(lanes, yearStart, yearStop, heightOffset);

			// random with seed
			//	var seed = 15;
			var seed = 1;

			function random() {
				var x = Math.sin(seed++) * 10000;
				return x - Math.floor(x);
			}

			// default random
			function realRandom() {
				return Math.randm();
			}

			itemsList.map((d) => {
				if (d.yearfine) {
					// reason for '0.1 + random()*0.7': set tighter limit for lanes, avoid items next to the lane markings
					items.push([parseInt(d.region - 1) + 0.1 + random() * 0.7, parseInt(d.yearfine), random() * 10, parseInt(d.region) - 1]);
				} else {
					// move items with NaN year to year 2200
					items.push([parseInt(d.region - 1) + 0.1 + random() * 0.7, 2200, random() * 10, parseInt(d.region) - 1]);
				}
			})

//			console.log(items);

//			for (let year = yearStart; year > yearStop; year = year - 3) {
//				for (let lane = 0; lane < lanes; lane++) {
//					items.push([lane + 0.1, year + random() * 2.5, heightOffset])
//					items.push([lane + 0.1, year + random() * 2.5, 10])
//					items.push([lane + 0.3, year + random() * 2.5, 4])
//					items.push([lane + 0.3, year + random() * 2.5, 6])
//					items.push([lane + 0.4, year + random() * 2.5, 1])
//					items.push([lane + 0.4, year + random() * 2.5, 10])
//					items.push([lane + 0.7, year + random() * 2.5, 1])
//					items.push([lane + 0.7, year + random() * 2.5, 10])
//				}
//			}

//		console.log("items:", items.length);

			var rescaleLanes = scaleLinear()
				.domain([0, 6])
				.range([0, 6]);

//			var xx = d3.scaleLinear().domain([0,6]).range([0,7]);

			var rescaleHeight = scaleLinear()
				.domain([1, 10])
				.range([2.1, 2.1]);
//			.range([2, 2.0]);
//			.range([2, 2.7]);

			var ItemsList = items.map((d, i) => {
				// translate
				let lane = rescaleLanes(d[0]);
				let year = rescaleYears(d[1]);
				let offset = rescaleHeight(d[2]);

//			console.log(lane, year);

				colors[i] = mycolors[d[3]];
//			colors[i] = '#f0f';

				box[i * 3] = lane;
				box[i * 3 + 1] = year;
				box[i * 3 + 2] = offset;

				return null
			})

			// get list of cluttered items
			const nearItems = checkItems(items);
//			console.log("nearItems: ", nearItems);
//			console.log("Items: ",items);

			var connectionList = [];

			let debugHelpLines = false;

			if (!debugHelpLines) {
				connectionList = rearrangeItems(items, nearItems);
//				console.log("connectionList: ",connectionList);
			} else {
				const res = rearrangeItemsWithHelpLines(items, nearItems);
				connectionList = res.connectionList;
				linePoints = res.linePoints;
				lineColorArray = res.lineColorArray;
			}

			// split connection list into groups
			connectionList.map((d, i) => {
				connectedGroups = addToGroups(connectedGroups, connectionList.filter(f => f[0] === connectionList[i][0]));
				connectedGroups = addToGroups(connectedGroups, connectionList.filter(f => f[1] === connectionList[i][1]));
			})

//			console.log("connectedGroups", connectedGroups);

			const changes = reArrange(connectedGroups);
//			console.log("changes ",changes);

			shiftBoxArrayX(changes, connectedGroups);
		}

		const HelpLines = () => {
			return (<></>);
//			console.log("lineslist: ",LinesList)
//			console.log("linePoints: ",linePoints)
			var hold;
			var LinesList = linePoints.map((d, index) => {
				if (index % 2 === 0) {
					hold = d;
				} else {
					return (
						<Line key={"lll2" + index} points={[hold, d]} color={lineColorArray[index]} lineWidth={1.845}
						      dashed={false}/>
					)
				}
				return null
			})
			return (
				<> {LinesList} </>
			)
		}

//		useEffect(() => {
//			console.log("debugHelpLines", debugHelpLines);
//			if (debugHelpLines) setInitialized3D(true);
//		}, [debugHelpLines])


		useEffect(() => {
			if (!initialized3D && itemsList) setInitialized3D(true);
			if (itemsList && initialized3D) {
//				console.log(itemsList, initialized3D)
				BoxCloud()
			}
		}, [itemsList, initialized3D, debugHelpLines, width, height])

//		console.log("box: ", box.length);
//		console.log("colors: ", colors.length);

		useEffect(() => {
			setTimeout(() => {
//				console.log("initialized3D");
				setInitialized3D(true);
			}, 1800)
		}, [initialized3D])

		// neede for effects
//	const isSelected = useStore(state => state.hovered);

//		function ItemsRange() {
		const ItemsRange = React.memo(() => {


			const selected = useStore(state => state.selected);
			const itemsList = useStore(state => state.items);

			var xx = d3.scaleLinear().domain([0, 6]).range([0, 7]);

			var region = 1;
			var starty = 2000;
			var stopy = 2001;

			if (selected != -1) {
				region = parseInt(itemsList[selected].region);
				var start = parseInt(itemsList[selected].year_start);

				if (itemsList[selected].year_stop) {
					starty = start;
					stopy = parseInt(itemsList[selected].year_stop);
				} else {      // no year stop found, set range +/- one year
					let interval = 0.5;
					if (start < 0) interval = 1;
					if (start < -5000) interval = 100;
					starty = start + interval;
					stopy = start - interval;
				}
			} else {
				return null
			}
//			console.log("HOVERED", region, starty, stopy, selected, itemsList[selected]);

			var year_start;
			var year_stop;

			if (starty > stopy) {
				year_start = rescaleYears(starty);
				year_stop = rescaleYears(stopy);
			} else {
				year_start = rescaleYears(stopy);
				year_stop = rescaleYears(starty);
			}


			let year_diff = year_stop - year_start;

			return (
				<mesh position={[xx(region - 0.5), year_start + year_diff / 2, 2]}>
					<planeBufferGeometry attach="geometry" args={[xx(1), year_diff]}/>
					<meshStandardMaterial attach="material"
						//				  depthTest={false}
						                  roughness={0.1}
						                  metalness={0.1}
						                  color="black"
						                  transparent
						                  opacity={0.150}
					/>
				</mesh>
			)
		})

//		console.log(":::: debugHelplines", debugHelpLines)
//		console.log(":::: linePoints", linePoints)
//		console.log(":::: linePoints", lineColorArray)
		return (
			<group position={[-3, -20, 0]}>
				<Boxes/>
				{debugHelpLines && <HelpLines linePoints={linePoints} lineColorArray={lineColorArray}></HelpLines>}
				<ItemsRange></ItemsRange>
				{/* EFFECTS */}
				{/*{ isSelected !== -1 && isSelected !== undefined &&*/}
				{/*<EffectComposer multisampling={8} autoClear={false}>*/}
				{/*		<Outline blur xRay kernelSize={3} pulseSpeed={100} selection={isSelected} visibleEdgeColor="white" edgeStrength={500} />*/}
				{/*	</EffectComposer>*/}
				{/*}*/}
			</group>
		)
	}
)
