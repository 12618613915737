export default {
 "name": "Gesamtsammlung",
 "children":
     [
      {"name": "Kunst", "size": 1},
      {"name": "Wirtschaft", "size": 1},
      {"name": "Individuum & Gemeinschaft", "size": 1},
      {"name": "Natur & Geschichte", "size": 1},
      ],

}
