import React, {useState, useEffect} from 'react'
import TruncateMarkup from 'react-truncate-markup';
//import TextTruncate from 'react-text-truncate';
import {mtrack} from "../utils/helpers";

export function ItemDescription(arg) {

//export const ItemDescription = React.memo((arg) => {
//	console.log("---- itemdesc ----", arg);

	const [shouldTruncate, setShouldTruncate] = useState(true);

	// truncate by default if a new item is selected
	useEffect(() => {
//		console.log("arg",arg.new);
//		console.log("arg.new",arg.new);
		if (arg.new && arg.new != -1) {
			setShouldTruncate(true);
		}
	}, [arg.new]);

	var longText = arg.val && arg.val != 'NULL' ? arg.val : ' ';

	longText = longText.replace(/<>/g, '\n');
	longText = longText.replace(/<br.>/g, '\n');
	longText = longText.replace(/\t\n/g, '\n');
	longText = longText.replace(/\t/g, '\n');
	longText = longText.replace(/\n{1,4}/g, '\n');
	longText = longText.replace(/\r\n/g, '\n');
	longText = longText.replace(/\n\r/g, '\n');
	longText = longText.replace(/\n\n/g, '\n');
	longText = longText.replace(/\n\n/g, '\n');
	longText = longText.replace(/\n$/g, '');

	// https://react-truncate-markup-patrik-piskay.vercel.app/
	const readMoreEllipsis = (
		<span>
            &nbsp;...{' '}
			<span className={'link'} onClick={() => {
				mtrack('description more')
				setShouldTruncate(shouldTruncate ? false : true)
			}}>
	            mehr
	        </span>
        </span>
	);

	return (
		<div>

			{shouldTruncate ? (
				<TruncateMarkup
					lines={8}
					tokenize="words"
					ellipsis={readMoreEllipsis}
//					onTruncate={()=>console.log("on truncate called")}
//					key={"tr_"+arg.new+Math.floor(Math.random()*1000)}
				>
					<div>
						{longText.split('\n').map((line, i, arr) => {
							const lineOut = <span className='descriptiveText' key={i}>{line}</span>;
							if (i === arr.length - 1) {
								return lineOut;
							} else {
								return [lineOut, <br key={i + 'br'}/>];
							}
						})}
					</div>
				</TruncateMarkup>
			) : (
				<div>
					{longText.split('\n').map((line, i, arr) => {
						const lineOut = <span className='descriptiveText' key={i}>{line}</span>;
						if (i === arr.length - 1) {
							return lineOut;
						} else {
							return [lineOut, <br key={i + 'br'}/>];
						}
					})}
					&nbsp;
					<span className={"link"} onClick={() => {
						mtrack('description less')
						setShouldTruncate(shouldTruncate ? false : true)
					}}>
								{'weniger'}
							    </span>
				</div>
			)}
		</div>
	)

}
//)

export function ItemDescription2(arg) {
//export const ItemDescription2 = React.memo((arg) => {

	//console.log("---- itemdesc2 ----", arg);

	const [shouldTruncate, setShouldTruncate] = useState(true);

	// truncate by default if a new item is selected
	useEffect(() => {
		if (arg.new && arg.new != -1) {
			setShouldTruncate(true);
		}
	}, [arg.new]);

	var longText = arg.val && arg.val != 'NULL' ? arg.val : ' ';

	longText = longText.replace(/<>/g, '\n');
	longText = longText.replace(/<br.>/g, '\n');
	longText = longText.replace(/\t\n/g, '\n');
	longText = longText.replace(/\t/g, '\n');
	longText = longText.replace(/\n{1,4}/g, '\n');
	longText = longText.replace(/\r\n/g, '\n');
	longText = longText.replace(/\n\r/g, '\n');
	longText = longText.replace(/\n\n/g, '\n');
	longText = longText.replace(/\n\n/g, '\n');
	longText = longText.replace(/\n$/g, '');

//	const div = document.querySelector('.descriptiveText')
//	const styles = window.getComputedStyle(div)
//	const size = 4*styles+6+'px';
//	console.log(styles.fontSize, size)
//	console.log(styles.lineHeight)

	return (
		<div>
			{shouldTruncate ? (
				<div>
					<span className='descriptiveText'> </span>
					<div
						className={'trunc2'}
					>
						{/*<div>*/}
						{longText}
						{/*{longText.split('\n').map((line, i, arr) => {*/}
						{/*	const lineOut = <span className='descriptiveText trunc2' key={i}>{line}</span>;*/}
						{/*	if (i === arr.length - 1) {*/}
						{/*		return lineOut;*/}
						{/*	} else {*/}
						{/*		return [lineOut, <br key={i + 'br'}/>];*/}
						{/*	}*/}
						{/*})}*/}

						{/*</div>*/}
					</div>
					<div>
						{'... '}
						<span className={'link'} onClick={() => {
							mtrack('description2 less');
							setShouldTruncate(shouldTruncate ? false : true)
						}}>
	                    mehr
	                    </span>
					</div>
				</div>
			) : (
				<div>
					{longText.split('\n').map((line, i, arr) => {
						const lineOut = <span className='descriptiveText' key={i}>{line}</span>;
						if (i === arr.length - 1) {
							return lineOut;
						} else {
							return [lineOut, <br key={i + 'br'}/>];
						}
					})}
					&nbsp;
					<span className={"link"} onClick={() => {
						mtrack('description2 more')
						setShouldTruncate(shouldTruncate ? false : true)
					}}>
								{'weniger'}
							    </span>
				</div>
			)}
		</div>
	)
}
//)

export default ItemDescription;
