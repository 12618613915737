import React, {useRef, useState, useMemo, useEffect, useLayoutEffect, useCallback} from "react";
import {useStore} from './store';
import "../css/gallery.css";

import Masonry from 'react-masonry-component';
import Lightbox from 'react-image-lightbox';
//import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import '../css/lightbox.css'; // This only needs to be imported once in your app
import shuffleArray from 'shuffle-array';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import {forceCheck, forceVisible} from 'react-lazyload';

import * as d3 from "d3";
import {useWindowDimensions} from "../utils/hooks";
import {useSpring, animated} from '@react-spring/web';
import {renderIntoDocument} from "react-dom/test-utils";

import {isSafari, isChrome, isTablet} from 'react-device-detect';

import {mtrack} from "../utils/helpers";


export const Gallery = React.memo(() => {


		const masonryOptions = {
//			transitionDuration: 750,
//			transitionDuration: 200,
			transitionDuration: 0,
			stagger: 0,
			gutter: 3,
			itemSelector: '.grid-item',
			columnWidth: '.grid-item',
			fitWidth: true,
			hiddenStyle: {
				transform: 'translateY(0px)',
				opacity: 1
			},
			visibleStyle: {
				transform: 'translateY(0px)',
				opacity: 1
			},
			//	columnWidth: '.grid-sizer',
			percentPosition: true
		};

		const isMo = useStore(state => state.isMobile);
		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);
		const isLandscape = useStore(state => state.isLandscape);


		const setShowLightbox = useStore(state => state.setShowLightbox);
		const imageIndex = useStore(state => state.imageIndex); // lightbox
		const setImageIndex = useStore(state => state.setImageIndex); // lightbox
		const setImageIndexPos = useStore(state => state.setImageIndexPos); // lightbox

		const itemsList = useStore(state => state.items);
		const museumsList = useStore(state => state.museums);

		const {height, width} = useWindowDimensions();

		const transition = useStore(state => state.transition);
		const images = useStore(state => state.images);
		const categoryChanged = useStore(state => state.categoryChanged);
		const fetchImages = useStore(state => state.fetchImages);
		const fetchImagesHovered = useStore(state => state.sunburstHovered);

		const fetchTrigger = useStore(state => state.fetchTrigger);
		const setFetchTrigger = useStore(state => state.setFetchTrigger);

		const museumFilter = useStore(state => state.museumFilter);
		const searchFilter = useStore(state => state.searchFilter);

		const setResetMuseumFilter = useStore(state => state.setResetMuseumFilter);
		const setResetSearchFilter = useStore(state => state.setResetSearchFilter);

		const gallerySelected = useStore(state => state.gallerySelected);
		const setGallerySelected = useStore(state => state.setGallerySelected);

		const halfwidth = width / 2;

		// scrollIntoView => myref
		const myRef = useRef(null);
		const [imageID, setImageID] = useState(-1);


		const masonryref = useRef();


//		useEffect(()=>{
//			const interval = setInterval(()=> {
////				console.log("force check");
////				forceCheck();
////				forceVisible();
//				if (masonryref.current) masonryref.current.masonry.layout();
////				if (masonryref.current) masonryref.current.masonry.reloadItems();
//			}, 3000);return () => clearInterval(interval);
//		}, [])

//
//		useEffect(()=>{
//			console.log(masonryref.current ? masonryref : '-')
//			console.log(masonryref.current ? masonryref.current : '-')
//			if (masonryref.current) masonryref.current.masonry.layout();
//		})


//		// do something when screen slides to zsunburst
//		useEffect(() => {
//			if (transition) {
//				setTimeout(() => {
//				console.log(transition);
//				}, 700)
//			}
//		}, [transition])

		const {opacity} = useSpring({
			from: {opacity: 1},
			to: {opacity: transition ? 1 : 0},
//			duration: transition ? 200 : 200,
			delay: transition ? 1000 : 200,
//	    	config: { }
		})


		const onClick = (d) => {

			// return if no image has been clicked
			if (!d.target.id) {
				return;
			}

//			// google analytics image
//			let text = "image full view: "+d.target.src;
//			ReactGA.event({
//				category: 'Vis',
//				action: text
//			});


			let targetid = parseInt(d.target.id);

			setShowLightbox(true);

			const image = images.nonfaves.filter((x) => x.id === targetid);
//			console.log("D", image[0]);
			mtrack('gallery-lightbox opened for ' + image[0].filename1)

			setImageIndex(image[0]);
			setImageIndexPos(0);
		}


		const handleScroll = (e) => {
			let element = e.target;
//			console.log("SS",element.scrollHeight - Math.floor(element.scrollTop), element.clientHeight)
			if (!fetchTrigger && element.scrollHeight - Math.floor(element.scrollTop) < element.clientHeight + 10) {
//				console.log("scrollEND", element.clientHeight);
				setFetchTrigger(true);
			}
		}

		// scroll to top for new images
		useEffect(() => {
			if (imageID !== categoryChanged) {
				// new images
//				setTimeout(()=> {myRef.current.scrollIntoView()}, 2000) ;
				setTimeout(() => {
//					console.log("scrollto started");
					if (myRef.current) {
						setImageID(categoryChanged);
						myRef.current.scrollTo(0, 0)
					}
				}, 500);
			}
		}, [categoryChanged])

		function getImageInfo(currentImage) {
			const image = itemsList.find((a) => parseInt(a.id) == parseInt(currentImage.id) ? a : '')
			const museum = museumsList.find((a) => parseInt(a.idMandant) === parseInt(image.museum) ? a : '')
			const info = {museum, image};
			return info
		}

		function getTitle(imageIndex) {
			/*
			<div className='imgtitle'>{element.caption}.&nbsp; <a href={url} className="fullview" target='_blank' rel="noopener noreferrer" style={fontstyle} >Klicken Sie hier für Details (in neuem Tab)
				&nbsp;&nbsp;<i><FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></i></a></div>;
			*/
			let title = imageIndex.title.replace(/\s+$/, '');
			let museum = getImageInfo(imageIndex).museum.bezeichnung;
			let outstring = <div> {title} - <i>{museum}</i></div>;
			return outstring
		}

		function getMuseum(id) {
			const museum = museumsList.find((a) => parseInt(a.idMandant) === parseInt(id) ? a : '')
			return museum.bezeichnung;
		}

		function handleResetMuseumFilter() {
//			console.log("reset")
			setResetMuseumFilter(true);
		}

		function handleResetSearchFilter() {
//			console.log("reset")
			setResetSearchFilter(true);
		}

		const handleMouseOver = (e) => {
//			console.log("set paths", e.target.id)
			setGallerySelected(e.target.id)
		}

		const handleMouseOut = (e) => {
//			console.log("reset paths", e)
			setGallerySelected(null)
		}

		const [firstwidth, setFirstWidth] = useState();

		useEffect(() => {
			setFirstWidth(width > height ? width : height)
		}, [])

//		console.log('display dimensions width:', width, firstwidth)

		return (
			<animated.div className="gallerycontainer"
			              style={{
				              width: isMo1 ? isLandscape ? '50vw' : '100vw' : '55vw',
				              height: isMo1 ? isLandscape ? height : height / 2 : height,
//				              backgroundColor: 'green',
				              zIndex: 10,
				              opacity: opacity.to(opacity => opacity),
			              }}
			>
				<Imageloader id={fetchImagesHovered.id}/>

				<div ref={myRef} className="scrollContainer"
				     onScroll={handleScroll}
				     style={{
					     height: '100%',
					     paddingLeft: isLandscape ? '0px' : '10px',
					     width: isMo1 ? isLandscape ? width / 2 - 40 : width : width / 2 - 55,
					     overflow: 'auto', // !!! very important, otherwise placeholder won't update
				     }}
				>
					<div className="gridheader">
						{fetchImagesHovered && fetchImagesHovered.name ?
							"  Auswahl: " + fetchImagesHovered.name
							: '  Auswahl: Gesamtsammlung'
						}
						<div className="resetGalleryFilterContainer">
							{
								(museumFilter !== 0 || searchFilter !== '') && <div> Filter: </div>
							}
							{
								museumFilter !== 0 &&
								<div className="resetGalleryFilterBox">
									<div className="resetGalleryFilterString"
									     title={getMuseum(museumFilter)}>{getMuseum(museumFilter)}</div>
									<div className="resetGalleryFilter" title="filter zurücksetzen"
									     onClick={handleResetMuseumFilter}>X
									</div>
								</div>
							}
							{
								searchFilter !== '' &&
								<div className="resetGalleryFilterBox">
									<div className="resetGalleryFilterString"> '{searchFilter}'</div>
									<div className="resetGalleryFilter" title="filter zurücksetzen"
									     onClick={handleResetSearchFilter}>X
									</div>
								</div>
							}
						</div>
					</div>
					{/*selected:*/}
					{/*{fetchImages ?*/}
					{/*	JSON.stringify(fetchImages.id) + " => " + JSON.stringify(fetchImages.name)*/}
					{/*	: ' nothing selected'}*/}
					{/*<br></br>hovered:*/}
					{/*{fetchImagesHovered ?*/}
					{/*//						JSON.stringify(fetchImagesHovered.id) + " => " + JSON.stringify(fetchImagesHovered.name)*/}
					{/*//						: ' nothing hovered'*/}
					{/*//					}*/}
					{/*{JSON.stringify(fetchImagesHovered)}*/}
					{/*{JSON.stringify(images ? images.nonfaves : 'none')}*/}
					<Masonry ref={masonryref}
					         className={'grid card-columns'} // default ''
					         elementType={'ul'} // default 'div'
					         options={masonryOptions} // default {}
					         onClick={onClick}
					         onMouseOver={handleMouseOver}
					         onMouseOut={handleMouseOut}
						//					ref={function (c) {
						//						this.masonry = this.masonry || c.masonry;
						//					}.bind(this)}


						     updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false

						//	updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
						//	updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
						//							enableResizableChildren={true}
						//	onImagesLoaded={this.handleImagesLoaded}
						     disableImagesLoaded={false} // default false
						//							disableImagesLoaded={true} // default false
						//							imagesLoadedOptions={backgroundColor:'red'} // default {}
					>
						<ImageLister></ImageLister>
						<div className="my-bg-image-el"/>
					</Masonry>

				</div>

			</animated.div>
		);

	}
)

const Imageloader = (id) => {

	const fetchTrigger = useStore(state => state.fetchTrigger);
	const setFetchTrigger = useStore(state => state.setFetchTrigger);

	const fetchTriggerNow = useStore(state => state.fetchTriggerNow);  // trigger fetch when filter changed
	const setFetchTriggerNow = useStore(state => state.setFetchTriggerNow);

	const setCategoryChanged = useStore(state => state.setCategoryChanged);
	const setCurrPage = useStore(state => state.setCurrPage);
	const currPage = useStore(state => state.currPage);

	const [currentID, setCurrentID] = useState(0);

	const [firstLoad, setFirstLoad] = useState(true);

	const images = useStore(state => state.images);
	const setImages = useStore(state => state.setImages);

	const seed = useStore(state => state.seed);

	var id2fetch = id.id ? id.id : 1;

	const museumFilter = useStore(state => state.museumFilter);
	const searchFilter = useStore(state => state.searchFilter);

	var museumsQuery = museumFilter ? '/museum/' + museumFilter : '';
	var searchQuery = searchFilter !== "" ? '/search/' + encodeURI(searchFilter) : '';
	var currentSeed = seed ? '/seed/' + seed : 1;

	const urlImages = process.env.REACT_APP_REST_PATH + `/cachedimages/id/` + id2fetch + museumsQuery + searchQuery + currentSeed;

//	console.log(fetchTrigger, urlImages);

	const fetcher = async (url) => {
		if (seed === 1) return;
//		console.log("########### ====> url", url);
		const response = await fetch(url);
		if (!response.ok) {
			const message = `An error has occured: ${response.status}`;
			throw new Error(message);
		}
		const data = await response.json();
		return data
	}

	// function to split first fetch in two parts
	// to avoid long processing delay at startup
	const split = (arr) => {

		let lim = 2;
		let max = arr.nonfaves.length;


//		console.log("## arr", arr);
		let nonfaves1 = arr.nonfaves.slice(0, lim);
		let nonfaves2 = arr.nonfaves.slice(lim, max);
//		console.log("## split", nonfaves1);
//		console.log("## split", nonfaves2);

		let firstpart = arr;
		firstpart.nonfaves.splice(lim, max - lim)
//		console.log("## out", firstpart);
		return [firstpart, nonfaves2]
	}

	useEffect(() => {

//		console.log("fetchtrigger ", fetchTrigger, fetchTriggerNow)


		// if category has changed, reset states
		if (currentID !== id2fetch) {
//			console.log("-------------- mismatch -------------")
			setCurrPage(0);
			setCurrentID(id2fetch);
			setFetchTrigger(true);
		}

		// test
		if (fetchTriggerNow) {
//			console.log("---- m i s m a t c h ---", fetchTrigger)
			setCurrPage(0);
			setFetchTrigger(true);
			setFetchTriggerNow(false);
//			setTimeout(() => {setFetchTriggerNow(false)}, 500)
		}

		// get actual values from store
		var currentpage = useStore.getState().currPage;
		var currenttrigger = useStore.getState().fetchTrigger;

		if (seed !== 1 && (currenttrigger && (currentpage === 0 || currentpage < parseInt(images.maxpage)))) {
//			if (fetchTriggerNow) {setFetchTriggerNow(false)}
			fetcher((urlImages + '/page/' + currentpage)).then(newImages => {
				if (currentpage > 0) {
					// concat
					let nonfaves = images.nonfaves;
					nonfaves.push.apply(nonfaves, newImages.nonfaves);
					newImages.nonfaves = nonfaves;
					let faves = images.faves;
					faves.push.apply(faves, newImages.faves);
					newImages.faves = faves;
					setImages(newImages);
					setCurrPage(currentpage + 1);
					setFetchTrigger(false);
					// on first load use splitted fetch result
				} else if (firstLoad) {
					let splitted = split(newImages);
					let newarr = splitted[0];
					setImages(newarr)
					setFirstLoad(false)
					setTimeout(() => {
						let nonfaves = splitted[1];
						nonfaves.push.apply(nonfaves, newImages.nonfaves);
						newImages.nonfaves = nonfaves;
						setImages(newImages);
					}, 1000)
					setCategoryChanged(id2fetch);
					setFetchTrigger(false);
					setCurrPage(currentpage + 1);
				} else {
					setImages(newImages);
					setCategoryChanged(id2fetch);
					setFetchTrigger(false);
					setCurrPage(currentpage + 1);
				}

//				console.log("--- images --- ", id2fetch, newImages);
			}).catch(error => {
				console.log("error message catched");
				return error.message;
			});
		}
	}, [id2fetch, fetchTrigger]);

	return (<></>)
}


const ImageLister = () => {

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const [thumbWidth, setThumbWidth] = useState(150);

	const images = useStore(state => state.images);
	const rescaleLargeThumbWidth = d3.scaleLinear().domain([1024, 2560, 4000]).range([150, 180, 250]);

	const rescalethumbwidth = (w) => {
//		console.log("thumbwidth", width, window.innerwidth, window.screen.availWidth)

		let currentWidth = w;
		let safariLandscape = false;

		if (isSafari) {

			if (document.documentElement && ("getBoundingClientRect" in document.documentElement)) {
				currentWidth = document.documentElement.getBoundingClientRect().width;
			} else if (("getBoundingClientRect" in document.body)) {
				currentWidth = document.body.getBoundingClientRect().width;
			} else if (("innerWidth" in window)) {
				currentWidth = window.innerWidth;
			}
			if (window.orientation !== 0) safariLandscape = true;
//			console.log('safari width', width, currentWidth, window.screen.availWidth, window.orientation)
		}

		if (currentWidth < 1024) {
			// assume 3
			let offset = 10 + 15 - 2;

			if (isSafari) {
//				console.log('safari return', currentWidth, safariLandscape ? currentWidth/6 : currentWidth/3,)
				return safariLandscape ? currentWidth / 6 - offset - 7 : currentWidth / 3 - offset
			} else return isLandscape ? currentWidth / 6 - offset - 7 : currentWidth / 3 - offset
		} else {
			return rescaleLargeThumbWidth(currentWidth)
		}
	};

	useEffect(() => {
//		console.log('thumbwidth recomputed, width:', width, rescalethumbwidth(width))

		setThumbWidth(rescalethumbwidth(width));
	}, [width, height])

	// just in case if placeholder renders forever
	useEffect(() => {
		const interval = setInterval(() => {
//			console.log("force check LAZY LOAD");
//			forceCheck();
			forceVisible();
		}, 10000);
		return () => clearInterval(interval);
	}, [])


	return (
		<div>
			{images && images.nonfaves.map((d) =>
					<li className='grid-item' key={"griditem" + d.id}>
						<LazyLoad
							key={"lazy_" + d.id}
							height={Math.floor(thumbWidth / d.filename1ratio)}
							width={thumbWidth}
							once={false}
							//						scrollContainer={document.getElementsByClassName("grid")}
							scrollContainer={'.grid'}
							//						resize={true}
							//						throttle={2300}
							//						debounce={2300}
							overflow={true}
							//						scroll={true}
							//						offset={[6000, -100]}    // no shuffling even when scrolling fast
							offset={[6000, 1000]}    // no shuffling even when scrolling fast
							//						offset={[6000, 90000]}    // no shuffling even when scrolling fast
							//							offset={[900000, 900000]}    // only needed for debugging
							//							offset={[-200, -200]}    // only needed for debugging
							//	unmountIfInvisible={false} // only needed for debugging
							placeholder=
								{
									<div key={"dummypl_" + d.id}
									     className="placeholder d-flex justify-content-center align-items-center"
									     style={{
										     height: Math.floor(thumbWidth / d.filename1ratio),
										     width: thumbWidth,
										     minHeight: Math.floor(thumbWidth / d.filename1ratio),
//								     backgroundColor: 'red'
									     }}
									>
										<div className="spinner-border text-muted align-self-center" role="status"
										     key={'spin_bord' + d.id}>
											{/*<span className="sr-only">Loading...</span>*/}
											<div className="lds-spinner" key={'spin_in' + d.id}
											     style={{top: (thumbWidth / d.filename1ratio) / 2 - 40}}>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</div>
									</div>
								}
						>
							{/*<a href={process.env.REACT_APP_PUBLIC_PATH + '/api/assets/cache/orig/all/' + d.filename1}> </a>*/}
							<div className="img-hover-zoom" key={'img00' + d.id}>
								<img id={d.id}
								     width={thumbWidth}
								     height={Math.floor(thumbWidth / d.filename1ratio)}
								     src={process.env.REACT_APP_PUBLIC_PATH + '/api/assets/cache/orig/allthumbs/' + d.filename1}
								     alt={d.title} title={d.title}/>
							</div>
						</LazyLoad>
					</li>
			)}
		</div>
	)
}
