import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback} from 'react';
import _ from 'lodash';
// convert hex color to rgba, set opacity in second parameter
import {scaleLinear} from "d3";
import {useStore} from "../components/store";

export function mtrack (eventcategory = 'emptylabel', action = "click-event") {
	window._paq.push(['trackEvent', eventcategory, action]);
}

export function getItemsImages(id) {

	const itemsList = useStore.getState().items;
	var imageArr = [];

	var currItem = itemsList[id];
//	var currItem = itemsList.find(f => f.id == id);
//	console.log("** ", currItem, id);


	let fname = currItem.filename1;
	let ratio = currItem.filename1ratio;
	let computedheight = ratio > 1 ? 500 / ratio : 500;
	let sel = fname !== null ? fname.toLowerCase() : null;
	let imagepath = process.env.REACT_APP_PUBLIC_PATH + "/api/assets/cache/orig/all/" + sel;

	imageArr.push({id: 0, itemid: id, path: imagepath, height: computedheight, ratio: ratio})

	let fname2 = currItem.filename2;
	let imagepath2 = null;
	if (fname2) {
//		console.log("fname2: ", fname2)
		let ratio2 = currItem.filename2ratio;
		let computedheight2 = ratio2 > 1 ? 500 / ratio2 : 500;
		let sel2 = fname2 !== null ? fname2.toLowerCase() : null;
		imagepath2 = process.env.REACT_APP_PUBLIC_PATH + "/api/assets/cache/orig/all/" + sel2;
		imageArr.push({id: 0, itemid: id, path: imagepath2, height: computedheight2, ratio2: ratio2})
	}
	return imageArr
}


export function hexToRgbA(hex, opacity) {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
	}
	throw new Error('Bad Hex');
}

export function radian(angle) {return 2 * Math.PI * (angle / 360)};

var yearsRange = scaleLinear()
	.domain([1400, 2020])
	.range([+310, 0]);

export function rescaleYears(year) {

	const {zerooffset, firstoffset, secondoffset, thirdoffset, fourthoffset, fifthoffset, sixtoffset, seventhoffset, lastoffset} = useStore.getState();
	const {zerocompression, firstcompression, secondcompression, thirdcompression, fourthcompression, fifthcompression, sixtcompression, seventhcompression} = useStore.getState();

	const firstdiff = zerooffset - firstoffset;
	const seconddiff = firstoffset - secondoffset;
	const thirddiff = secondoffset - thirdoffset;
	const fourthdiff = thirdoffset - fourthoffset;
	const fifthdiff = fourthoffset - fifthoffset;
	const sixtdiff = fifthoffset - sixtoffset;
	const seventhdiff = sixtoffset - seventhoffset;


	if (year < firstoffset && year > secondoffset) {
		let offset = zerooffset - firstdiff * zerocompression;
		return yearsRange(offset + ((year - firstoffset) * firstcompression));
	}
	if (year <= secondoffset && year > thirdoffset) {
		let offset = zerooffset - firstdiff * zerocompression - seconddiff * firstcompression;
		return yearsRange(offset + ((year - secondoffset) * secondcompression));
	}
	if (year <= thirdoffset && year > fourthoffset) {
		let offset = zerooffset - firstdiff * zerocompression - seconddiff * firstcompression - thirddiff * secondcompression;
		return yearsRange(offset + ((year - thirdoffset) * thirdcompression));
	}
	if (year <= fourthoffset && year > fifthoffset) {
		let offset = zerooffset - firstdiff * zerocompression - seconddiff * firstcompression - thirddiff * secondcompression - fourthdiff * thirdcompression;
		return yearsRange(offset + ((year - fourthoffset) * fourthcompression));
	}
	if (year <= fifthoffset && year > sixtoffset) {
		let offset = zerooffset - firstdiff * zerocompression - seconddiff * firstcompression - thirddiff * secondcompression - fourthdiff * thirdcompression - fifthdiff * fourthcompression;
		return yearsRange(offset + ((year - fifthoffset) * fifthcompression));
	}
	if (year <= sixtoffset && year > seventhoffset) {
		let offset = zerooffset - firstdiff * zerocompression - seconddiff * firstcompression - thirddiff * secondcompression -
			fourthdiff * thirdcompression - fifthdiff * fourthcompression - sixtdiff * fifthcompression;
		return yearsRange(offset + ((year - sixtoffset) * sixtcompression));
	}
	if (year <= seventhoffset && year > lastoffset) {
		let offset = zerooffset - firstdiff * zerocompression - seconddiff * firstcompression - thirddiff * secondcompression -
			fourthdiff * thirdcompression - fifthdiff * fourthcompression  - sixtdiff * fifthcompression - seventhdiff * sixtcompression;
		return yearsRange(offset + ((year - seventhoffset) * seventhcompression));
	} else
		return yearsRange(year)
}

export function getCompression(year) {

	const {zerooffset, firstoffset, secondoffset, thirdoffset, fourthoffset, fifthoffset, sixtoffset, seventhoffset, lastoffset} = useStore.getState();
	const {zerocompression, firstcompression, secondcompression, thirdcompression, fourthcompression, fifthcompression, sixtcompression, seventhcompression} = useStore.getState();

	if (year < firstoffset   && year > secondoffset)    {return firstcompression}
	if (year <= secondoffset && year > thirdoffset)     {return secondcompression}
	if (year <= thirdoffset  && year > fourthoffset)    {return thirdcompression}
	if (year <= fourthoffset && year > fifthoffset)     {return fourthcompression}
	if (year <= fifthoffset  && year > sixtoffset)      {return fifthcompression}
	if (year <= sixtoffset   && year > seventhoffset)   {return sixtcompression}
	if (year <= seventhoffset)                          {return seventhcompression}
	else
		return zerocompression
}


