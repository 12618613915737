import React from "react";
import {Line, Text, Plane} from "@react-three/drei";
import {useStore} from "./store";
import * as d3 from "d3";

export const GauBar = React.memo(() => {

		const HelpLines = () => {

			var points = [];

			var xx = d3.scaleLinear().domain([0, 6]).range([0, 7]);

			// long lines
			for (let j = 0; j < 6; j++) {
				points.push([xx(j) - 3, -22, 2.001], [xx(j) - 3, -15, 2.001])
			}

			var hold;

			var HelpLinesList = points.map((d, index) => {
				if (index % 2 === 0) {
					hold = d;
				} else {
					return (
						<Line key={"hl" + index} points={[hold, d]} color="white" lineWidth={0.4}/>
					)
				}
			})
			return (<>{HelpLinesList}</>)
		}

		const Bars = () => {

			const isMo = useStore(state => state.isMobile);
			const isMo1 = useStore(state => state.isMobile1);
			const isMo2 = useStore(state => state.isMobile2);

			let barLength = 1 * 1.166;
			let barHeigth = 0.16;

			const colors = useStore(state => state.gauColors);
			const fontColors = useStore(state => state.gauFontColors);

			var xx = d3.scaleLinear().domain([0, 6]).range([0, 7]);

			const names = ['Flachgau', 'Tennengau', 'Pongau', 'Pinzgau', 'Lungau'];

			var pointsBars = [];
			var pointsText = [];

			for (let i = 0; i < 5; i++) {
				pointsBars.push([-3 + (xx(i) * 1) + barLength / 2, -22, 2.001 - barHeigth / 2]);
				pointsText.push([-3 + (xx(i) * 1) + barLength / 2, -22, 2.001 - barHeigth / 2]);
			}

			var BarTags = pointsBars.map((d, i) => {
				return (
					<group key={"bartag" + i}>
						<Plane
							//							rotation={[Math.PI / 2.1, 0, isMo2 ? Math.PI/2 * 2 : 0]}
							rotation={[isMo2 ? 0 : Math.PI / 2, 0, 0]}
							position={d}
							args={[barLength, isMo2 ? barHeigth + 0.1 : isMo1 ? barHeigth + 0.1 : barHeigth]}
							material-color={colors[i]}
							material-polygonOffset={true}
							material-polygonOffsetUnits={-1}
							material-polygonOffsetFactor={-1000}
						>
						</Plane>
						<Text
							key={'bartext' + i}
							rotation={[isMo2 ? 0 : Math.PI / 2, 0, 0]}
							position={pointsText[i]}
							fontSize={isMo2 ? 0.17 : isMo1 ? 0.16 : 0.08}
							color={fontColors[i]}
							depthOffset={-10001}
							//							depthOffset={-1000001}
						>
							{names[i]}
						</Text>
					</group>
				)
			})
			return <>{BarTags}</>
		}

		return (
			<group>
				<HelpLines/>
				<Bars></Bars>
			</group>
		)
	}
)
