import React from 'react';

export function Umbrella(props) {
	let iconColor = props.color;
	return (
		<svg className='umbrella' x="0px" y="0px" width="100%" height="100%" viewBox="0 0 116 255.333">
			<path fill={iconColor} d="M60.342,184.341"/>
			<path fill={iconColor} d="M57.388,185.954"/>
			<polyline fill={iconColor} points="60.365,184.341 60.365,184.312 60.342,184.341 "/>
			<line x1="60.62" y1="184.191" x2="60.62" y2="184.191"/>
			<path fill={iconColor} d="M58.334,186.334v34.311c1,17.922-6.426,19.869-9.899,19.869c-9.299,
							0-12.231-0.18-12.231-19.18H33.62h-3.417 c0,19,1.944,24.18,18.231,24.18c13.809,0,15.899-8.919,
							15.899-24.807v-34.373H58.334z"/>
			<polygon fill={iconColor} points="106.121,186.334 79.745,164.388 106.121,149.04 60.371,
							8.709 14.245,147.638 41.745,164.455 14.621,186.334 "/>
			<path fill={iconColor} d="M41.414,163.466"/>
			<path fill={iconColor} d="M60.289,163.33"/>
		</svg>
	)
}

export function HelpButton() {
	let color = 'gray'
	let stroke = 30

	return (
		<svg x="0px" y="0px" width="64px" height="44px" viewBox="0 0 640 460">
			<g>
				<circle fill="none" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"
				        strokeMiterlimit="10" cx="323.97" cy="240.312" r="207.5"/>
				<g>
					<path fill={color} strokeWidth={2} stroke={color} d="M333.982,277.415H311.41v-15.627c0-5.247,1.06-9.376,3.184-12.386c2.121-3.01,6.771-7.293,13.948-12.849
			c10.957-8.411,16.437-17.324,16.437-26.74c0-7.099-2.141-12.733-6.424-16.9c-4.283-4.167-9.859-6.251-16.727-6.251
			c-15.898,0-25.274,10.997-28.129,32.99l-25.234-4.515c1.543-16.437,7.62-29.344,18.231-38.72
			c10.609-9.376,23.324-14.064,38.142-14.064c14.738,0,26.931,4.381,36.578,13.138c9.646,8.76,14.47,19.661,14.47,32.701
			c0,6.482-1.331,12.637-3.994,18.463c-2.662,5.827-5.691,10.342-9.086,13.543c-3.397,3.203-10.574,8.817-21.531,16.842
			c-3.087,2.239-5.055,4.419-5.903,6.54C334.521,265.705,334.059,270.316,333.982,277.415z M338.612,293.852v28.939H311.41v-28.939
			H338.612z"/>
				</g>
			</g>
		</svg>
	)
}


export function FullScreen(props) {
//	let color = 'gray'
	let color = props.color ? props.color : 'white';
	let opacity = props.opacity ? props.opacity : 1;
	let width = props.width ? props.width : '100%';
	let stroke = 9;

	return (
			<svg x="0px" y="0px" opacity={opacity}
			     width={width} height="100%" viewBox="0 0 375 345">


	<path fill={color} strokeWidth={2} stroke={color}

	      d="M233.965,119.888l21.094,21.75l49.688-49.453l19.172,18.469V51.638h-60l19.733,19.125L233.965,119.888z M143.965,223.106
		l-21.328-21.469l-49.313,49.547l-19.359-18.188l0.516,58.642l60.094-0.469l-19.922-18.892L143.965,223.106z M255.715,201.638
		l-21.75,21.094l49.453,49.688l-18.469,19.219h59.016v-60.047l-19.125,19.733L255.715,201.638z M122.355,141.638l21.609-21.234
		l-49.5-49.5l18.328-19.266l-58.828,0.281l0.234,60.094l18.984-19.828L122.355,141.638z"

	/>
				<path fill={color} strokeWidth={stroke} stroke={color}

				      d="M346.465,336.638h-315c-12.422,0-22.5-10.078-22.5-22.5v-285c0-12.422,10.078-22.5,22.5-22.5h315
		c12.422,0,22.5,10.078,22.5,22.5v285C368.965,326.56,358.887,336.638,346.465,336.638z M31.465,21.638c-4.125,0-7.5,3.375-7.5,7.5
		v285c0,4.125,3.375,7.5,7.5,7.5h315c4.125,0,7.5-3.375,7.5-7.5v-285c0-4.125-3.375-7.5-7.5-7.5H31.465z"

				/>
			</svg>
	)
}

export function ExitFullScreen(props) {
//	let color = 'gray'
	let color = props.color ? props.color : 'white';
	let opacity = props.opacity ? props.opacity : 1;
	let stroke = 1

	return (
		<svg x="0px" y="0px" opacity={opacity}
		     width="100%" height="100%" viewBox="0 0 375 345">
			<path fill={color} strokeWidth={stroke} stroke={color}
			      d="M139.23,65.05c-0.32-2.639-3.559-3.759-5.438-1.879l-17.474,17.474L62.856,27.223c-1.239-1.24-3.279-1.24-4.519,0
		L41.383,44.137c-1.24,1.24-1.24,3.279,0,4.519l53.462,53.463l-17.554,17.554c-1.879,1.879-0.759,5.119,1.879,5.438l64.059,7.558
		c2.04,0.24,3.799-1.479,3.559-3.56L139.23,65.05z M143.269,214.28l-64.099,7.559c-2.639,0.32-3.758,3.559-1.879,5.438
		l17.554,17.555l-53.462,53.383c-1.24,1.238-1.24,3.278,0,4.519l16.954,16.914c1.24,1.239,3.279,1.239,4.519,0l53.462-53.422
		l17.474,17.475c1.879,1.879,5.118,0.761,5.438-1.88l7.558-64.019C147.027,215.76,145.308,214.041,143.269,214.28L143.269,214.28z
		 M231.959,132.628l64.099-7.559c2.64-0.319,3.76-3.559,1.88-5.438l-17.555-17.514l53.462-53.423c1.239-1.239,1.239-3.278,0-4.519
		l-16.954-16.914c-1.239-1.24-3.278-1.24-4.519,0L258.91,80.645l-17.475-17.474c-1.879-1.879-5.119-0.76-5.438,1.879l-7.558,64.019
		C228.2,131.148,229.92,132.868,231.959,132.628L231.959,132.628z M280.383,244.789l17.555-17.554c1.88-1.88,0.76-5.118-1.88-5.438
		l-64.059-7.558c-2.039-0.24-3.799,1.479-3.56,3.559l7.558,64.059c0.319,2.639,3.56,3.759,5.438,1.879l17.475-17.475l53.462,53.424
		c1.239,1.238,3.278,1.238,4.519,0l16.954-16.915c1.239-1.239,1.239-3.278,0-4.519L280.383,244.789z"
			/>

		</svg>
	)
}

export function Dummy() {
	return (
		<div style={{border: '1px solid green'}}>

		</div>
	)
}
