import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback} from 'react'
import {Leva, useControls} from 'leva';
import {useStore} from "./store";


export function LevaComponent() {

	const debugLevel = useStore(state => state.debugLevel);

	const setInitialize3D = useStore(state => state.setInitialized3D);
	const initialize3D = useStore(state => state.initialized3D);

	// switch color scheme changes colors in various components, therefore a store ist used
	const colorScheme = useStore(state => state.colorScheme);

	const setColor = useStore(state => state.setColor);
	const color = useStore(state => state.color);
	const setOverlay = useStore(state => state.setOverlay);

	const setDebugHelpLines = useStore(state => state.setDebugHelpLines);

	const setHideStats = useStore(state => state.setHideStats);
//	const hideStats = useStore(state => state.hideStats);

	const setShowSunburst = useStore(state => state.setShowSunburst);
	const setSunburstColor1 = useStore(state => state.setSunburstColor1);
	const setSunburstColor2 = useStore(state => state.setSunburstColor2);
	const setSunburstFontColor = useStore(state => state.setSunburstFontColor);
	const setSunburstOpacity = useStore(state => state.setSunburstOpacity);

	const setGauColor1 = useStore(state => state.setGauColor1);
	const setGauColor2 = useStore(state => state.setGauColor2);
	const setGauColor3 = useStore(state => state.setGauColor3);
	const setGauColor4 = useStore(state => state.setGauColor4);
	const setGauColor5 = useStore(state => state.setGauColor5);

	const setBackgroundColor1 = useStore(state => state.setBackgroundColor1);
	const setFogColor1 = useStore(state => state.setFogColor1);
	const setLandscapeColor1 = useStore(state => state.setLandscapeColor1);
	const setOverlayBackgroundColor1 = useStore(state => state.setOverlayBackgroundColor1);

	const [reload, setReload ] = useState(false);

	const {sceneColors, overlay} = useControls({
		sceneColors: {
			value: debugLevel === 1 ? 'custom': colorScheme,
//			options: ["grey", "green", "blue01", "blue02", "blue03", "blue04", "blue05", "custom" ],
			options: ["grey", "blue03", "blue04", "custom" ],
			onChange: (v) => {
				setColor(v);
//				console.log("changed color scheme", v, colorScheme);
				setInitialize3D(false)
			},
		},
		overlay: {
			value: "vertical",
//			value: "horizontal",
			options: ["vertical", "horizontal"],
			onChange: (v) => {
				setOverlay(v);
//				console.log("changed overlay", v);
				setInitialize3D(false)
			},
		},
	});



	useEffect(()=>{
//		console.log('--------- color:',color)
//		if (reload && debugLevel == 1) {
		if (reload) {
			setTimeout(()=>{
				setColor(colorScheme);
//				setColor("custom");
//				setHideStats(hideStats ? true : false);
				setInitialize3D(false);
//				setHideStats(true);
				setReload(false);
			}, 500)
		}
	}, [reload])

	const {colorscheme} = useControls(
		'colorscheme: CUSTOM',
		{
			gauColor1: {
				value: '#f75847',
				onChange: v => {setGauColor1(v); setReload(false);}
			},
			gauColor2: {
				value: '#FFB92B',
				onChange: v => {setGauColor2(v); setReload(false);}
			},
			gauColor3: {
				value: '#FFEA51',
				onChange: v => {setGauColor3(v); setReload(false);}
			},
			gauColor4: {
				value: '#FFFBCA',
				onChange: v => {setGauColor4(v); setReload(false);}
			},
			gauColor5: {
				value: '#9BCA3E',
				onChange: v => {setGauColor5(v); setReload(false);}
			},
			backgroundColor: {
				value: '#5C6A89',
				onChange: v => {setBackgroundColor1(v); setReload(false);}
			},
			fogColor: {
				value: '#5C6A89',
				onChange: v => {setFogColor1(v); setReload(false);}
			},
			landscapeColor: {
				value: '#D7DEE8',
				onChange: v => {setLandscapeColor1(v); setReload(false);}
			},
			overlayBackgroundColor: {
				value: '#4f546c',
				onChange: v => {setOverlayBackgroundColor1(v); setReload(false);}
			},
		}
	)

	const {sunburst} = useControls(
		'sunburst',
		{
			showSunburst: {
				label: 'show sunbrust',
//				value: false,
				value: true,
				onChange: (v) => setShowSunburst(v)
			},
			Color1: {
				value: '#ffb429',
//				value: 'orange',
				onChange: v => setSunburstColor1(v)
			},
			Color2: {
				value: '#ba2d77',
//				value: 'purple',
				onChange: v => setSunburstColor2(v)
			},
			FontColor: {
				value: '#111',
				onChange: v => setSunburstFontColor(v)
			},
			Opacity: {
				value: 0.8,
				min: 0,
				max: 1,
				step: 0.01,
				onChange: v => setSunburstOpacity(v)
			},
		}
	)
	const {debugHelpLines} = useControls(
			'debug',
			{
				debugHelpLines: {
					value: false,
					onChange: (v) => ()=> setDebugHelpLines(v)
				}
			},
			{
				"collapsed":
					true
			}
		)
	const {hideStats} = useControls(
			'debug',
			{
				hideStats: {
					value: true,
					onChange: v => {
//						console.log("hide", v);
						setHideStats(v);
						setReload(true);}
				}
			},
			{
				"collapsed":
					false
			}
		)

	;

//
//	useEffect(()=>{
//		console.log('hideStats:',hideStats)
//		if (reload && debugLevel == 1) {
//			setTimeout(()=>{
//				setColor("custom");
//				setInitialize3D(false);
//				setReload(false);
//			}, 3000)
//		}
//	}, [reload])
//

	// adding theme for leva, see: https://codesandbox.io/s/leva-theme-o1w0e?file=/src/App.jsx
	const theme = {
		colors: {
			elevation1: '#FFFFFF00',
			highlight1: '#777777',
			highlight2: '#CCCCCC',
			highlight3: '#FFFFFF'
		},
		sizes: {titleBarHeight: '19px', folderTitleHeight: '10px'}
	};

	return (
		<div className="levaGUI">
			{/*<button>hide</button>*/}
			{ /* this will render the panel inside the parent div */}
			<Leva theme={theme}
			      fill
			      flat
			      collapsed
			      hidden={debugLevel ? false : true}
			/>
		</div>
	)
}

