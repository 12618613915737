import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useSpring, config} from '@react-spring/core'
import {useGesture} from 'react-use-gesture'
import clamp from 'lodash/clamp'
import {useStore} from './store'
import {isFirefox} from "react-device-detect";

export function useXScroll(bounds, props) {
//export const useXScroll = React.memo((bounds, props) => {

	const setScrolling = useStore(state => state.setScrolling);
	const setScrollingJump = useStore(state => state.setScrollingJump);
	const scrollingJump = useStore(state => state.scrollingJump);

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isSafari = useStore(state => state.isSafari);

	// get hoveredPopup value from store without re-rendering scene
	// Fetch initial state
	const onNoScrollArea = useRef(useStore.getState().hoveredPopup);
	const onGallery = useRef(useStore.getState().transition);

	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	useEffect(() => useStore.subscribe(
		hoveredPopup => (onNoScrollArea.current = hoveredPopup),
		state => state.hoveredPopup
	), [])

	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	useEffect(() => useStore.subscribe(
		transition => (onGallery.current = transition),
		state => state.transition
	), [])

	useEffect(() => {
		if (useStore.getState().updateScrollXPosition !== null) {
//			console.log("updateScrollPosition:",useStore.getState().updateScrollPosition);
			let newX = clamp(useStore.getState().updateScrollXPosition, ...bounds);
//			console.log("updateScrollPositionclamped:",newY);
			set({x: newX});

			// set scrollingFlag to show, hide, update refline
			setScrollingJump(true);
			setTimeout(() => {setScrollingJump(false)}, 2000)  // also avoids that automatic scrolling is interrupted by manual scrolling

			useStore.getState().setUpdateScrollXPosition(null);
		}
	})

	const [{x}, set] = useSpring(() => ({x: 0, config: config.slow}))

	const fn2 = useCallback(
		({down, movement: [mx, my], memo = x.get(), active: a, velocity: v}) => {

//			console.log("DRAGspy: ",mx, my, down, a, v);
//			console.log("DRAGspy: ",memo);
//			console.log("DRAGspy: ismo1",isMo, isMo1, isMo2);

//			if (useStore.getState().scrolling !== a && v < 0.2) {
//				console.log("scrolling: ",useStore.getState().scrolling);
//				let delay = a === true ? 10 : 2000;
//				setTimeout(()=>setScrolling(a), delay)
//			}

//			if (my - memo > 5) {
////				console.log('large scroll')
//			}

			let accelerator = isSafari || isFirefox ? 0.05 : 0.1;

//			let newY = clamp(memo + (-my * accelerator), ...bounds)
//			set({y: newY})

			let newX = clamp(memo + (mx * v * accelerator), ...bounds)

//			if (isMo1 !== true || isMo2 !== true) newX = memo;
//			if (isMo2 === false) newX = memo;

			// allow x-scrolling on any mobile
			if (isMo === false) newX = memo;

			// when mouse over popup then do not scroll
			if (useStore.getState().dragged) {
				newX = memo;
			}

			// when mouse over popup then do not scroll
			if (onNoScrollArea.current) {
				newX = memo;
			}

			// when mouse over gallery then do not scroll
			if (onGallery.current) {
				newX = memo;
			}

			// when mouse over popup then do not scroll
			if (useStore.getState().dragged) {
				newX = memo;
			}

			set({x: newX})
			return newX
		},
		[bounds, x, set]
	)

	const bind = useGesture({
			onDrag: fn2
		},
		{
			domTarget: window,
			drag: {
				enabled: true,
//				filterTaps: true,
//				useTouch: false,
//				preventDefault: true,
			}
		},
	)

	return [x, bind]
}
//)

export default useXScroll;
