import React, {useState, useRef, useEffect} from 'react'
import {useStore} from "./store";
import {useThree} from "@react-three/fiber";

export default () => {

	const scene = useThree(state => state.scene);
	const canvas = useThree(state => state.canvas);

	const fogColor = useStore(state => state.fogColor);
	const backgroundColor = useStore(state => state.backgroundColor);

	useEffect(() => {
//		console.log(" --> canvas: ", canvas);
//		console.log(" --> fogColor: ", fogColor);
//		console.log(" --> backgroundColor: ", backgroundColor);
//		console.log(" --> scene: ", scene);
		scene.fog.color.set(fogColor)
//		scene.background=backgroundColor;
		scene.background.set(backgroundColor);
	});

	return (
		<></>
	)

}
