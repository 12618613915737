import _ from 'lodash';
import {scaleLinear} from "d3";

export function checkItems (items) {

	//	console.log(items);

	var nearItems = [];

	var xx = scaleLinear().domain([0,6]).range([0,7]);
	var yy = scaleLinear().domain([0,7]).range([0,6]);


	for (let i = 0; i < items.length; i++) {
//		console.log(items[i]);
		for (let j = 0; j < items.length; j++) {
			if (i !== j && items[i][2] !== items[j][2]) {

				let xdiff = Math.abs(yy(items[i][0])-yy(items[j][0]));
				let ydiff = Math.abs(items[i][1]-items[j][1]);
				let zdiff = Math.abs(items[i][2]-items[j][2]);

				let regiondiff = Math.abs(items[i][3]-items[j][3]);

//				console.log(regiondiff, items[i][3], items[j][3]);

				if ( xdiff < 0.6 && ydiff < 0.6 && (xdiff+ydiff)/2 < 0.6 && regiondiff === 0) {
					let out = [items[j][0], items[j][1], items[j][2],
						'yellow', j, i,
						items[j][0], items[j][1], items[j][2],
						items[i][3], items[j][3]    // store regions
					]
					nearItems.push(out);
				}
			}
		}
	}
	return nearItems;
}


export function addToGroups(connectedGroups, arr) {

	const source = arr[0][0];
	const target = arr[0][1];

	const flat = _.flatten(connectedGroups);

	const findSource = flat.filter(d => d === source);
	const findTarget = flat.filter(d => d === target);

	let index = 0;

	if (findSource.length > 0 && findTarget.length > 0) {
		// everything is already there, do nothing
	} else if (findSource.length > 0 && findTarget.length === 0) {
		// add target if source is found
		_.forEach(connectedGroups, (group) => {
			let res = group.find(i => i === source) ? connectedGroups[index].push(target) : index++;
		})
	} else if (findSource.length === 0 && findTarget.length > 0) {
		// add source if target is found
		_.forEach(connectedGroups, (group) => {
			let res = group.find(i => i === target) ? connectedGroups[index].push(source) : index++;
		})
	} else {
		// start new group
		connectedGroups.push(arr[0])
	}
	return connectedGroups
}


export function rearrangeItems(items, nearItems) {

	// console.log("rearrange Items called");

	const connectionList = []
	nearItems.map((d, i) => {
		items.map((y, i) => {
			let diff = Math.abs(d[1] - y[1]);
//			if (Math.floor(d[0]) === Math.floor(y[0]) && diff < 10 && diff !== 0) {
			if (d[9] === y[3] && diff < 1) {
				connectionList.push([d[4], i])
			}
		})
	})
	return connectionList
}

export function reArrange(connectedGroups) {

	var changes = [];

	connectedGroups.map((d, i) => {

		let slotpos = [0.1, 0.3, 0.5, 0.7, 0.9, 0.2, 0.4];
		let slotpos4 = [0.2, 0.4, 0.6, 0.8];
		let slotpos6 = [0.1, 0.25, 0.45, 0.6, 0.75, 0.9];
		let slot = [];

		switch (d.length) {
			case 2:
				slot = [1, 3]
				d.map((dd, ii) => {
					changes.push([dd, slotpos[slot[ii]], 0]);
				})
				break;
			case 3:
				slot = [0, 2, 4]
				d.map((dd, ii) => {
					changes.push([dd, slotpos[slot[ii]], 0]);
				})
				break;
			case 4:
				slot = [0, 1, 2, 3]
				d.map((dd, ii) => {
					changes.push([dd, slotpos4[slot[ii]], 0]);
				})
				break;
			case 5:
				slot = [0, 1, 2, 3, 4]
				d.map((dd, ii) => {
					changes.push([dd, slotpos[slot[ii]], 0]);
				})
				break;
			case 6:
				slot = [0, 1, 2, 3, 4, 5]
				d.map((dd, ii) => {
					changes.push([dd, slotpos6[slot[ii]], 0]);
				})
				break;
			default:
				break;
		}

		if (d.length > 6 && d.length < 14) {
			d.map((dd, ii) => {
				changes.push([dd, 0.05 + (ii / d.length)*0.9, ii %2 === 0 ? 0 : 0.2])
			})
//			console.log("::: changes",changes)
		}
		if (d.length > 13 && d.length < 20) {
			d.map((dd, ii) => {
				changes.push([dd, 0.05 + (ii % 4 * -0.07) + (ii / d.length)*1, ii % 4 * 0.2])
			})
			//console.log("::: changes",changes)
		}

	});
	return changes
}


export function rearrangeItemsWithHelpLines(items, nearItems) {

	var linePoints = [];  // start and endpoint for helper lines
	var lineColorArray = []; // color array for lines

	const connectionList = []

	var rescaleYears = scaleLinear()
		.domain([1400, 2020])
		.range([-1310, 5]);
//		.domain([1400, 2020])
//		.range([+310, 0]);

	var rescaleLanes = scaleLinear()
		.domain([0, 6])
		.range([0.1, +6.1]);

	var rescaleHeight = scaleLinear()
		.domain([1, 10])
					.range([2, 2.2]);
		//			.range([2, 2.3]);
//		.range([2, 2.7]);

	//console.log("XXXXnearItems",nearItems);

	nearItems.map((d, i) => {

		var start = [];
		var stop = [];

		// translate
		let lane = rescaleLanes(d[0]);
		let year = rescaleYears(d[1]);
		let offset = rescaleHeight(d[2]);
		let linecolor = d[3];
		let targetId = d[4];
		let sourceId = d[5];

		if (sourceId < targetId) {
//					box[sourceId * 3] = box[sourceId * 3] - 0.15;
		}

		// get all neighbours on similar row position
		items.map((y, i) => {

			let diff = Math.abs(d[1] - y[1]);
			let minoffset = 0.01;

			if (Math.floor(d[0]) === Math.floor(y[0]) && diff < 2 && diff !== 0) {

				connectionList.push([targetId, i])

				if (diff < 0.1) minoffset = 0.00

				var start1 = [];
				var stop1 = [];

				start1[0] = lane - minoffset;
				start1[1] = year;
				start1[2] = offset;

				stop1[0] = rescaleLanes(y[0] + minoffset);
				stop1[1] = rescaleYears(y[1]);
				stop1[2] = rescaleHeight(y[2]);

				linePoints.push(start1);
				linePoints.push(stop1);

				lineColorArray.push('white');
				lineColorArray.push('white');
			}
		})


		// mark neighbours
		start[0] = lane;
		start[1] = year;
		start[2] = offset;

		stop[0] = lane;
		stop[1] = year;
		stop[2] = offset + 0.3;

		linePoints.push(start);
		linePoints.push(stop);

		lineColorArray.push(linecolor);
		lineColorArray.push(linecolor);
	})

	const res = {connectionList: connectionList, linePoints: linePoints, lineColorArray: lineColorArray}

	//console.log("YYY linePoints",res)

	return res
}

