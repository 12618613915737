import create from 'zustand';

export const useStore = create((set, get) => ({

	// timescale

	zerooffset:     2020,
	firstoffset:    1700,
	secondoffset:   0,
	thirdoffset:    -10000,
	fourthoffset:   -100000,
	fifthoffset:    -1000000,
	sixtoffset:     -10000000,
	seventhoffset:  -100000000,
	lastoffset:     -500000001,

	zerocompression: 1,
	firstcompression: 0.15,
	secondcompression: 0.01,
	thirdcompression:   Math.pow(10, -3.6),
	fourthcompression:  Math.pow(10, -4.4),
	fifthcompression:   Math.pow(10, -5.3),
	sixtcompression:    Math.pow(10, -6.3),
	seventhcompression: Math.pow(10, -6.4),

	// ---------------
	// init
	// ---------------

	initialized3D: false,
	setInitialized3D: (initialized3D) => set((state) => ({initialized3D})),

	firstModalCompleted: false,
	setFirstModalCompleted: (firstModalCompleted) => set((state) => ({firstModalCompleted})),

	isTriggerReload : false,
	setTriggerReload: (isTriggerReload) => set((state) => ({isTriggerReload})),

	remotePending: false,
	setRemotePending: (remotePending) => set((state) => ({remotePending})),

	seed: 1,
	setSeed: (seed) => set((state) => ({seed})),

	// ---------------
	// device & query
	// ---------------

	isMobile: false,
	setIsMobile: (isMobile) => set((state) => ({isMobile})),
	isMobile1: false,
	setIsMobile1: (isMobile1) => set((state) => ({isMobile1})),
	isMobile2: false,
	setIsMobile2: (isMobile2) => set((state) => ({isMobile2})),
	device: false,
	setDevice: (device) => set((state) => ({device})),
	isLandscape: false,
	setIsLandscape: (isLandscape) => set((state) => ({isLandscape})),
	isMoIOS: false,
	setIsMoIOS: (isMoIOS) => set((state) => ({isMoIOS})),
	isSafari: false,
	setIsSafari: (isSafari) => set((state) => ({isSafari})),

	modalLeft: null,
	setModalLeft: (modalLeft) => set((state) => ({modalLeft})),

	isFullScreen: false,
	setIsFullScreen: (isFullScreen) => set((state) => ({isFullScreen})),
	isFullScreenAvailable: true,
	setIsFullScreenAvailable: (isFullScreenAvailable) => set((state) => ({isFullScreenAvailable})),

	query: "",
	setQuery: (query) => set((state) => ({query})),
	debugLevel: 0,
	setDebugLevel: (debugLevel) => set((state) => ({debugLevel})),

	// ---------------
	// interaction
	// ---------------

	selected: -1,
	setSelected: (selected) => set((state) => ({selected})),

	remote: -1,
	setRemote: (remote) => set((state) => ({remote})),

	noItemClicked: false,
	setNoItemClicked: (noItemClicked) => set ((state) => ({noItemClicked})),
	sunburstSelected: -1,
	setSunburstSelected: (sunburstSelected) => set((state) => ({sunburstSelected})),
	sunburstHovered: -1,
	setSunburstHovered: (sunburstHovered) => set((state) => ({sunburstHovered})),

	gallerySelected: null,
	setGallerySelected: (gallerySelected) => set((state) => ({gallerySelected})),

	hoveredCoords: [0,0,0],
	setHoveredCoords: (hoveredCoords) => set((state) => ({hoveredCoords})),
	hoveredCoords2: [0,0,0],
	setHoveredCoords2: (hoveredCoords2) => set((state) => ({hoveredCoords2})),
	hoveredPopup: false,
	setHoveredPopup: (hoveredPopup) => set((state) => ({hoveredPopup})),

	dragged: false,
	setDragged: (dragged) => set((state) => ({dragged})),

	closePopup: false,
	setClosePopup: (closePopup) => set((state) => ({closePopup})),
	showPopup: false,
	setShowPopup: (showPopup) => set((state) => ({showPopup})),

	// search
	museumSelectOpen : false,
	setMuseumSelectOpen: (museumSelectOpen) => set((state) => ({museumSelectOpen})),


	// image-lightbox
	isOpen: false,
	setIsOpen: (isOpen) => set(state => ({isOpen})),
	showLightbox: false,
	setShowLightbox: (showLightbox) => set(state => ({showLightbox})),
	imageIndex: -1,
	setImageIndex: (imageIndex) => set(state => ({imageIndex})),
	imageIndexPos: 0,
	setImageIndexPos: (imageIndexPos) => set(state => ({imageIndexPos})),

	// other lightboxes
	showMediaOverlay: false,
	setShowMediaOverlay: (showMediaOverlay) => set((state) => ({showMediaOverlay})),
	showVideo: false,
	setShowVideo: (showVideo) => set((state) => ({showVideo})),
	show3D: false,
	setShow3D: (show3D) => set((state) => ({show3D})),
	showAudio: false,
	setShowAudio: (showAudio) => set((state) => ({showAudio})),

	showTour: false,
	setShowTour: (showTour) => set((state) => ({showTour})),
	selectedStory: -1,
	setSelectedStory: (selectedStory) => set((state) => ({selectedStory})),

	showInfo: false,
	setShowInfo: (showInfo) => set((state) => ({showInfo})),

	showInfo2: false,
	setShowInfo2: (showInfo2) => set((state) => ({showInfo2})),

	showImpressum: false,
	setShowImpressum: (showImpressum) => set((state) => ({showImpressum})),

	scrolling: false,
	setScrolling: (scrolling) => { set ((state) => ({scrolling}))},
	scrollingJump: false,
	setScrollingJump: (scrollingJump) => { set ((state) => ({scrollingJump}))},
	cameraAnimationStopped: false,
	setCameraAnimationStopped: (cameraAnimationStopped) => { set ((state) => ({cameraAnimationStopped}))},
	cameraStoryAnimationStopped: false,
	setCameraStoryAnimationStopped: (cameraStoryAnimationStopped) => { set ((state) => ({cameraStoryAnimationStopped}))},

	showReferenceLine: false,
	setShowReferenceLine: (showReferenceLine) => { set ((state) => ({showReferenceLine}))},
	showStoryReferenceLine: false,
	setShowStoryReferenceLine: (showStoryReferenceLine) => { set ((state) => ({showStoryReferenceLine}))},

	isInView: [],
	setIsInView: (val) => { set ((state) => ({isInView: [...state.isInView, val]}))},
	unsetIsInView: (val) => {
		const prev = get().isInView;
		let arr = prev.filter((d) => d !== val);
//		console.log("QQ: prev, arr: ", prev, arr, val)
		set ((state) => ({isInView: arr}))
	},
	resetIsInView: () => {
		set ((state) => ({isInView: []}))
	},

	isJumpInView: -1,
	setIsJumpInView: (isJumpInView) =>  set(state => ({isJumpInView})),

	// screen transition: timeline=false, zsunburst=true;
	transition: false,
	setTransition: (d) => set(state => ({transition: d})),

	jumpClicked: false,
	setJumpClicked: (jumpClicked) => set((state) => ({jumpClicked})),
	increaseJumpClicked: () => set(state => ({ jumpClicked: state.jumpClicked + 1 })),

	jumpDistance: 30,
	setJumpDistance: (jumpDistance) => set(state => ({jumpDistance})),

	jumpRegion: -1,
	setJumpRegion: (jumpRegion) => set(state => ({jumpRegion})),

	animateJump: false,
	setAnimateJump: (animateJump) => set(state => ({animateJump})),
	animateYearJump: false,
	setAnimateYearJump: (animateYearJump) => set(state => ({animateYearJump})),

	updateScrollPosition: null,
	setUpdateScrollPosition: (updateScrollPosition) => set(state => ({updateScrollPosition})),

	updateScrollXPosition: null,
	setUpdateScrollXPosition: (updateScrollXPosition) => set(state => ({updateScrollXPosition})),

	// ---------------
	// onboarding
	// ---------------

	isOnboarding: false,
	setIsOnboarding: (d) => set(state => ({isOnboarding: d})),

	// ---------------
	// help
	// ---------------

	clickedHelp: false,
	setClickedHelp: (d) => set(state => ({clickedHelp: d})),
	hoveredHelp: 0,
	setHoveredHelp: (d) => set(state => ({hoveredHelp: d})),
	hoveredModel3D: 0,
	setHoveredModel3D: (d) => set(state => ({hoveredModel3D: d})),
	hoveredHelpTimeline: 0,
	setHoveredHelpTimeline: (d) => set(state => ({hoveredHelpTimeline: d})),
	hoveredHelpSunburst: 0,
	setHoveredHelpSunburst: (d) => set(state => ({hoveredHelpSunburst: d})),

	// ---------------
	// fetch data
	// ---------------

	items: undefined,
	setItems: (items) => set((state) => ({items})),
	museums: undefined,
	setMuseums: (museums) => set((state) => ({museums})),
	media3D: undefined,
	setMedia3D: (media3D) => set((state) => ({media3D})),
	tree: undefined,
	setTree: (tree) => set((state) => ({tree})),


	images: undefined,
	setImages: (images) => set((state) => ({images})),

	fetchImages: 0,
	setFetchImages: (fetchImages) => set((state) => ({fetchImages})),

	categoryChanged: 0,
	setCategoryChanged: (categoryChanged) => set((state) => ({categoryChanged})),

	currPage: 0,
	setCurrPage: (currPage) => set((state) => ({currPage})),

	fetchTrigger: false,
	setFetchTrigger: (fetchTrigger) => set((state) => ({fetchTrigger})),

	fetchTriggerNow: false,
	setFetchTriggerNow: (fetchTriggerNow) => set((state) => ({fetchTriggerNow})),

	museumFilter: 0,
	setMuseumFilter: (museumFilter) => set((state) => ({museumFilter})),

	searchFilter: "",
	setSearchFilter: (searchFilter) => set((state) => ({searchFilter})),

	resetMuseumFilter: false,
	setResetMuseumFilter: (resetMuseumFilter) => set((state) => ({resetMuseumFilter})),

	resetSearchFilter: false,
	setResetSearchFilter: (resetSearchFilter) => set((state) => ({resetSearchFilter})),


	// ---------------
	// LEVA (see also color-scheme)
	// ---------------

	hideStats: false,
	setHideStats: (hideStats) => set((state) => ({hideStats})),

	colorScheme: 'blue04',
	setColorScheme: colorScheme => set (state => ({colorScheme})),

	overlay: -1,
	setOverlay: overlay => set(state => ({overlay})),
	debugHelpLines: -1,
	setDebugHelpLines: val => set(state => ({debugHelpLines: val})),
	showSunburst: false,
	setShowSunburst: val => set (state =>({showSunburst: val})),
	sunburstColor1: 'black',
	setSunburstColor1: val => set (state =>({sunburstColor1: val})),
	sunburstColor2: 'black',
	setSunburstColor2: val => set (state =>({sunburstColor2: val})),
	sunburstFontColor: 'black',
	setSunburstFontColor: val => set (state =>({sunburstFontColor: val})),
	sunburstOpacity: 0.8,
	setSunburstOpacity: val => set (state =>({sunburstOpacity: val})),

	gauColor1: '#fcca46',
	setGauColor1: val => set (state =>({gauColor1: val})),
	gauColor2: '#a1c181',
	setGauColor2: val => set (state =>({gauColor2: val})),
	gauColor3: '#fe7f2d',
	setGauColor3: val => set (state =>({gauColor3: val})),
	gauColor4: '#619b8a',
	setGauColor4: val => set (state =>({gauColor4: val})),
	gauColor5: 'f94144',
	setGauColor5: val => set (state =>({gauColor5: val})),
	backgroundColor1: '#5C6A89',
	setBackgroundColor1: val => set (state =>({backgroundColor1: val})),
	fogColor1: '5C6A89',
	setFogColor1: val => set (state =>({fogColor1: val})),
	landscapeColor1: 'D7DEE8',
	setLandscapeColor1: val => set (state =>({landscapeColor1: val})),
	overlayBackgroundColor1: '#4f546c',
	setOverlayBackgroundColor1: val => set (state =>({overlayBackgroundColor1: val})),

	// ---------------
	// color-scheme
	// ---------------
	// initial values

	color: 'grey',
	svg: 0,
	backgroundColor: "#666666",
	fogColor: '#888888',
	gauColors: ["#fcca46", '#f94144', '#fe7f2d', '#619b8a', '#a1c181', '#277da1'],
	gauFontColors: ["black", 'black', 'black', 'black', 'black', 'white'],
	landscapeColor: '#D7D7D7',
	overlayBackgroundColor: '#2B2B2B',
	setColor: (color) =>
		set(state => {
			switch (color) {
				case 'grey':
					return {
						svg: 0,
						backgroundColor: "#666666",
						fogColor: '#888888',
						gauColors: ["#fcca46", '#f94144', '#fe7f2d', '#619b8a', '#a1c181', '#277da1'],
						gauFontColors: ["black", 'black', 'black', 'black', 'black', 'white'],
						landscapeColor: '#D7D7D7',
						overlayBackgroundColor: '#2b2b2b',
					}
					break;
				case 'green':
					return {
						svg: 2,
						backgroundColor: "#2D6434",
						fogColor: '#2D6434',
						gauColors: ["#fcca46", '#a1c181', '#fe7f2d', '#619b8a', '#f94144', '#81c3b6'],
						gauFontColors: ["black", 'black', 'black', 'black', 'white', 'black'],
						landscapeColor: '#bde7bd',
						overlayBackgroundColor: '#214926',
					}
					break;
				case 'blue01':
					return {
						svg: 1,
						backgroundColor: "#5C6A89",
						fogColor: '#5C6A89',
						gauColors: ["#fcca46", '#a1c181', '#fe7f2d', '#619b8a', '#f94144', '#81c3b6'],
						gauFontColors: ["black", 'black', 'black', 'black', 'black', 'black'],
						landscapeColor: '#D7DEE8',
						overlayBackgroundColor: '#4f546c',
					}
					break;
				case 'blue02':
					return {
						svg: 1,
						backgroundColor: "#5C6A89",
						fogColor: '#5C6A89',
						gauColors: ["#ED5315", '#FFEA51', '#FFB92B', '#E6E6E6', '#9BCA3E'],
						gauFontColors: ["black", 'black', 'black', 'black', 'black', 'black'],
						landscapeColor: '#D7DEE8',
						overlayBackgroundColor: '#4f546c',
					}
					break;
				case 'blue03':
					return {
						svg: 1,
						backgroundColor: "#5C6A89",
						fogColor: '#5C6A89',
						gauColors: [
							"#F94143",
							'#FFAA2B',
							'#D8D8D8',
							'#8ecdfc',
							'#284158',

							'#5a95e2',
							'#5782ba',
							],
						gauFontColors: [
							"black",
							'black',
							'black',
							'black',
							'white',
						],
						landscapeColor: '#D7DEE8',
						overlayBackgroundColor: '#4f546c',
					}
					break;
				case 'blue04':
					return {
						svg: 1,
						backgroundColor: "#5C6A89",
						fogColor: '#5C6A89',
						gauColors: [
							"#f75847",
							'#FFB92B',
							'#FFEA51',
							'#FFFBCA',
							'#9BCA3E',

							"#F94143",
							"#ED5315",
							"#f2642c",
							'#d3d926',
							'#97c144',
							'#a1c181',
						],
						gauFontColors: ["black", 'black', 'black', 'black', 'black', 'black'],
						landscapeColor: '#D7DEE8',
						overlayBackgroundColor: '#4f546c',
					}
					break;

				case 'blue05':
					return {
						svg: 1,
						backgroundColor: "#5C6A89",
						fogColor: '#5C6A89',
						gauColors: ["#EFFF47", '#98FF68', '#66CDCC', '#669ACC', '#934791', '#000'],
						gauFontColors: ["black", 'black', 'black', 'black', 'white', 'black'],
						landscapeColor: '#D7DEE8',
						overlayBackgroundColor: '#4f546c',
					}
					break;

				case 'custom':
					return {
						svg: 1,
						backgroundColor: useStore.getState().backgroundColor1,
						fogColor: useStore.getState().fogColor1,
						gauColors: [useStore.getState().gauColor1, useStore.getState().gauColor2, useStore.getState().gauColor3, useStore.getState().gauColor4, useStore.getState().gauColor5, '#000'],
						gauFontColors: ["black", 'black', 'black', 'black', 'black', 'black'],
						landscapeColor: useStore.getState().landscapeColor1,
						overlayBackgroundColor: useStore.getState().overlayBackgroundColor1,
					}
					break;
				default:
					break;
			}
		}),

	// ---------------
	// testing
	// ---------------

	addVar: (newState) => {
		//console.log("newState", newState);
		set((state) => ({globalState: [...state.globalState, newState]}));
	},
	removeAllVars: () => set({globalState: ''}),
	fishies: {},
	fetch: async pond => {
		const response = await fetch(pond)
		set({fishies: await response.json()})
	}
}));
