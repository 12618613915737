import {Plane, useTexture} from "@react-three/drei";
import textureUrlBlue from "../assets/skyline_salzburg_blueish_02_basis.svg";
import textureUrlGrey from "../assets/skyline_salzburg_greyish_02_derived.svg";
import textureUrlGreen from "../assets/skyline_salzburg_greenish_02_derived.svg";
import React from "react";
import * as THREE from 'three';
import {useStore} from "./store";
import {radian} from "../utils/helpers";

export const Background = React.memo(() => {

		const SvgColor = useStore(state => state.svg);
		const colors = [textureUrlGrey, textureUrlBlue, textureUrlGreen];

		const texture = useTexture(colors[SvgColor]);

		if (texture) {
			texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
			texture.repeat.set(3, 1);
			texture.anisotropy = 16;
		}

		return (
			<group>
				<mesh layers={0} scale={[5900, 1600, 1600]} rotation={[Math.PI / 2, 0, 0]} position={[0, 1650, -580]}>
				{/*<mesh layers={0} scale={[5900, 1600, 1600]} rotation={[Math.PI / 2, 0, 0]} position={[0, 1600, -580]}>*/}
					<planeGeometry/>
					<meshBasicMaterial fog={false} map={texture} depthTest={true}/>
				</mesh>
				{/*	base */}
				<Plane position={[0, 0, -50]} args={[3000, 2000]} material-color={'#666666'}></Plane>
				{/*<Plane position={[0,0,-50]} args={[3000,2000]} material-color={'white'}></Plane>*/}
				{/* front */}
				<Plane position={[0, 1801, 0]} args={[6000, 6000]} rotation={[radian(90), 0, 0]}
				       material-color={'white'}></Plane>
				{/* right */}
				<Plane position={[1000, 2000, 0]} args={[3000, 6000]} rotation={[0, radian(-90), 0]}
				       material-color={'white'}></Plane>
				{/* left */}
				<Plane position={[-1000, 2000, 0]} args={[3000, 6000]} rotation={[radian(0), radian(90), radian(0)]}
				       material-color={'white'}></Plane>
			</group>
		)
	}
)
