import ReactDOM from 'react-dom'
import React from 'react'
import './styles.css'
import App from './App'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

const urlBase = 'https://matomo.mindfactor.at/'

const instance = createInstance({
	urlBase: `${urlBase}`,
	siteId: 3,
//	userId: 'UID987'+Math.floor(Math.random() * 600000) + 1, // optional, default value: `undefined`.
	trackerUrl: `${urlBase}matomo.php`, // optional, default value: `${urlBase}matomo.php`
	srcUrl: `${urlBase}matomo.js`, // optional, default value: `${urlBase}matomo.js`
	disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	heartBeat: { // optional, enabled by default
		active: true, // optional, default value: true
		seconds: 10 // optional, default value: `15
	},
//	linkTracking: false, // optional, default value: true
	linkTracking: true, // optional, default value: true
	configurations: { // optional, default value: {}
		// any valid matomo configuration, all below are optional
		disableCookies: true,
//		setSecureCookie: true,
		setRequestMethod: 'POST'
	}
})

ReactDOM.render(
	<MatomoProvider value={instance}>
		<App />
	</MatomoProvider>,
	document.getElementById('root')
)

//ReactDOM.render(<App />, document.getElementById('root'))
