import React, {useRef, useState, useMemo, useEffect, useLayoutEffect, useCallback} from "react";
import {Canvas, useFrame, useThree} from "@react-three/fiber";
//import {MeshStandardMaterial, BoxBufferGeometry} from "three";
//import niceColors from 'nice-color-palettes';
import {scaleLinear} from "d3";
import * as THREE from "three";
import {useStore} from './store';
import {Html, Line} from "@react-three/drei";

//import React, {Component} from 'react';
import * as d3 from "d3";
//import data from './Data';
import data from '../resource/Data_root';
import {useWindowDimensions} from "../utils/hooks";
import {useSpring, animated} from '@react-spring/web';
import {mtrack} from "../utils/helpers";

export function Sunburst() {

	const {x, r} = useSpring({x: 0, r: 0})

	const {height, width} = useWindowDimensions();

	const [init, setInit] = useState(false);

	const sunburstSelected = useStore(state => state.sunburstSelected);
	const setSunburstSelected = useStore(state => state.setSunburstSelected);
	const setSunburstHovered = useStore(state => state.setSunburstHovered);

	const transition = useStore(state => state.transition);
	const setTransition = useStore(state => state.setTransition);

	const noItemClicked = useStore(state => state.noItemClicked);
	const setNoItemClicked = useStore(state => state.setNoItemClicked);

	const sunburstColor1 = useStore(state => state.sunburstColor1);
	const sunburstColor2 = useStore(state => state.sunburstColor2);
	const sunburstFontColor = useStore(state => state.sunburstFontColor);
	const sunburstOpacity = useStore(state => state.sunburstOpacity);

	const color = d3.scaleSequential()
		.interpolator(d3.interpolateRgb(sunburstColor1, sunburstColor2))
//		.domain([-1, 7]);
		.domain([12, -1]);

	const drawChart = () => {

		setInit(true);
		const format = d3.format(",d");

		const showHierarchies = 2;

		const currWidth = width;
		const fontScale = 26 / 1280;  // ideal: 28px on width = 1280
		const segmentsPadding = 0.009;
		var rscale = d3.scaleLinear().domain([1, 1.85, 2]).range([1, 1.98, 2]);

		const radius = currWidth / 6 * (1.9);    // 1.125
		const shrink = radius / 3 * 2;          // make inner radius smaller

		const elevate = -radius / 10;
		const unelevate = 0;

		const arc = d3.arc()
			.startAngle(d => d.x0 + Math.PI / 2 * 2)
			.endAngle(d => d.x1 + Math.PI / 2 * 2)
			.padAngle(d => Math.min((d.x1 - d.x0) / 2, segmentsPadding))
			.padRadius(radius * 1.5)
			.innerRadius(d => radius * rscale((d.y0)) - shrink + 1 - 1)
			.outerRadius(d => Math.max(radius * rscale((d.y0)) - shrink, radius * rscale((d.y1)) - shrink - 1));

		const partition = data => {
			const root = d3.hierarchy(data)
				.sum(d => d.size)
//				.sort((a.order, b.order) => b.value - a.value);
			return d3.partition()
				.size([1 * Math.PI / 2, root.height + 1])(root);
		};

		const root = partition(data);

		root.each(d => d.current = d);
//		console.log(root);

		const svg = d3.select('#partitionSVG')
			.style("width", "100%")
			.style("height", "auto")
			.style("font", (width * fontScale) + "px sans-serif")
			.on("click", clickedSVG);

		const g = svg.append("g")
			.attr("transform", `translate(${currWidth / 2},${currWidth / 2})`);

//		console.log(svg);

		const path = g.append("g")
			.selectAll("path")
			.data(root.descendants().slice(1))
			.join("path")
			.attr("class", "segments")
			.attr("fill", (d, i) => {
				while (d.depth > 1) {
					d = d.parent;
				}
//				return color(d.data.name);
				return color(i);
			})
			.attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.9 : 0.8) : 0)
			.attr("pointer-events", "all")
			.attr("cursor", "pointer")
			.on("mouseenter", onmouseenter)
			.on("mouseleave", resetAllSegments)
			.on("click", clicked)
			.attr("d", d => arc(d.current));

		path.filter(d => d.children)
			.style("cursor", "pointer")
			.on("click", clicked);

		path.append("title")
			//			.text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);
			.text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}`);

		const label = g.append("g")
			.attr("pointer-events", "none")
			//			.attr("text-anchor", "middle")
			.attr("text-anchor", "right")
			.style("user-select", "none")
			.selectAll("text")
			.data(root.descendants().slice(1))
			.join("text")
			.attr("dy", "0.35em")
			.attr("fill-opacity", d => +labelVisible(d.current))
			.style("fill", sunburstFontColor)
			.attr("transform", d => labelTransform(d.current))
			.text(d => d.data.name);
//		console.log(label);

		const parent = g.append("circle")
			.datum(root)
			.attr("r", radius / 3)
			.style("cursor", "pointer")
			.attr("fill", "none")
			.attr("pointer-events", "all")
			.on("click", transition);

		// -------------------------
		// interaction functions
		// -------------------------

		function resetAllSegments() {
			d3.selectAll("path.segments")
				.attr("fill", (d, i) => {
					while (d.depth > 1) {
						d = d.parent;
					}
					return color(i);
				});
			d3.selectAll('#partitionSVG').selectAll("text").style("fill", sunburstFontColor);
			setSunburstHovered(-1);
//			mtrack('littlesunburst reset')
		}

		function onmouseenter(e) {
			resetAllSegments();
			d3.select(this).attr("fill", "purple");

//			console.log("d3 select this",d3.select(this));

			const currNode = d3.select(this).data();
			d3.selectAll('#partitionSVG').selectAll("text")
				.filter((d) => d.data.name === currNode[0].data.name)
				.style("fill", "white")

			setSunburstHovered(currNode[0].data.name);
			mtrack('littlesunburst over'+currNode[0].data.name)

//			console.log("currNode: ",currNode[0].data.name);
		}

		function clickedSVG(e) {
			// check if empty space of partitionSVG is clicked
			if (e.target.id === 'partitionSVG') {
				resetSelection();
				setSunburstSelected(-1);
				setNoItemClicked(true);
//				mtrack('littlesunburst reset')
			}
		}

		function transition(e) {
//			setTransition(true);

//			d3.select("svg#partitionSVG").attr("id", "usg")
//				.attr("style", function () {
////				return "-webkit-transform: perspective(800) scale(1) scale3d(1, 1, 1) rotate3d(1, 0, 0, 0deg) translate3d(0, 0, 0);";
//					return "-webkit-transform: rotate3d(0, 0, 0, 0deg);" +
//							"font-size:" + (width * fontScale) + "px";
//				})
//			setTimeout(()=>{
////				d3.select("#usg").transition().duration(0).attr("style", function () {
//////				return "-webkit-transform: perspective(800) scale(1) scale3d(1, 1, 1) rotate3d(1, 0, 0, 55deg) translate3d(0px, 198px, 0px);";
////					return "-webkit-transform: rotate3d(0, 1, 0, 0deg);" +
////						"font-size:" + (width * fontScale) + "px;"
////				})
//
//			}, 500);
////			rot();
//			function rot () {
////				d3.transition(roti).duration
//				d3.select("#usg").transition(rot).duration(1000).attr("style", function () {
////				return "-webkit-transform: perspective(800) scale(1) scale3d(1, 1, 1) rotate3d(1, 0, 0, 55deg) translate3d(0px, 198px, 0px);";
//					return "-webkit-transform: rotate3d(0, 1, 0, 90deg);" +
//						"font-size:" + (width * fontScale) + "px";
//				})
//			}
		}

		function clicked(p) {

			const clickedSegment = d3.select(this).data()[0].data.name;
			const lastSelection = useStore.getState().sunburstSelected;

			if (clickedSegment !== lastSelection) {
				d3.selectAll("path.segments").attr("transform", "scale(1)");
				d3.selectAll('#partitionSVG').selectAll("text")
					.transition()
					.duration(200)
					.attr("x", unelevate);
				d3.select(this)
					.transition()
					.duration(200)
					.attr("transform", "translate(0 0) scale(1.1)");
				d3.selectAll('#partitionSVG').selectAll("text")
					.filter((d) => d.data.name === clickedSegment)
					.transition()
					.duration(200)
					.attr("x", elevate);
				setSunburstSelected(clickedSegment);
				mtrack('littlesun path: '+clickedSegment)
			} else {
				resetSelection();
//				mtrack('littlesunburst reset')
			}
		}

		function arcVisible(d) {
			return d.y1 <= showHierarchies && d.y0 >= 1 && d.x1 > d.x0;
		}

		function labelVisible(d) {
			return d.y1 <= showHierarchies && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
		}

		function labelTransform(d) {
			let offset = Math.PI / 2 * 2;
			const x = (d.x0 + 2 * Math.PI + d.x1) / 2 * 180 / Math.PI;
			const y = (d.y0 + d.y1) / 2 * radius - shrink + radius / 2.2;           // radius / 2.2 = offset of text from the left hand side
			return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
		}
	}

	// repaint sunburst
	useLayoutEffect(() => {
		// delete current sunburst to avoid painting over existing sunburst
		if (init) {
			d3.select('#partitionSVG').selectAll('*').remove();
		}
		drawChart();
	}, [width, height, sunburstColor1, sunburstColor2])

	useEffect(() => {
		d3.selectAll('#partitionSVG').selectAll("text").style('fill', sunburstFontColor);
	}, [sunburstFontColor])

	// reset colors when screen slides to zsunburst
	useEffect(() => {
		if (!transition) {
			setTimeout(()=>{
				d3.selectAll("path.segments")
					.attr("fill", (d, i) => {
						while (d.depth > 1) {
							d = d.parent;
						}
						return color(i);
					});
			}, 700)
		}
	}, [transition])

	const {rotate} = useSpring({
		from: {rotate: 0},
		to: {rotate: transition ? 90 : 0},
		duration: 500,
		delay: transition ? 0 : 1000,
//		config: { }
	})

	useEffect(() => {
		d3.select("g").selectAll("path.segments").attr('fill-opacity', sunburstOpacity);
	}, [sunburstOpacity])

	useEffect(() => {
		resetSelection();
	}, [noItemClicked])


	function resetSelection() {
		d3.selectAll('#partitionSVG').selectAll("text")
			.transition()
			.duration(200)
			.attr("x", 0);
		d3.selectAll("path.segments")
			.transition()
			.duration(200)
			.attr("transform", "scale(1)");
		setSunburstSelected(-1);
	}

	const radius = width / 4;
	const viewbox = "0 0 " + (width) + " " + (width);

	return (
		<animated.div className="screenoverlay sunburst"
		              style={{
			              position: 'absolute',
			              top: -radius,
			              right: -radius,
			              transform: rotate.to(rotate => `rotate3d(0,1,0,${rotate}deg)`),
			              zIndex: 105,
		              }}
		>
			<svg id="partitionSVG" viewBox={viewbox}>
			</svg>
		</animated.div>
	);

}
