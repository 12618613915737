import React, {Suspense, useEffect, useLayoutEffect, useState, useRef} from "react";
import {Items} from "./items";
import {Landscape} from "./landscpe";
import {TimeLine} from "./timeline";
import {GauBar} from "./gauBar";
import {Background} from "./background";

import {a} from '@react-spring/three';
import useYScroll from "./useYScroll";
import useXScroll from "./useXScroll";
import {useStore} from "./store";
import {Html} from "@react-three/drei";
import {Canvas, useFrame} from "@react-three/fiber";
import * as THREE from "three";
import {useWindowDimensions} from "../utils/hooks";

const Scene = () => {

	const myref = useRef();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const [y] = useYScroll([-9500, -5], {domTarget: window})
	const [x] = useXScroll(isMo2 ? [-9500, 15] : isLandscape ? [-30, 50] : [-80, 40], {domTarget: window})

	const jumpDistance = useStore(state => state.jumpDistance);
	const jumpRegion = useStore(state => state.jumpRegion);

	const animateJump = useStore(state => state.animateJump);
	const setAnimateJump = useStore(state => state.setAnimateJump);
	const animateYearJump = useStore(state => state.animateYearJump);
	const setAnimateYearJump = useStore(state => state.setAnimateYearJump);
	const setUpdateScrollPosition = useStore(state => state.setUpdateScrollPosition);
	const setUpdateScrollXPosition = useStore(state => state.setUpdateScrollXPosition);

	// jump to (relative) position
	useLayoutEffect(() => {
		if (animateJump) {
//			console.log("animateRelativeJump", jumpDistance);
			setUpdateScrollPosition(((myref.current.position.y - jumpDistance) * 500) / 25);
			setAnimateJump(false);
		}
	}, [animateJump])

	// jump to Year
	useLayoutEffect(() => {
		if (animateYearJump) {
//			console.log("animateYearJump", jumpDistance, jumpRegion, -Math.PI/2*1.5);
			setUpdateScrollPosition(((jumpDistance) * 500) / 25);
//			if (jumpRegion !== -1) setUpdateScrollXPosition(-Math.PI / 2 * 1.5+1-jumpRegion);
			if (jumpRegion !== -1 && isMo1 && !isLandscape) setUpdateScrollXPosition((2-jumpRegion)*500/25);
			setAnimateYearJump(false);
		}
	}, [animateYearJump])

	const offsetY = isMo2 ? -30.4 : 0;
	const offsetX = isMo2 ? 14.8 : 0;
	const offsetZ = isMo1 ? isLandscape ? 0.3 : 0.1 : 0;
//	const offsetZ = isMo2 ? -12.1 : 0;

	return (
		<group>
			<a.group ref={myref}
//			         position-y={y.to((yPos) => (yPos / 500) * 25 + isMo2 ? -32 : 0)}
			         position-y={y.to((yPos) => isMo2 ? yPos+offsetY : (yPos / 500) * 25 + offsetY)}
			         position-x={x.to((xPos) => isMo1 || isMo2 ? (xPos / 500) * 25 + offsetX : xPos)}
			         position-z={offsetZ}
			         rotation-z={isMo2 ? Math.PI/2*-1 : 0}
			>
				<Landscape/>
				<TimeLine/>
				<Items/>
				<Suspense fallback={null}>
					<Background/>
				</Suspense>
			</a.group>
			<a.group
			    position-y={isMo2 ? offsetY : 0}
				position-x={x.to((xPos) => {
					if (isMo1) {
						return (xPos / 500) * 25 + +offsetX;
					} else
					if (isMo2) {
						return offsetX;
					} else {
						return xPos;
					}
				})
			}
				position-z={offsetZ}
				rotation-z={isMo2 ? Math.PI/2*-1.0 : 0}
			>
				<GauBar />
			</a.group>
		</group>
	)
}


export default Scene;

