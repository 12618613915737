import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import "../css/audio.css";
import {useStore} from './store';
import {useEffect, useRef, useState} from "react";
import {mtrack} from "../utils/helpers";

export default function ShowAudio(d) {

	let audiopath = process.env.REACT_APP_PUBLIC_PATH + "/api/assets/cache/orig/audio/" + d.filename;

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const setDragged = useStore((state) => state.setDragged);
	const dragged = useStore((state) => state.dragged);

//	const [drag, setDrag] = useState(false);

//	const audio = useRef();

	return (
		<div className='audiowrapper'
		style={{
			padding: isMo1 ? isLandscape ? '15px 10px 20px 10px': '15px 10px 20px 10px': '15px 20px 15px 20px',
		}}
		     onTouchStartCapture={(e) => {
				 e.stopPropagation();
//				 e.preventDefault();
				 setDragged(true)}}
		     onTouchEnd={(e) => {
				 e.stopPropagation();
//				 e.preventDefault();
				 setDragged(false)}}
		     onTouchCancel={(e) => {
				 e.stopPropagation();
//				 e.preventDefault();
				 setDragged(false)}}
		>
			<AudioPlayer
//				ref={audio}
				layout={isMo ? "stacked-reverse" : "horizontal-reverse"}
				customAdditionalControls={[]}
				src={audiopath}
				onPlay={e => {
//					console.log("onPlay")
					mtrack('story audio play '+d.filename)
				}}
				autoPlay={false}
				on
				onVolumeChange={e => {setDragged(true)}}
			/>
		</div>
	)
}
