import React, {useCallback, useEffect, useRef, useState, useLayoutEffect} from 'react'
import {useSpring, config} from '@react-spring/core'
import {useGesture} from 'react-use-gesture'
import clamp from 'lodash/clamp'
import {useStore} from './store'
import {isFirefox} from "react-device-detect";

export default function useYScroll(bounds, props) {
//	const useYScroll = React.memo((bounds, props) => {


		const setScrolling = useStore(state => state.setScrolling);
	const setScrollingJump = useStore(state => state.setScrollingJump);
	const scrollingJump = useStore(state => state.scrollingJump);

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isSafari = useStore(state => state.isSafari);

	// get hoveredPopup value from store without re-rendering scene
	// Fetch initial state
	const onNoScrollArea = useRef(useStore.getState().hoveredPopup);
	const onGallery = useRef(useStore.getState().transition);
	const showTour = useRef(useStore.getState().showTour);

	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	useLayoutEffect(() => useStore.subscribe(
		hoveredPopup => (onNoScrollArea.current = hoveredPopup),
		state => state.hoveredPopup
	), [])

	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	useEffect(() => useStore.subscribe(
		transition => (onGallery.current = transition),
		state => state.transition
	), [])

	useEffect(() => {
		if (useStore.getState().updateScrollPosition !== null) {
//			console.log("updateScrollPosition:",useStore.getState().updateScrollPosition);
			let newY = clamp(useStore.getState().updateScrollPosition, ...bounds);
//			console.log("updateScrollPositionclamped:",newY);
			set({y: newY});
			// set scrollingFlag to show, hide, update refline
			setScrollingJump(true);
			setTimeout(() => {setScrollingJump(false)}, 2000)  // also avoids that automatic scrolling is interrupted by manual scrolling
			useStore.getState().setUpdateScrollPosition(null);
		}
	})

	const [{y}, set] = useSpring(() => ({y: 0, config: config.slow}))

	const fn = useCallback(
		({xy: [, cy], previous: [, py], memo = y.get(), active: a, velocity: v}) => {

			// set scroll-spy
//			console.log("scollspy: ",y, v, a, cy, py);
//			if (cy !== py) {
//				setScrolling(true)
//			}
			if (
				!useStore.getState().museumSelectOpen
				&& !useStore.getState().hoveredPopup
				&& useStore.getState().scrolling !== a
				&& !isMo
				&& v < 0.0001
			)
			{
//				console.log("transition: ",onGallery);
				let delay = a === true ? 10 : 2000;
				setTimeout(() => setScrolling(a), delay)
			}

			let newY = clamp(memo + cy - py, ...bounds)

			// bounce back on -5
			if ((memo) === -5) {
				newY = 0 + (memo + (py - cy)) / 2;
//				console.log("rebound: ", memo, (cy-py), newY, d);
			}

			// when mouse over popup then do not scroll
			if (onNoScrollArea.current) {
				newY = memo;
			}

			// when mouse over gallery then do not scroll
			if (onGallery.current) {
				newY = memo;
			}

			//  when mobile then do not scroll
			if (isMo) {
				newY = memo;
			}

			// when in tour then do not scroll
			if (useStore.getState().showTour) {
				newY = memo;
			}

			// avoids that automatic scrolling is interrupted by manual scrolling
			if (scrollingJump) {
				return null
			}

			set({y: newY})
			return newY
		},
		[bounds, y, set]
	)

	const fn2 = useCallback(
		({down, movement: [mx, my], memo = y.get(), active: a, velocity: v}) => {

//			console.log("DRAGspy: ", mx, my, down, a, v);
//			console.log("DRAGspy: ",memo);
//			console.log("DRAGspy1: ", onNoScrollArea, useStore.getState().hoveredPopup,
//				useStore.getState().dragged
//			);
//			console.log("DRAGspy2: ", onGallery, useStore.getState().hoveredPopup,
//				useStore.getState().dragged
//			);

			if (!onGallery.current && isMo && useStore.getState().scrolling !== a && v < 0.0001) {
//				console.log("transition: ",onGallery);
				let delay = a === true ? 10 : 2000;
				setTimeout(() => setScrolling(a), delay)
			}

			if (!onGallery.current && isMo && useStore.getState().dragging !== a && v < 1.5) {
//				console.log("transition: ",onGallery);
				let delay = a === true ? 10 : 2000;
				setTimeout(() => setScrolling(a), delay)
			}

			let accelerator = isSafari || isFirefox ? 0.5 : 0.5;

			let newY = clamp(memo + (-my * v * accelerator), ...bounds)

			// avoid y-scrolling on tour
			if (useStore.getState().showTour) newY = memo;

			// avoid y-scrolling desktop
			if (!isMo) newY = memo;

			// when mouse over popup then do not scroll
			if (onNoScrollArea.current) {
				newY = memo;
			}

			// when mouse over popup then do not scroll
			if (useStore.getState().dragged) {
				newY = memo;
			}


			// when mouse over gallery then do not scroll
			if (onGallery.current) {
				newY = memo;
			}

			set({y: newY})
			return newY
		},
		[bounds, y, set]
	)

	const bind = useGesture({
			onWheel: fn,
			onDrag: fn2
		},
		{
			domTarget: window,
			drag: {
				enabled: true,
//				filterTaps: true,
//				useTouch: false,
//				preventDefault: true,
			}
		},
		)

	return [y, bind]
}
//)

//export default useYScroll;
