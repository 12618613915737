import "../css/help.css";
import React, {Suspense, useEffect, useLayoutEffect, useState, useRef} from "react";
import {useStore} from "./store";
import {useWindowDimensions} from "../utils/hooks";
import {TrackerHelp} from "./tracker";
import {mtrack} from "../utils/helpers";
import Museum from "./museum";
import ItemDescription from "./description";

//export function InfoPanel(props) {
export const InfoPanel = React.memo((props) => {

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const item = props.val
	const [overDescription, setOverDescription] = useState(false);
	const [overMuseum, setOverMuseum] = useState(false);
	const [overSharedLink, setOverSharedLink] = useState(false);
	const [overTimeline, setOverTimeline] = useState(false);

	useEffect(() => {
		setOverDescription(false)
		setOverMuseum(false)
		setOverSharedLink(false)
		setOverTimeline(false);
	}, [item]);

	return (
		<div className="lightboxInfopanel">
			<div
				className={'panelhover'}
				style={{
					position: 'absolute',
					left: '4vw',
//					backgroundColor: 'red',
					width: isMo1 && !isLandscape ? '90vw' : '100vw',
					height: '90vh',
					opacity: 0,
					pointerEvents: overDescription || overMuseum || overSharedLink || overTimeline ? 'auto' : 'none',
					zIndex: 200,
				}}
				onClick={() => {
//					console.log("++++ mouse clicked")
				}}
				onMouseOver={() => {
//					console.log("++++ mouse left")
					if (!isMo1) {
						setOverDescription(false);
						setOverMuseum(false);
						setOverSharedLink(false);
						setOverTimeline(false);
					}
				}}
				onMouseOut={() => {
//					console.log("++++ mouse over")
				}}
			/>

			{overDescription && <ShowDescription val={item}></ShowDescription>}
			{overMuseum && <ShowMuseum val={item}></ShowMuseum>}
			{overSharedLink && <SharedLink val={item}></SharedLink>}
			{overTimeline && <ShowTimeline val={item}></ShowTimeline>}

			<div
				onMouseEnter={() => {
					if (!isMo1) {
						setOverDescription(true);
						setOverSharedLink(false);
						setOverMuseum(false);
						setOverTimeline(false);
					}
				}}
				onClick={() => {
					if (overDescription) {
						setOverDescription(false)
						setOverSharedLink(false);
						setOverMuseum(false)
						setOverTimeline(false);
					} else {
						setOverDescription(true)
						setOverSharedLink(false);
						setOverTimeline(false);
						setOverMuseum(false)
					}
//								setOverDescription(overDescription ? false : true)
//								setOverMuseum(false)
				}}
			>
				<svg width="30px" height="30px" viewBox="0 0 162.136 187.864">
					<line fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
					      strokeMiterlimit="10" x1="33.922"
					      y1="100.83" x2="132.922" y2="100.83"/>
					<line fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
					      strokeMiterlimit="10" x1="33.922"
					      y1="121.83" x2="132.922" y2="121.83"/>
					<line fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
					      strokeMiterlimit="10" x1="52.922"
					      y1="141.83" x2="113.922" y2="141.83"/>
					<path fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
					      strokeMiterlimit="10" d="M25.641,12.83c-4.843,0-10.219,2.634-10.219,8.06
							v143.498c0,5.427,5.376,10.442,10.219,10.442h114.937c4.843,0,7.845-5.016,7.845-10.442V46.12l-33.916-33.29H25.641z"/>
					<path fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
					      strokeLinejoin="bevel"
					      strokeMiterlimit="10" d="M123.435,48.027
							l25.987,0.093l-34.916-34.544L114.422, 38.9C114.422,44.326,118.592,48.027,123.435,48.027z"/>
					<path fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
					      strokeMiterlimit="10"
					      d="M87.485-3.857"/>
				</svg>
			</div>
			<div
				onMouseEnter={() => {
					if (!isMo) {
						setOverMuseum(true);
						setOverDescription(false)
						setOverTimeline(false);
						setOverSharedLink(false);
					}
				}}
				onClick={() => {
					if (overMuseum) {
						setOverDescription(false)
						setOverMuseum(false)
						setOverTimeline(false);
						setOverSharedLink(false);
					} else {
						setOverDescription(false)
						setOverTimeline(false);
						setOverMuseum(true)
						setOverSharedLink(false);
					}
				}}
				style={{paddingTop: '5px'}}
			>
				<svg width="30px" height="30px" viewBox="0 0 196.117 195.631">
					<polygon fill={overMuseum ? 'white' : 'grey'}
					         points="97.386,8.166 12.386,54.583 12.386,54.583 182.14,54.583 "/>
					<rect x="12.263" y="170.583" fill={overMuseum ? 'white' : 'grey'} width="170"
					      height="14.399"/>
					<polygon fill={overMuseum ? 'white' : 'grey'} points="97.051,127.981 44.806,83.707 44.806,149.097 83.485,149.097 83.485,161.13 25.763,161.13
									25.763,65.315 53.849,65.315 97.051,101.643 142.136,65.315 167.763,65.315 167.763,161.13 111.745,161.13 111.745,149.097
									148.916,149.097 148.916,83.479 "/>
				</svg>

			</div>

			<div
				onMouseEnter={() => {
					if (!isMo) {
						setOverSharedLink(true);
						setOverTimeline(false);
						setOverMuseum(false);
						setOverDescription(false)
					}
				}}
				onClick={() => {
					if (overSharedLink) {
						setOverSharedLink(false);
						setOverTimeline(false);
						setOverDescription(false)
						setOverMuseum(false)
					} else {
						setOverSharedLink(true);
						setOverDescription(false)
						setOverTimeline(false);
						setOverMuseum(false)
					}
				}}
				style={{padding: '5px'}}
			>
				<div>
					<svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="10 0 550 483">
						<g color={'white'} fill={overSharedLink ? 'white' : 'grey'}>
							<path d="M395.72,0c-48.204,0-87.281,39.078-87.281,87.281c0,2.036,0.164,4.03,0.309,6.029l-161.233,75.674
							c-15.668-14.971-36.852-24.215-60.231-24.215c-48.204,0.001-87.282,39.079-87.282,87.282c0,48.204,39.078,87.281,87.281,87.281
							c15.206,0,29.501-3.907,41.948-10.741l69.789,58.806c-3.056,8.896-4.789,18.396-4.789,28.322c0,48.204,39.078,87.281,87.281,87.281
							c48.205,0,87.281-39.078,87.281-87.281s-39.077-87.281-87.281-87.281c-15.205,0-29.5,3.908-41.949,10.74l-69.788-58.805
							c3.057-8.891,4.789-18.396,4.789-28.322c0-2.035-0.164-4.024-0.308-6.029l161.232-75.674c15.668,14.971,36.852,24.215,60.23,24.215
							c48.203,0,87.281-39.078,87.281-87.281C482.999,39.079,443.923,0,395.72,0z"/>
						</g>
					</svg>
				</div>
			</div>

			<div
				onMouseEnter={() => {
					if (!isMo) {
						setOverTimeline(true);
						setOverSharedLink(false);
						setOverMuseum(false);
						setOverDescription(false)
					}
				}}
				onClick={() => {
					if (overTimeline) {
						setOverSharedLink(false);
						setOverTimeline(false);
						setOverDescription(false)
						setOverMuseum(false)
					} else {
						setOverTimeline(true);
						setOverSharedLink(false);
						setOverDescription(false)
						setOverMuseum(false)
					}
				}}
				style={{paddingLeft: '2px'}}
			>
				<div>
					<svg version="1.1" id="Layer_1" x="0px" y="0px"
					     width="25px" height="25px" viewBox="0 0 200 200">
						<polygon fill="none" stroke={overTimeline ? 'white' : 'grey'} strokeWidth="18"
						         strokeLinecap="round"
						         strokeLinejoin="round" strokeMiterlimit="10" points="
								123.334,17.5 66.667,17.5 20.942,180.5 20.942,180.5 181,180.5 181,180.5 "/>
						<rect x="74.829" y="122.851" transform="matrix(0.771 -0.6368 0.6368 0.771 -66.9838 88.2754)"
						      fill="none" stroke={overTimeline ? 'white' : 'grey'} strokeWidth="13"
						      strokeLinecap="round"
						      strokeLinejoin="round" strokeMiterlimit="10" width="28.861" height="28.861"/>
						<rect x="106.5" y="79.5" transform="matrix(0.7975 -0.6033 0.6033 0.7975 -28.602 84.787)"
						      fill="none" stroke={overTimeline ? 'white' : 'grey'} strokeWidth="8" strokeLinecap="round"
						      strokeLinejoin="round" strokeMiterlimit="10" width="11" height="10.999"/>

					</svg>
				</div>

			</div>


		</div>
	)
})

//const SharedLink = (d) => {
const SharedLink = React.memo((d) => {


		const isMo = useStore(state => state.isMobile);
		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);
		const isLandscape = useStore(state => state.isLandscape);

		const itemsList = useStore(state => state.items);

		const item = d.val;

		const url = window.location.protocol + '//' + window.location.hostname + '/?inv=';
		//	let url = process.env.REACT_APP_MAIN_PATH+'/?inv='+itemsList[lastSelected].id_inventar;

		return (
			<>
				<div className='infocontainer'
				     style={{width: isMo1 && !isLandscape ? '80vw' : '500px'}}
				>
					<h3>Teilen</h3>
					Bitte klicken Sie auf den Permalink um diesen in die Zwischenablage zu kopieren:
					<br/>
					{item &&
						<div className={'link tooltip'}
							//					style={{float: 'right'}}
							 onMouseOver={() => {
								 let tooltip = document.getElementById("myTooltip");
								 tooltip.innerHTML = "Link in Zwischenablage kopieren"
							 }
							 }
							 onClick={() => {
								 navigator.clipboard.writeText(url + item.id_inventar);
								 let tooltip = document.getElementById("myTooltip");
								 tooltip.innerHTML = "Kopiert: " + url + item.id_inventar;
								 return true
							 }
							 }
						>
							<div className={'tooltiptext'}
							     id="myTooltip"
							     style={{
								     backgroundColor: 'grey',
								     top: 60,
								     left: '10px',
								     width: '100%',
								     height: '2em'
							     }}
							>
								Link in Zwischenablage kopieren
							</div>
							<div style={{
								paddingTop: '3vh',
								paddingBottom: '5vh',
								width: '100%',
							}}>
								Permalink: {url + item.id_inventar}
							</div>
						</div>
					}
				</div>
			</>
		)

	}
)


//function ShowDescription(props) {
export const ShowDescription = React.memo((props) => {

	const items = useStore(state => state.items)

	const item = props.val;

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	return (
		<>
			<div
				className='infocontainer'
				style={{width: isMo1 && !isLandscape ? '80vw' : '500px'}}
			>
				<h3>Beschreibung</h3>
				<h4>{item.title}</h4>
				<p>Datierung: {item.objectdate}</p>
				<ItemDescription
					val={item.description}
					new={0}
				>
				</ItemDescription>
			</div>
		</>
	)
}
)

//function ShowMuseum(props) {
const ShowMuseum = React.memo((props) => {

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const museums = useStore(state => state.museums)
	const item = props.val;
	const info = museums.find((d) => d.idMandant === item.museum);

	return (
		<>
			<div className='infocontainer'
			     style={{width: isMo1 && !isLandscape ? '80vw' : '500px'}}
			>
				<h3>Ausstellungsort</h3>
				{item &&
					<Museum
						val={info.id} museumdata={info}
					>
					</Museum>}
			</div>
		</>
	)
}
)

//function ShowTimeline(props) {
export const ShowTimeline = React.memo((props) => {


	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const setRemote = useStore(state => state.setRemote); // select remote
	const setRemotePending = useStore(state => state.setRemotePending); // select remote
	const setShowMediaOverlay = useStore(state => state.setShowMediaOverlay);
	const setShow3D = useStore(state => state.setShow3D);

	const museums = useStore(state => state.museums)
	const item = props.val;
	const info = museums.find((d) => d.idMandant === item.museum);

	const handleButton = (e) => {
//		console.log("jump to timeline")
		setShowMediaOverlay(false);
		setShow3D(false);
		setRemote(item.id_inventar);
		setRemotePending(true);
	}

	return (
		<>
			<div className='infocontainer'
			     style={{width: isMo1 && !isLandscape ? '80vw' : '500px'}}
			>
				<h3>Zeitleiste</h3>
				Schauen Sie sich das Objekt in der Zeitleiste an!
				<br/>
				<button onClick={handleButton}>Los</button>
			</div>
		</>
	)
})

export default InfoPanel;
