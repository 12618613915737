import React, {useRef, useState, useMemo, useEffect, useLayoutEffect, useCallback} from "react";
import {useStore} from './store';
//import 'bootstrap/dist/css/bootstrap.css';
import _ from 'lodash';
import {useWindowDimensions} from "../utils/hooks";
import {useSpring, animated, a} from '@react-spring/web';
import {hexToRgbA} from "../utils/helpers";
import Select from 'react-select';
import "../css/search.css";
import { useMatomo } from '@datapunt/matomo-tracker-react'


export default function Onboarding(props) {

	const { trackPageView, trackEvent } = useMatomo();

	const {height, width} = useWindowDimensions();
	const leftoffset = (props.pos - 15) / width * 100;
	const heightoffset = props.heightOffset;

	const [isOpen, setIsOpen] = useState(false);
	const firstModalCompleted = useStore(state => state.firstModalCompleted);

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const clickedHelp = useStore(state => state.clickedHelp);

	const setIsOnboarding = useStore(state => state.setIsOnboarding);
	const setShowInfo = useStore(state => state.setShowInfo);
	const setShowMediaOverlay = useStore(state => state.setShowMediaOverlay);

	const [show, setShow] = useState(true);
	const [closed, setClosed] = useState(false)

	const delay = firstModalCompleted ? isMo ? 1500 : 4000 : 99999999999;

	useEffect(() => {
		setTimeout(() => {
//			window.scrollTo(0, 100);
		}, delay - 1000)

	}, []);

	useEffect(() => {
		if (clickedHelp) {
			setShow(false)
			setClosed(true)
			setIsOnboarding(false)
		}
	}, [clickedHelp])

	const handleShowForm = event => {
		event.stopPropagation()
		setShow(!show)
		trackEvent({ category: 'onboarding closed', action: 'click-event' })
		if (event.currentTarget.id === 'helpText') {
			trackEvent({ category: 'help started via onboarding link', action: 'click-event' })
//			console.log('FIRED')
			setShowInfo(true);
			setShowMediaOverlay(true);
		}
		setTimeout(() => {
			setClosed(true)
			setIsOnboarding(false)
		}, 1000)
	}

//	useEffect(()=>{
//		setTimeout (()=>{
//			setIsOnboarding(true)
//		}, delay)
//	},[show])

	const {s} = useSpring({
		from: {s: 0},
		to: {s: show ? 1 : 0},
		delay: show ? delay : 10,
		onStart: () => { setIsOnboarding(true) }
	})

	return (
		<>
			{!closed &&
				<div>
					<a.div className="onboardingcontainer"
					       style={{
//					   height: mobileHeight ? mobileHeight : '100vh',
						       bottom: isMo1 ? isLandscape ? heightoffset : heightoffset : heightoffset,
						       width: isMo1 ? isLandscape ? '45vw' : '70vw' : '30vw',
						       left: isMo1 ? isLandscape ? '50vw' : '10vw' : '67vw',
						       fontSize: isMo1 ? isLandscape ? '1.0em' : '1.1em' : '1.2em',
						       padding: isMo ? '1.8vmax' : '1vmax',
						       opacity: s,
					       }}
					>
						<div
							className={'Xbuttonright'} style={{}}
							style={{
								fontSize: isMo1 ? isLandscape ? '1.7em' : '1.5em' : '2em',
								position: 'relative',
								top: '-0.5em',
							}}
							onClick={handleShowForm}
						>&times;
						</div>
						<div className={'onboardingText'}
						     style={{position: 'relative', top: '-0.8em'}}
						>
							<p>Willkommen im digitalen Schauraum der Salzburger Regionalmuseen.
							</p><p>Wir laden Sie ein zu einer Zeitreise durch mehr als 60 lokale Sammlungen.
						</p><p>Informationen zur Bedienung finden Sie jederzeit unter
							<span id='helpText' style={{cursor: 'pointer'}} onClick={handleShowForm}>&nbsp;&bdquo;<u>Hilfe</u>&ldquo;</span>!
						</p>

						</div>
					</a.div>
					<a.div className={'triangle-down'}
					       style={{
						       bottom: isMo1 ? isLandscape ? heightoffset-20 : heightoffset-20 : heightoffset-20,
						       left: isMo1 ? isLandscape ? leftoffset + 'vw' : leftoffset + 'vw' : leftoffset + 'vw',
						       borderTop: `20px solid #4A4B59DD`,
						       opacity: s,
					       }}
					>
					</a.div>
				</div>
			}
		</>
	)
}
