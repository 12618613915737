import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback} from 'react'
import "../css/lightboxoverlay.css";
import {useStore} from './store';
import {hexToRgbA} from "../utils/helpers";
import {useInterval, useWindowDimensions} from "../utils/hooks";
import ItemDescription from "./description";
import Museum from "./museum";
import Lightbox from 'react-image-lightbox';

//export default function LightboxOverlay() {
	export const LightboxOverlay = React.memo(() => {


		const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);
	const isSafari = useStore(state => state.isSafari);
	const isMoIOS = useStore(state => state.isMoIOS);

	const itemsList = useStore(state => state.items);
	const museumsList = useStore(state => state.museums);

	const [overDescription, setOverDescription] = useState(false);
	const [overMuseum, setOverMuseum] = useState(false);
	const [overSharedLink, setOverSharedLink] = useState(false);

	const showLightbox = useStore(state => state.showLightbox);
	const setShowLightbox = useStore(state => state.setShowLightbox);

	const imageIndex = useStore(state => state.imageIndex); // lightbox
	const imageIndexPos = useStore(state => state.imageIndexPos); // lightbox

	const [imageList, setImageList] = useState();
	const [currPos, setCurrPos] = useState();

	const isFullScreen = useStore(state => state.isFullScreen);

	useEffect(() => {
//		console.log('pos', imageIndexPos)
		let firstpath = process.env.REACT_APP_PUBLIC_PATH + '/api/assets/cache/orig/allfull/' + imageIndex.filename1;
		if (imageIndex.filename2) {
			let secondpath = process.env.REACT_APP_PUBLIC_PATH + '/api/assets/cache/orig/allfull/' + imageIndex.filename2;
			setImageList([firstpath, secondpath])
		} else {
			setImageList([firstpath])
		}
		setCurrPos(imageIndexPos ? imageIndexPos : 0)
	}, [imageIndex])

	function getImageInfo(currentImage) {
		const image = itemsList.find((a) => parseInt(a.id) == parseInt(currentImage.id) ? a : '')
		const museum = museumsList.find((a) => parseInt(a.idMandant) === parseInt(image.museum) ? a : '')
		const info = {museum, image};
		return info
	}


	function getTitle(imageIndex) {
		/*
		<div className='imgtitle'>{element.caption}.&nbsp; <a href={url} className="fullview" target='_blank' rel="noopener noreferrer" style={fontstyle} >Klicken Sie hier für Details (in neuem Tab)
			&nbsp;&nbsp;<i><FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></i></a></div>;
		*/
		let title = imageIndex.title.replace(/\s+$/, '');
		let museum = getImageInfo(imageIndex).museum.bezeichnung;
		let outstring = <div> {title} - <i>{museum}</i></div>;
		return outstring
	}


	function ShowDescription() {
		const info = getImageInfo(imageIndex);

		const isMo = useStore(state => state.isMobile);
		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);
		const isLandscape = useStore(state => state.isLandscape);

		return (
			<>
				<div
					className='infocontainer'
					style={{width: isMo1 && !isLandscape ? '80vw' : '500px'}}
				>
					<h3>Beschreibung</h3>
					<h4>{info.image.title}</h4>
					<p>Datierung: {info.image.objectdate}</p>
					<ItemDescription
						val={info.image.description}
						new={imageIndex}
					>
					</ItemDescription>
				</div>
			</>
		)
	}

	function ShowMuseum() {
		const info = getImageInfo(imageIndex);

		const isMo = useStore(state => state.isMobile);
		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);
		const isLandscape = useStore(state => state.isLandscape);

		return (
			<>
				<div className='infocontainer'
				     style={{width: isMo1 && !isLandscape ? '80vw' : '500px'}}
				>
					<h3>Ausstellungsort</h3>
					{imageIndex &&
						<Museum
							val={info.museum.id} museumdata={info.museum}
						>
						</Museum>}
				</div>
			</>
		)
	}

//	console.log('isMoIOS', isMoIOS);
//	console.log('isSafari', isSafari);

	return (
		<div>
			{showLightbox && (
				<div>
					<Lightbox
						mainSrc={imageList[currPos]}
						imageTitle={getTitle(imageIndex)}
						imagePadding={50}
						imageLoadErrorMessage={''}
						nextSrc={currPos === 0 && imageList.length > 1 ? imageList[(currPos + imageList.length + 1) % imageList.length] : null}
						prevSrc={currPos === 1 && imageList.length > 1 ? imageList[(currPos + imageList.length - 1) % imageList.length] : null}
						//	prevSrc={imageheader + images[(imageIndex + images.length - 1) % images.length] + '/full'}
						onCloseRequest={() => setShowLightbox(false)}
						onMovePrevRequest={() =>
							setCurrPos((currPos + imageList.length + 1) % imageList.length)
						}
						onMoveNextRequest={() =>
							setCurrPos((currPos + imageList.length - 1) % imageList.length)
						}
						reactModalProps={
							isMoIOS ? {} :
								{ parentSelector: () => isFullScreen || !isMo ?
										document.querySelector('.rootcontainer')
										:
										document.querySelector('.fullscreenwrapper')
								}
						}
						reactModalStyle={{
							overlay: {
//							position: 'absolute',
//							right: '20px',
//							top: '20px',
//							width: '50%',
//							height: '50%',
							zIndex: 10010,
							opacity: 1,
							}
						}} // set very high to overlay header
					/>
					{(overMuseum || overDescription || overSharedLink) &&
						<div>
							{/* next div responds to mouse over, turns off sidepanel */}
							<div
								className='lightboxInfoBackground'
								onMouseEnter={() => {
									setOverDescription(false);
									setOverMuseum(false);
									setOverSharedLink(false);
								}}
							>
							</div>
							{/* next div does not responds to mouse enter, adds area around panel which is safe */}
							<div className='lightboxInfoSafe'>
							</div>
						</div>
					}
					{/* sidepanel */}
					<div className="lightboxInfopanel">
						{overDescription && <ShowDescription></ShowDescription>}
						{overMuseum && <ShowMuseum></ShowMuseum>}
						{overSharedLink && <SharedLink val={{imageIndex}}></SharedLink>}


						<div
							onMouseEnter={() => {
								if (!isMo1) {
									setOverDescription(true);
									setOverMuseum(false);
									setOverSharedLink(false);
								}
							}}
							onClick={() => {

								if (overDescription) {
									setOverDescription(false)
									setOverMuseum(false)
									setOverSharedLink(false);
								} else {
									setOverDescription(true)
									setOverMuseum(false)
									setOverSharedLink(false);
								}
							}}
						>
							<svg width="30px" height="30px" viewBox="0 0 162.136 187.864">
								<line fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
								      strokeMiterlimit="10" x1="33.922"
								      y1="100.83" x2="132.922" y2="100.83"/>
								<line fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
								      strokeMiterlimit="10" x1="33.922"
								      y1="121.83" x2="132.922" y2="121.83"/>
								<line fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
								      strokeMiterlimit="10" x1="52.922"
								      y1="141.83" x2="113.922" y2="141.83"/>
								<path fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
								      strokeMiterlimit="10" d="M25.641,12.83c-4.843,0-10.219,2.634-10.219,8.06
	v143.498c0,5.427,5.376,10.442,10.219,10.442h114.937c4.843,0,7.845-5.016,7.845-10.442V46.12l-33.916-33.29H25.641z"/>
								<path fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
								      strokeLinejoin="bevel"
								      strokeMiterlimit="10" d="M123.435,48.027
	l25.987,0.093l-34.916-34.544L114.422, 38.9C114.422,44.326,118.592,48.027,123.435,48.027z"/>
								<path fill="none" stroke={overDescription ? 'white' : 'grey'} strokeWidth="11"
								      strokeMiterlimit="10"
								      d="M87.485-3.857"/>
							</svg>
						</div>


						<div
							onMouseEnter={() => {
								if (!isMo) {
									setOverMuseum(true);
									setOverDescription(false)
									setOverSharedLink(false);
								}
							}}
							onClick={() => {
								if (overMuseum) {
									setOverDescription(false)
									setOverMuseum(false)
									setOverSharedLink(false);
								} else {
									setOverMuseum(true)
									setOverDescription(false)
									setOverSharedLink(false);
								}
							}}
							style={{padding: '1px'}}
						>
							<svg width="30px" height="30px" viewBox="0 0 196.117 195.631">
								<polygon fill={overMuseum ? 'white' : 'grey'}
								         points="97.386,8.166 12.386,54.583 12.386,54.583 182.14,54.583 "/>
								<rect x="12.263" y="170.583" fill={overMuseum ? 'white' : 'grey'} width="170"
								      height="14.399"/>
								<polygon fill={overMuseum ? 'white' : 'grey'} points="97.051,127.981 44.806,83.707 44.806,149.097 83.485,149.097 83.485,161.13 25.763,161.13
									25.763,65.315 53.849,65.315 97.051,101.643 142.136,65.315 167.763,65.315 167.763,161.13 111.745,161.13 111.745,149.097
									148.916,149.097 148.916,83.479 "/>
							</svg>
							</div>

							<div
								onMouseEnter={() => {
									if (!isMo) {
										setOverSharedLink(true);
										setOverMuseum(false);
										setOverDescription(false)
									}
								}}
								onClick={() => {
									if (overSharedLink) {
										setOverSharedLink(false);
										setOverDescription(false)
										setOverMuseum(false)
									} else {
										setOverSharedLink(true);
										setOverDescription(false)
										setOverMuseum(false)
									}
								}}
								style={{padding: '5px'}}
							>
								<div>
									<svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="10 0 550 483">
										<g color={'white'} fill={overSharedLink ? 'white' : 'grey'}>
											<path d="M395.72,0c-48.204,0-87.281,39.078-87.281,87.281c0,2.036,0.164,4.03,0.309,6.029l-161.233,75.674
							c-15.668-14.971-36.852-24.215-60.231-24.215c-48.204,0.001-87.282,39.079-87.282,87.282c0,48.204,39.078,87.281,87.281,87.281
							c15.206,0,29.501-3.907,41.948-10.741l69.789,58.806c-3.056,8.896-4.789,18.396-4.789,28.322c0,48.204,39.078,87.281,87.281,87.281
							c48.205,0,87.281-39.078,87.281-87.281s-39.077-87.281-87.281-87.281c-15.205,0-29.5,3.908-41.949,10.74l-69.788-58.805
							c3.057-8.891,4.789-18.396,4.789-28.322c0-2.035-0.164-4.024-0.308-6.029l161.232-75.674c15.668,14.971,36.852,24.215,60.23,24.215
							c48.203,0,87.281-39.078,87.281-87.281C482.999,39.079,443.923,0,395.72,0z"/>
										</g>
									</svg>
								</div>
						</div>
					</div>
					}
				</div>
			)
			}
		</div>
	)
})

//const SharedLink = (d) => {
	const SharedLink = React.memo((d) => {


		const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const itemsList = useStore(state => state.items);

	const itemindex = d.val.imageIndex;

	var item = itemsList.find((d) => itemindex.id == d.id);
//	console.log("*********** idinventar",item.id_inventar);

	const url = window.location.protocol + '//' + window.location.hostname + '/?inv=';
	//	let url = process.env.REACT_APP_MAIN_PATH+'/?inv='+itemsList[lastSelected].id_inventar;

	return (
		<>
			<div className='infocontainer'
			     style={{width: isMo1 && !isLandscape ? '80vw' : '500px'}}
			>
				<h3>Teilen</h3>
				Bitte klicken Sie auf den Permalink um diesen in die Zwischenablage zu kopieren:
				<br/>
				{item &&
					<div className={'link tooltip'}
						//					style={{float: 'right'}}
						 onMouseOver={() => {
							 let tooltip = document.getElementById("myTooltip");
							 tooltip.innerHTML = "Link in Zwischenablage kopieren"
						 }
						 }
						 onClick={() => {
							 navigator.clipboard.writeText(url + item.id_inventar);
							 let tooltip = document.getElementById("myTooltip");
							 tooltip.innerHTML = "Kopiert: " + url + item.id_inventar;
							 return true
						 }
						 }
					>
						<div className={'tooltiptext'}
						     id="myTooltip"
						     style={{
							     backgroundColor: 'grey',
							     top: 60,
							     left: '10px',
							     width: '100%',
							     height: '2em'
						     }}
						>
							Link in Zwischenablage kopieren
						</div>
						<div style={{
							paddingTop: '3vh',
							paddingBottom: '5vh',
							width: '100%',
						}}>
							Permalink: {url + item.id_inventar}
						</div>
					</div>
				}
			</div>
		</>
	)

})

export default LightboxOverlay;
