import React, {useState, useEffect, useRef, useMemo} from 'react';

function getWindowDimensions() {
	const {innerWidth: width, innerHeight: height} = window;
	return {
		width,
		height
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

function getScreenDimensions() {
	const {width: screenWidth, height: screenHeight} = window.screen;
	return {
		screenWidth,
		screenHeight
	};
}


export function useScreenDimensions() {
	const [screenDimensions, setScreenDimensions] = useState(getScreenDimensions());

	useEffect(() => {
		function handleResize1() {
			setScreenDimensions(getScreenDimensions());
		}

		window.addEventListener('resizescreen', handleResize1);
		return () => window.removeEventListener('resizescreen', handleResize1);
	}, []);

	return screenDimensions;
}

function getScreenOrientation() {
	const {orientation: orientation} = window;
	return {
		orientation,
	};
}


export function useScreenOrientation() {
	const [screenOrientation, setScreenOrientation] = useState(getScreenOrientation());

	useEffect(() => {
		function handleOrientation() {
			setScreenOrientation(getScreenOrientation());
		}

		window.addEventListener('screenorientation', handleOrientation);
		return () => window.removeEventListener('screenorientation', handleOrientation);
	}, []);

	return screenOrientation;
}



export function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}


// useTimeout currently unused
export function useTimeout(callback, delay) {
	const timeoutRef = React.useRef();
	const callbackRef = React.useRef(callback);

	// Remember the latest callback:
	//
	// Without this, if you change the callback, when setTimeout kicks in, it
	// will still call your old callback.
	//
	// If you add `callback` to useEffect's deps, it will work fine but the
	// timeout will be reset.

	React.useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	// Set up the timeout:

	React.useEffect(() => {
		if (typeof delay === 'number') {
			timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);

			// Clear timeout if the components is unmounted or the delay changes:
			return () => window.clearTimeout(timeoutRef.current);
		}
	}, [delay]);

	// In case you want to manually clear the timeout from the consuming component...:
	return timeoutRef;
}

/*
export function useOnScreen(ref, rootMargin = "0px") {

	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState([]);

	const observerRef = useRef(null);

	useEffect(()=>{
		observerRef.current =
			new IntersectionObserver(
				(entries) => {
					console.log("entry=", entries)
					var arr=[];
					entries.map((d, i) => {
						console.log("res: ", i, d.isIntersecting)
						arr.push(d.isIntersecting);
					})
					// Update our state when observer callback fires
					setIntersecting(arr)
		//				changeArr(entries[0].isIntersecting ? true : false,0);
				},
				{
					rootMargin,
				})


	}, [])

	useEffect(() => {

		if (ref) {
			ref.map((d, idx) => {
				console.log("start observer with", idx);
				observerRef.current.observe(ref[idx]);
			})

		}

		return () => {
			console.log("start cleaned");
				ref.map((d, idx) => {
					console.log("XXXXXXXXX start observer with", idx);
					if (ref[idx]) {
						observerRef.current.unobserve(ref[idx]);
					}
				})
//			observer.unobserve(ref.current[idx]);
		};

	}, []); // Empty array ensures that effect is only run on mount and unmount
	return isIntersecting;
}
*/
