import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback} from 'react'
import "../css/popup.css";
import extLink from '../assets/extlink.png';
//import {ReactComponent as ExtLink} from '../assets/extlink.svg';
import {useStore} from './store';
import {Html, Line} from "@react-three/drei";
import {getItemsImages, hexToRgbA, mtrack} from "../utils/helpers";
import {useInterval, useWindowDimensions} from "../utils/hooks";
import {useFrame} from '@react-three/fiber';
import * as THREE from "three";
import ItemDescription from "./description";
import {ItemDescription2} from "./description";
import Lightbox from 'react-image-lightbox';
import '../css/lightbox.css';
import {useMatomo} from "@datapunt/matomo-tracker-react"; // This only needs to be imported once in your app
//import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
//import throttle from 'lodash-es/throttle'

//import { useMatomo } from '@datapunt/matomo-tracker-react';

export const Popup = React.memo(() => {

//		console.log("------------- popup started ----------");

//	const {pushInstruction} = useMatomo();

		const {height, width} = useWindowDimensions();

		const isMo = useStore(state => state.isMobile);
		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);
		const isLandscape = useStore(state => state.isLandscape);
		const isSafari = useStore(state => state.isSafari);

		const itemsList = useStore(state => state.items);

		const overlay = useStore(state => state.overlay);
		const overlayBackgroundColor = useStore(state => state.overlayBackgroundColor);
		const vec = new THREE.Vector3();
		const targetVec = new THREE.Vector3();

		// next 2 lines create force_update-like hook
		const [, updateState] = useState();
		const forceUpdate = useCallback(() => updateState({}), []);

		const [hovered, setHovered] = useState(false); // mouse over popup
		const [opacityRefLine, setOpacityRefLine] = useState(0); // refline
		const setHoveredPopup = useStore((state) => state.setHoveredPopup);

		const setDragged = useStore((state) => state.setDragged);

		// show or hide popup
		const [hidden, setHidden] = useState(true);
		const setShowPopup = useStore(state => state.setShowPopup);
		const showTour = useStore(state => state.showTour);
		const [stayOnScreen, setStayOnScreen] = useState(false);


//	const [shouldTruncate, setShouldTruncate] = useState(true);

		const scr = useStore(state => state.scrolling);  // for normal scrolling with wheel
		const scrJump = useStore(state => state.scrollingJump); // for jumps (story/forward- & back-button)

		const closePopupState = useStore(state => state.closePopup);
		const setClosePopupState = useStore(state => state.setClosePopup);

		const [count, setCount] = useState(0);

		const selected = useStore(state => state.selected); // mouse over item
		const [lastSelected, setLastSelected] = useState(-1); // last selected item, changes only when a valid new item is selected
		const hoveredCoords = useStore(state => state.hoveredCoords); // coords of item

		const setCameraAnimationStopped = useStore(useCallback(state => state.setCameraAnimationStopped, [hidden])); // animation of camera
		const cameraAnimationStopped = useStore(useCallback(state => state.cameraAnimationStopped, [hidden])); // animation of camera

		const setShowReferenceLine = useStore(useCallback(state => state.setShowReferenceLine, [scr, scrJump, hovered]));
		const showReferenceLine = useStore(useCallback(state => state.showReferenceLine, [scr, scrJump, hovered])); // show referenceLine

		const toggleRefline = "reflineA";        // initial key for refline, ready to be toggle to force re-render

		const popupRef = useRef();
		const [initListener, setInitListener] = useState(false);          // init popup transition listener
		const [cssInTransition, setCssInTransitions] = useState(false);       // track popup transitions

		const popupTitleRef = useRef();
		const thirdContainerRef = useRef();
		const overlayRef = useRef();

		const museumsList = useStore(state => state.museums);
		const [showMuseum, setShowMuseum] = useState(false);

//	useEffect(()=>{
//		pushInstruction('setUserId', 'USER_ID_HERE');
//	}, [])

		useEffect(() => {
//		setShowPopup(!showTour ? true : false)
			if (showTour) {
				setShowPopup(false);
				setTimeout(() => closePopup(), 500)
			}
		}, [showTour])

		useEffect(() => {
			setShowPopup(hidden ? false : true)
		}, [hidden])


		// hold current selection, even if it is not hovered anymore
		useEffect(() => {
			if (selected > -1 && selected !== lastSelected) {
				setLastSelected(selected)
				RestartRefline();
				setShowMuseum(false);
//			mtrack('popup select: '+itemsList[selected].filename1);
			}
		}, [selected, hovered])


		// event listener tracking the css animation of the popup, sets variable cssInTransition which hides the reference line
		useEffect(() => {
			if (popupRef.current && !initListener) {
				popupRef.current.addEventListener('transitionstart', (e) => {
					e.stopPropagation();
					if (e.propertyName === 'transform') {
						setCssInTransitions(true);
//					console.log("start: ", e, e.propertyName, e.target)
					}
				})
				popupRef.current.addEventListener('transitionend', (e) => {
					e.stopPropagation();
					if (e.propertyName === 'transform') {
						setCssInTransitions(false);
//					console.log("end: ", e, e.propertyName, e.target)
					}
					setInitListener(true);
				})
			}
		}, [initListener, hidden])


		// close popup if empty space was clicked
		useLayoutEffect(() => {
			if (closePopupState === true) {
				closePopup();
				setClosePopupState(false);
			}
		}, [closePopupState])

		// reference line is hidden when camera slides or user scrolls
		useLayoutEffect(() => {
			if (overlay === "vertical") {
				cameraAnimationStopped === false || scr === true || scrJump === true ? HideRefLine() : ShowRefLine()
			} else {
				!scr && !scrJump && !hidden && !cssInTransition ? ShowRefLine() : HideRefLine()
			}
		})

		// move camera, popup slide in
		let t = 0;
		useFrame((state) => {
			if (overlay === "vertical" && !showTour) {
				t = t + 1;
				const step = 0.1
				targetVec.x = -Math.PI / 2 * (isMo1 ? isLandscape ? 1.9 : 1.2 : 1.2);
				targetVec.y = isMo1 ? isLandscape ? -31 : -35 : -30;
				targetVec.z = isMo1 ? isLandscape ? 5.5 : 7.5 : 5;
				let animate = hidden;
				state.camera.fov = THREE.MathUtils.lerp(state.camera.fov, animate ? 30 : 32, step)
				let rr = state.camera.position.lerp(vec.set(animate ? 0 : targetVec.x, animate ? -30 : targetVec.y, animate ? 5 : targetVec.z), step)
//			state.camera.lookAt(1+Math.PI/2, 2000, 20)
				state.camera.updateProjectionMatrix()
//			light.current.position.lerp(vec.set(zoom ? 4 : 0, zoom ? 3 : 8, zoom ? 3 : 5), step)
//			if (state.camera.position.distanceTo(targetVec) < 0.01) { console.log("lerp finished", rr);}

				if (t > 60) {
					t = 0;
					if (rr.x < targetVec.x + 0.1) {
						if (cameraAnimationStopped === false) {
//							console.log("camera animation stopped", rr.x);
							setCameraAnimationStopped(true)
						}
						t = 0;
					} else {
//					console.log("back to normal", rr.x);
						setCameraAnimationStopped(false)
						t = 0;
					}
				}
			}
		})

		// start timer when hovered
		useEffect(() => {
			//console.log("do not start counter:", hovered);
			setHoveredPopup(hovered);
//		if (!hovered) {
//			console.log("restart counter");
//			startTimer();
//		} else {
//			stopTimer()
//		}
		}, [hovered])

		useLayoutEffect(() => {
			if (selected !== -1 && !showTour) {
				mtrack('popup select ' + itemsList[selected].filename1);
			}
			if (!stayOnScreen && selected !== -1 && !showTour) {
				setStayOnScreen(true);
				setHidden(selected === -1 ? 1 : 0)
				setShowReferenceLine(selected === -1 ? 0 : 1)
//			stopTimer()
			}
			// add logic here if nothing is selected
			if (selected === -1 && !hovered) {
//			startTimer()
//			RestartRefline();
//		} else if (selected !== -1) {
//			stopTimer()
			}
		}, [selected])


		// close popup if counter > 3000
//	useEffect(() => {
//		if (count % 100 === 0) console.log("===> count: ", count);
//		if (count > 3000) {
//			closePopup();
//			stopTimer()
//		}
//
//	}, [count])

		// call custom hook: counter
//	useInterval(() => {
//		// Your custom logic here
//		if (count === -1) {
//			// stop timer, do nothing
//		} else {
//			setCount(count + 1);
//		}
//	}, 500000);

//	// forceupdate to restart truncate after animation finished
//	useEffect(() => {
//		console.log("forceupdate started");
//		setTimeout(() => hidden ? null : forceUpdate(), 300);
//	}, [hidden, shouldTruncate])


//	function startTimer() {
//		setCount(0)
//	}

//	function stopTimer() {
//		setCount(-1)
//	}

		function HideRefLine() {
			setShowReferenceLine(false);
		}

		function ShowRefLine() {
			setShowReferenceLine(true);
		}

		function RestartRefline() {
//		HideRefLine();
//			console.log("restart >>> Refline <<<")
			setTimeout(() => {HideRefLine()}, 50);
			setTimeout(() => {ShowRefLine()}, 60);
		}

		function GetItem(arg) {
			if (lastSelected !== -1 && itemsList) {
				let sel = itemsList[lastSelected].[arg.val];
				return (<>{sel}</>)
			} else return (<></>)
		}

		function ShowSelected() {
			if (selected !== -1 && itemsList) {
//				console.log(itemsList[1].id);
				let sel = itemsList[selected].title
//				console.log(sel);
				return (
					<div>
						<p>{sel}</p>
						<p>selected = {selected}</p>
					</div>
				)
			} else return (<p></p>)
		}

		function HoveredCoords() {
			return hoveredCoords
		}

		function ReferenceLine() {
//		console.log("refline called");

			if (popupTitleRef.current && overlayRef.current) {
//			setTimeout(()=>{
				const coords = popupTitleRef.current.getBoundingClientRect();
				const wholepopup = overlayRef.current.getBoundingClientRect();

				if (overlay === "vertical") {
					let x = !isLandscape && isMo1 ? wholepopup.width / 2 : coords.left + coords.width;
					let y = !isLandscape && isMo1 ? wholepopup.height + 4 : coords.top + coords.height + 10;
					return (
						<svg height={height} width={width} opacity={0.6}>
							{(!isMo1 || isLandscape) &&
								<g>
									<filter id="shadow" x="0" y="0" width="200%" height="200%">
										<feDropShadow dx="2" dy="3" stdDeviation="2.5" floodColor="#000"
										              floodOpacity="0.5"/>
									</filter>
									<line x1={x} y1={y} x2={hoveredCoords.x} y2={hoveredCoords.y}
									      stroke="white" strokeWidth="3"
									      strokeLinejoin="round"
									      opacity={hoveredCoords.y < wholepopup.height ? 0.4 : 1}
									      filter="url(#shadow)"
									/>
									<line x1={x} y1={y} x2={x - coords.width} y2={y}
									      stroke="white" strokeWidth="3"
									      strokeLinecap="round"
									      opacity={0.6}
									/>
								</g>
							}
							{isMo1 && !isLandscape &&
								<g>
									<filter id="shadow" x="0" y="0" width="200%" height="200%">
										<feDropShadow dx="2" dy="3" stdDeviation="2.5" floodColor="#000"
										              floodOpacity="0.5"/>
									</filter>
									<line x1={coords.width + 20} y1={y} x2={hoveredCoords.x} y2={hoveredCoords.y}
									      stroke="white" strokeWidth="3"
									      strokeLinejoin="round"
									      filter="url(#shadow)"
									/>
									<line x1={coords.width + 20} y1={wholepopup.height - 20} x2={coords.width + 20} y2={y}
									      stroke="white" strokeWidth="3"
									      strokeLinecap="round"
									/>
								</g>
							}

							<circle cx={hoveredCoords.x} cy={hoveredCoords.y} r="4" stroke="white" strokeWidth="0"
							        fill="white"
							        opacity={hoveredCoords.y < wholepopup.height ? 0.4 : 1}
							/>
						</svg>
					)
				} else {
					let x = coords.left + coords.width;
					let y = coords.top + coords.height + 10;
					return (
						<svg height="2000" width="3000" opacity={0.6}>
							<line x1={x} y1={y} x2={hoveredCoords.x} y2={hoveredCoords.y} stroke="white" strokeWidth="3"/>
							<line x1={x} y1={y} x2={x - coords.width} y2={y} stroke="white" strokeWidth="3"/>
							<circle cx={hoveredCoords.x} cy={hoveredCoords.y} r="4" stroke="white" strokeWidth="0"
							        fill="white"/>
						</svg>
					)
				}
//			}, 1000)
			}
			return null

		}

		function ShowHoveredCoords() {
			return (<p>coords: <br/> x: {hoveredCoords.x} <br/> y: {hoveredCoords.y}<br/> z: {hoveredCoords.z}</p>)
		}

		function closePopup(e) {
			setStayOnScreen(false);
			setHidden(true);
			setHoveredPopup(false);
			setCameraAnimationStopped(false);
			HideRefLine();
		}

		function getImageInfo(currentImage) {
			const image = itemsList.find((a) => parseInt(a.id) == parseInt(currentImage.id) ? a : '')
			const museum = museumsList.find((a) => parseInt(a.idMandant) === parseInt(image.museum) ? a : '')
			const info = {museum, image};
			return info
		}

		function getTitle(imageIndex) {
			/*
			<div className='imgtitle'>{element.caption}.&nbsp; <a href={url} className="fullview" target='_blank' rel="noopener noreferrer" style={fontstyle} >Klicken Sie hier für Details (in neuem Tab)
				&nbsp;&nbsp;<i><FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></i></a></div>;
			*/
			let title = imageIndex.title.replace(/\s+$/, '');
			let museum = getImageInfo(imageIndex).museum.bezeichnung;
			let outstring = <div> {title} - <i>{museum}</i></div>;
			return outstring
		}

//	useEffect(()=>{
//		console.log("dimensions width", width)
//		console.log("dimensions popuptitem", popupTitleRef.current.offsetWidth)
//		console.log("dimensions thirdcont", thirdContainerRef.current.offsetWidth)
//	})


		return (
			<group>
				<Html
					calculatePosition={() => [0, 0, 0]}
					occlude
					onOcclude={null}
					zIndexRange={[2000, 1000]}
					style={{
						pointerEvents: "none",
						opacity: 1,
					}}
				>
					<div className={showReferenceLine ? "screenoverlay fade-in" : "screenoverlay fade-out"}>
						{showReferenceLine &&
							<ReferenceLine key={toggleRefline === "reflineA" ? "reflineB" : "reflineA"}></ReferenceLine>
						}
					</div>


				</Html>

				<Html
					ref={popupRef}
					calculatePosition={() => [0, 0, 0]}
					occlude
					onOcclude={setHidden}
					zIndexRange={[300, 200]}
					style={{
						transition: 'all 0.4s',
						opacity: hidden ? 0 : 1,
						transform: `scale(${hidden ? [1, 0] : [1, 1]})`
					}}
				>
					<div
						className={`${overlay}`}
						className={'verticalStory'}
						ref={overlayRef}
						style={{
							opacity: 1,
							backgroundColor: hexToRgbA(overlayBackgroundColor, !isLandscape && isMo1 ? 0.8 : 0.6),
							width: isMo1 ? isLandscape ? '40vw' : '100vw' : '30vw',
							height: !isLandscape && isMo1 ? '60vh' : '100vh',
							fontSize: isMo1 ? isLandscape ? '0.85em' : '1.0em' : '1.2em',

						}}
						onMouseEnter={(e) => {
							if (!isSafari) {
//								console.log('++ mouse entered');
								setHovered(true)
								return true
							}
						}
						}
						onMouseLeave={(e) => {
//							console.log('++ mouse left');
							if (!isSafari) {
								setHovered(false)
								return true
							}
						}}
						onTouchStart={(e) => {
//							console.log('++ touch start', e);
							setHovered(true)
							setDragged(true)
//							console.log('sethovered = true')
//						if (isSafari) e.preventDefault();
						}}
						onTouchEnd={(e) => {
							//console.log('++ touch end', e);
							setHovered(false)
							setDragged(false)
							//console.log('sethovered = false (ended)')
//						if (isSafari) e.preventDefault();
						}}
						onTouchCancel={(e) => {
//							console.log('++ touch cancelled');
							e.preventDefault();
							e.stopPropagation();
							setHovered(false)
							setDragged(false)
//							console.log('sethovered = false (canceled)')

						}}
					>
						<div className="closeX"
						     style={{
							     left: isMo1 ? isLandscape ? '85%' : '90%' : '90%'
						     }}
						     onClick={(e) => {
//							     console.log('++ close clicked');
							     mtrack('popup close')
//						     if (isSafari) e.preventDefault();
							     closePopup(e);
						     }}
						>
							&times;
						</div>
						<div className='popuptext firstcontainer' style={{
//							paddingTop: isMo1 ? isLandscape ? '0px' : '0px' : '10px',
							marginLeft: isMo1 ? isLandscape ? '5px' : '5px' : '30px',
							marginRight: isMo1 ? isLandscape ? '5px' : '15px' : '30px',
							padding: isMo1 ? isLandscape ? '0.5vw 0.5vw 0.5vw 0.5vw' : '0.1vw 0.1vw 0.1vw 0.1vw' : '0.5vw 0.5vw 0.5vw 0.5vw'
						}}>
							{overlay === "vertical" && <h3 ref={popupTitleRef}><GetItem val="title"></GetItem></h3>}
							Datierung: <GetItem val="objectdate"></GetItem>
							{overlay === "horizontal" && <h3 ref={popupTitleRef}>Gegenstand Bezeichnung Bildname </h3>}
						</div>
						<div className="secondcontainer" style={{
							padding: 5,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							opacity: 1
						}}>
							{lastSelected > -1 &&
								<ShowImages val={lastSelected}></ShowImages>
							}
						</div>
						{lastSelected > -1 &&
							<Sketchfab> val={lastSelected > -1 ? itemsList[lastSelected] : -1} </Sketchfab>
						}
						{/*<ShowSelected/>*/}
						{/*<ShowHoveredCoords/>*/}

						{!hidden &&
							<div ref={thirdContainerRef} className="thirdcontainer"
						      style={{
							      marginLeft: isMo1 ? isLandscape ? '5px' : '15px' : '30px',
							      marginRight: isMo1 ? isLandscape ? '5px' : '15px' : '30px',
							      padding: isMo1 ? isLandscape ? '0.5vw 0.5vw 0.5vw 0.5vw' : '0.1vw 0.1vw 0.1vw 0.1vw' : '0.5vw 0.5vw 0.5vw 0.5vw'
						      }}
						>
							{
								isSafari &&
								<ItemDescription2
									val={lastSelected > -1 ? itemsList[lastSelected].description : ''}
									new={selected}
								>
								</ItemDescription2>
							}
							{
								!isSafari &&
								<ItemDescription
									val={lastSelected > -1 ? itemsList[lastSelected].description : ''}
									new={selected}
								>
								</ItemDescription>
							}

							<Museum val={lastSelected > -1 ? itemsList[lastSelected] : -1}></Museum>
							<ShareLink val={lastSelected}/>
						</div>
						}
					</div>
				</Html>

			</group>
		)

	}
)

const ShareLink = (d) => {

	const itemsList = useStore(state => state.items);

	const url = window.location.protocol + '//' + window.location.hostname + '/?inv=';
	//	let url = process.env.REACT_APP_MAIN_PATH+'/?inv='+itemsList[lastSelected].id_inventar;

	return (
		<>
			{d.val !== -1 &&
				<div className={'link tooltip'}
					//				      style={{float: 'right'}}
					 onMouseOver={() => {
						 let tooltip = document.getElementById("myTooltip");
						 tooltip.innerHTML = "Link in Zwischenablage kopieren"
					 }
					 }
					 onClick={() => {
						 navigator.clipboard.writeText(url + itemsList[d.val].id_inventar);
						 let tooltip = document.getElementById("myTooltip");
						 tooltip.innerHTML = "Kopiert: " + url + itemsList[d.val].id_inventar;
						 return true
					 }
					 }
				>
					<span style={{verticalAlign: 'top'}}>
						<svg version="1.1" x="0px" y="0px"
						     width="25px" height="25px" viewBox="0 0 483 483">
	<g color={'white'} fill={'white'}>
		<path d="M395.72,0c-48.204,0-87.281,39.078-87.281,87.281c0,2.036,0.164,4.03,0.309,6.029l-161.233,75.674
			c-15.668-14.971-36.852-24.215-60.231-24.215c-48.204,0.001-87.282,39.079-87.282,87.282c0,48.204,39.078,87.281,87.281,87.281
			c15.206,0,29.501-3.907,41.948-10.741l69.789,58.806c-3.056,8.896-4.789,18.396-4.789,28.322c0,48.204,39.078,87.281,87.281,87.281
			c48.205,0,87.281-39.078,87.281-87.281s-39.077-87.281-87.281-87.281c-15.205,0-29.5,3.908-41.949,10.74l-69.788-58.805
			c3.057-8.891,4.789-18.396,4.789-28.322c0-2.035-0.164-4.024-0.308-6.029l161.232-75.674c15.668,14.971,36.852,24.215,60.23,24.215
			c48.203,0,87.281-39.078,87.281-87.281C482.999,39.079,443.923,0,395.72,0z"/>
	</g>
						</svg>
					</span>
					<span style={{paddingLeft: '1vw', paddingRight: '1vw', width: 'auto'}}>
						Permalink<br/>
					</span>
					<span className={'tooltiptext'} id="myTooltip">Link in Zwischenablage kopieren
					</span>
				</div>}
		</>
	)

}

const ShowImages = (d) => {

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const itemsList = useStore(state => state.items);

//	const id = parseInt(d.val) ? parseInt(d.val) : -1;
	const id = parseInt(d.val);

	const dummypath = './nopic.png';
	const [currImg, setCurrImg] = useState(0);

	const [imgs, setImgs] = useState([]);

	const imageIndex = useStore(state => state.imageIndex); // lightbox
	const setImageIndex = useStore(state => state.setImageIndex); // lightbox
	const setImageIndexPos = useStore(state => state.setImageIndexPos); // lightbox

	const setShowLightbox = useStore(state => state.setShowLightbox);

	const imageContainerRef = useRef();

	useEffect(() => {
//		console.log('restarted')
//		console.log('curr', imgs, currImg, id);
	}, [])

	useEffect(() => {
		if (id !== -1) {
			var imageArr = getItemsImages(id);
			setImgs(imageArr)
			if (imageArr.length < 2) {
				setCurrImg(0);
				setImageIndexPos(0);
			}
		}
	}, [id])

	function handleClick(e) {
		if (id > -1 && imgs[e.target.id]) {
//			console.log('XXXX clicked', e.target.id)
			mtrack('popup other image ' + e.target.id)
			setCurrImg(parseInt(e.target.id))
		}
	}

	// open an image in lightbox
	const onImageclick = (d, p) => {
//		console.log('XXXX01 clicked', d)
		mtrack('popup lightbox ' + d.filename1)
		setShowLightbox(true);
		setImageIndex(d);
		setImageIndexPos(p);
	}

	const computeWidth = (h) => {
//		console.log('h=', imgs[currImg], h)
//		console.log(imgs[currImg].ratio, isMo1)
//		console.log("computed width", width/100*30-65);
//		console.log("imagecontainer", imageContainerRef.current ? imageContainerRef.current.offsetWidth : 'undefined');

		let newWidth = width / 100 * (isMo1 ? isLandscape ? 26 : 60 : 26);
//		let newWidth = width/100 * 15;
		let ratioResize = isMo1 ? 1 : 0.75;

		if (imgs[currImg].ratio < ratioResize) {
//		if (imgs[currImg].ratio < 75) {
			// make smaller
//			console.log("< ratio => resizing")
//			let currratio = imgs[currImg].ratio * isMo1 ? 0.6 : 1.1;
			let currratio = imgs[currImg].ratio * 1.1;
			newWidth = newWidth * currratio;
		}

		return newWidth;
	}

	return (
		<>
			{imgs.length > 0 &&
				<div ref={imageContainerRef} className='imageContainer'>

					<div id={0} className='imagefwd' onClick={handleClick}
					     style={{
						     opacity: id > -1 && imgs[0] && currImg === 1 ? 1 : 0.01,
						     cursor: id > -1 && imgs[0] && currImg === 1 ? 'pointer' : 'default'
					     }}
					> &#10094; </div>
					<div className='itemsImage'
					     onClick={(e) => {
						     onImageclick(itemsList[d.val], currImg);
					     }}
					>
						<img
							src={id > -1 ? imgs[currImg].path : dummypath}
							//						height={id > -1 ? imgs[currImg].height *0.7 : isMo1 ? 220 : 220}
							width={id > -1 ? computeWidth(imgs[currImg].height) : 320}
						></img>
					</div>
					<div id={1} className='imageback' onClick={handleClick}
					     style={{
						     opacity: id > -1 && imgs[1] && currImg === 0 ? 1 : 0.01,
						     cursor: id > -1 && imgs[1] && currImg === 0 ? 'pointer' : 'default'
					     }}
					> &#10095; </div>
				</div>
			}
		</>
	)
}

const Sketchfab = (d) => {

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const media3D = useStore(state => state.media3D);

	var item = d.children[1];
	var model = media3D.find((d) => d.id_inventar === item.id_inventar);

	var sURL = model && model.sid ? "https://sketchfab.com/models/" + model.sid + "/embed?ui_theme=dark" : '';
//	var sURL = model && model.sid ? "https://sketchfab.com/models/" + model.sid + "/embed" : '';

	const imgsratio = 1.5;

	const computeWidth = (h) => {
		let newWidth = width / 100 * (isMo1 ? isLandscape ? 26 : 60 : 26);
		let ratioResize = isMo1 ? 1 : 0.75;

		if (imgsratio < ratioResize) {
			let currratio = imgsratio * 1.1;
			newWidth = newWidth * currratio;
		}
		return newWidth;
	}

	return (
		<>
			{model &&
				<div className="scetchfabcontainer" style={{
					padding: 5,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					opacity: 1
				}}>
					<div className="sketchfab-embed-wrapper">
						<div style={{padding: "0.5vw"}}></div>
						<iframe title={model.title} frameBorder="0" allowFullScreen mozallowfullscreen="true"
						        webkitallowfullscreen="true" allow="autoplay; fullscreen;xr-spatial-tracking"
						        xr-spatial-tracking={'true'} execution-while-out-of-viewport={"true"} execution-while-not-rendered={"true"}
						        web-share={'true'}
						        width={computeWidth(320)} height="320"
						        src={sURL}>
						</iframe>
					</div>
				</div>
			}
		</>
	)
}

const Museum = (d) => {

	const museumsList = useStore(state => state.museums);
	const [showMuseum, setShowMuseum] = useState(false);

	const museumId = d.val.museum;
	const ext = "<img src=\".logo192.png\" height=13 style='verticalAlign: bottom' alt='external Link'/>";

	function striplinks(text) {
		var re = /<a\s.*?href=[\"\'](.*?)[\"\']*?>(.*?)<\/a>/g;
		var str = text;
		var subst = '$2';
		var result = str.replace(re, subst);
		return result;
	}

	console.log(striplinks(''))
	
	if (museumId && museumId > -1) {
		const museum = museumsList.find((a) => a.idMandant === museumId ? a : '')

		
		
//			console.log(museum);
		return (
			<>
				<p><b>Museum:</b> {museum.bezeichnung}</p>
				{
					!showMuseum &&
					<div>
						<div className={"link"} onClick={() => {
							mtrack('popup description museum more')
							setShowMuseum(true)
						}}>
							mehr Info zum Museum
						</div>
						<div style={{width: '5px', margin: '10px', textDecoration: 'none'}}>&nbsp;</div>
					</div>
				}
				{
					showMuseum && <div>
						{/*<p><b>Öffnungszeiten:</b>*/}
						{/*	<br></br>*/}
						{/*	{museum.open.split('\n').map((line, i, arr) => {*/}
						{/*		let lineOut;*/}
						{/*		if (line.search(/<a.href=/) > -1) {*/}
						{/*			if (line.search(/>Öffnungszeiten</) > -1) {*/}
						{/*				let out = "siehe "*/}
						{/*					+ line.replace(/>Öffnungszeiten</, ' target=\'_blank\'>homepage<')*/}
						{/*					+ "<img src='extlink.png' height='12' style='vertical-align: bottom'/>"*/}
						{/*					+ " des Museums";*/}
						{/*				lineOut = <span key={i} dangerouslySetInnerHTML={{__html: out}}></span>;*/}
						{/*			} else {*/}
						{/*				lineOut = <span key={i} dangerouslySetInnerHTML={{__html: line}}></span>;*/}
						{/*			}*/}
						
						{/*			return lineOut;*/}
						{/*		} else {*/}
						{/*			lineOut = <span key={i}>{line}</span>;*/}
						{/*		}*/}
						{/*		if (i === arr.length - 1) {*/}
						{/*			return lineOut;*/}
						{/*		} else {*/}
						{/*			return [lineOut, <br key={i + 'br'}/>];*/}
						{/*		}*/}
						{/*	})}*/}
						{/*</p>*/}

						
						<p style={{flexDirection: 'row', justifyContent: 'start'}}>
							<div>
							<b>Link zum Museum: </b>
							</div>
							<a href={'https://'+striplinks(museum.open)} target='_bank'> 
								{striplinks(museum.open)}
								<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
							</a>
						</p>
					
						<p style={{flexDirection: 'row', justifyContent: 'space-between'}}>
							<b>Info zum Museum: </b>
							<a href={museum.homepageSlrg} target='_bank'>
								salzburg.gv.at
								<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
							</a>

						</p>
						<p>
							<b>Anfahrt: </b>
							<a href={museum.directions} target='_bank'>Google Maps
								<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
							</a>
						</p>
						<div className={"link"} onClick={() => {
							mtrack('popup description museum less')
							setShowMuseum(false)
						}}>
							weniger Info zum Museum<br/><br/>
						</div>
					</div>
				}
			</>)
	} else return (<></>)
}
