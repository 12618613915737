import React, {memo} from "react";
import {Line, Text} from "@react-three/drei";
import {getCompression, radian, rescaleYears} from "../utils/helpers";

//export default function Epochs () {
	export const Epochs = React.memo(() => {


//	const textcolor = "lightgrey"; // orig
	const textcolor = "#b5c9ca";
//	const linecolor = "lightgrey"; // orig
	const linecolor = "#c0d3d4";
	const lineWidth = 0.45;
	const dashScale = 10;
	const dashSize = 0.5;
	const dashOffset = 0.1;
//	const dashed = false;  // orig
	const dashed = true;


	// [epchname, startYear, stopYear, lenghtOfLine, Xoffset, textoffset]
	const epochs = [

//		['Paläozoikum', -252000000, -541000000, 45, -3.5, -0.5],
		['Paläozoikum', -252000000, -500000000, 20, -1, 0],
		['Trias', -201000000, -252000000, 3, -1.5, 0],
		['Jura', -145000000, -201000000, 4,  -1, 0],
		['Kreidezeit', -66000000, -142000000, 5.5, -1.5, 0],
		['Paläogen', -23000000, -66000000, 3, -1, 0],
		['Neogen', -2600000, -23000000, 8, -1.5, 0],
		['Steinzeit', -2200, -600000, 26, -2.5, 0],
		['Bronzezeit', -800, -2200 , 2.5, -3, -0.8],
		['Eisenzeit', 0, -800, 1.5, -1.5, -0.5],
		['Antike',  500, -450,  17, -2.5, 0],
		['Frühmittelalter', 500, 1000, 14, -2, 0],
		['Hochmittelalter', 1000, 1250, 5, -2.5, 0],
		['Spätmittelalter', 1250, 1500, 5, -2, 0],
		['Renaissance', 1520, 1580, 2, -2.5, -0.8],
		['Manierismus', 1580, 1615, 1, -1.5, -0.5],
		['Barock', 1615, 1750, 13, -2.5, 0],
		['Rokoko', 1750, 1780, 5, -2, 0],
		['Klassizismus', 1780, 1815, 5, -2.5, 0],
		['Biedermeier', 1815, 1850, 5, -2, 0],
		['Historismus', 1850, 1890, 6, -2.5, 0],
		['Moderne', 1890, 1945, 10, -2, 0],
		['Gegenwart', 1945, 1970, 3, -2.5, 0],
		['Postmoderne', 1970, 2000, 4, -2, 0],

]

	const r = (d) => rescaleYears(d)

	let zpos = 2;

	var EpochsList = epochs.map((d, i) => {

		let xpos = d[4];

		let corr = 0;

		if (d[0] === "Kreidezeit") corr = -5.5;

		return <Text
			key={"et_" + i}
			position={[xpos+d[5], (r(d[1]) + (r(d[2]) - r(d[1])) / 2)+corr, zpos]}
			//				rotation={[Math.PI / 2, 0, 0]}
			rotation={[0, 0, radian(90)]}
			color={textcolor}
			fontSize={1}
		> {(d[0])}</Text>
	})

	var EpochsLines = epochs.map((d, i) => {

		let xpos = d[4];
		let offset = d[3];

		let startYear = d[1];
		let stopYear = d[2];

		let arrowOffsetStart = startYear > 0 ? 0.3 : -0.3;
		let arrowOffsetStop = stopYear > 0 ? 0.3 : -0.3;

		let startOffset = startYear > 0 ? offset : -offset;
		let compressionOffsetStart = startOffset * 1 / getCompression(startYear);

		let stopOffset = startYear > 0 ? offset : -offset;
		let compressionOffsetStop = stopOffset * 1 / getCompression(stopYear);

		if (startYear > 0 && stopYear < 0) {
			startOffset = startOffset * -1
			stopOffset = stopOffset * -1
		}

		if (d[0] === "Kreidezeit") {
			startOffset = startOffset +5;
			stopOffset = stopOffset -5;
		}

//			console.log(d[0], compressionOffsetStart, startYear);
//			console.log(d[0], compressionOffsetStop, stopYear);


		return <group key={"x" + i}>
			<Line
				key={"el1_" + i}
//				points={[[xpos, rescaleYears(startYear), zpos], [xpos, rescaleYears(startYear + compressionOffsetStart), zpos]]}
				points={[[xpos, rescaleYears(startYear), zpos], [xpos, rescaleYears(startYear) - startOffset, zpos]]}
				color= {linecolor}
				lineWidth = {lineWidth}
				dashScale = {dashScale}
				dashSize = {dashSize}
				dashOffset = {dashOffset}
				dashed = {dashed}
			/>
			<Line
				key={"el2_" + i}
//				points={[[xpos, rescaleYears(stopYear), zpos], [xpos, rescaleYears(stopYear - compressionOffsetStop), zpos]]}
				points={[[xpos, rescaleYears(stopYear), zpos], [xpos, rescaleYears(stopYear) + stopOffset, zpos]]}
				lineWidth={0.45}
				color= {linecolor}
				lineWidth = {lineWidth}
				dashScale = {dashScale}
				dashSize = {dashSize}
				dashOffset = {dashOffset}
				dashed = {dashed}
			/>
			<Line
				key={"el2stop_" + i}
				points={[[xpos - 0.2, rescaleYears(stopYear), zpos], [xpos + 0.2, rescaleYears(stopYear), zpos]]}
				color= {linecolor}
				lineWidth={0.45}
				dashed={false}
			/>
			<Line
				key={"el1stop_" + i}
				points={[[xpos - 0.2, rescaleYears(startYear), zpos], [xpos + 0.2, rescaleYears(startYear), zpos]]}
				color= {linecolor}
				lineWidth={0.45}
				dashed={false}
			/>
			<Line
				key={"ela1_" + i}
				points={[[xpos - 0.1, rescaleYears(startYear) - arrowOffsetStart, zpos], [xpos, rescaleYears(startYear), zpos]]}
				color= {linecolor}
				lineWidth={0.45}
				dashed={false}
			/>
			<Line
				key={"ela2_" + i}
				points={[[xpos + 0.1, rescaleYears(startYear) - arrowOffsetStart, zpos], [xpos, rescaleYears(startYear), zpos]]}
				color= {linecolor}
				lineWidth={0.45}
				dashed={false}
			/>
			<Line
				key={"ela3_" + i}
				points={[[xpos - 0.1, rescaleYears(stopYear) + arrowOffsetStop, zpos], [xpos, rescaleYears(stopYear), zpos]]}
				color= {linecolor}
				lineWidth={0.45}
				dashed={false}
			/>
			<Line
				key={"ela4_" + i}
				points={[[xpos + 0.1, rescaleYears(stopYear) + arrowOffsetStop, zpos], [xpos, rescaleYears(stopYear), zpos]]}
				color= {linecolor}
				lineWidth={0.45}
				dashed={false}
			/>
		</group>
	})

	return (
		<> {EpochsList}{EpochsLines} </>
	);
}
)

export default Epochs;
