import React, {Suspense, useEffect, useLayoutEffect, useState, useRef} from "react";
import {useWindowDimensions} from "../utils/hooks";
import {useStore} from "./store";
import {Html} from "@react-three/drei";
import {mtrack} from "../utils/helpers";

export const JumpRelative = React.memo(() => {

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const jumpClicked = useStore(state => state.jumpClicked);
	const increaseJumpClicked = useStore(state => state.increaseJumpClicked);

	const setAnimateJump = useStore(state => state.setAnimateJump);
	const setJumpDistance = useStore(state => state.setJumpDistance);

	const [hoveredFwd, setHoveredFwd] = useState(false);
	const [hoveredBack, setHoveredBack] = useState(false);



	function startRelativeJump(d) {
//		console.log("d", d);
		setJumpDistance(d === 1 ? 30 : -30)
		increaseJumpClicked();
		mtrack('jump '+(d ===1 ? 'back' : 'forward'))
	}

	useEffect(() => {
//		console.log("testClicked", jumpClicked);
		setAnimateJump(jumpClicked > 0 ? true : false);
	}, [jumpClicked])

	return (
//		<Html>
			<div className="jumpControl">
				<div style={{position: 'absolute',
					left: isMo1 && !isLandscape ? '85vw' : '70vw',
					top: -5, width: 30, zIndex: 1}}
				     onClick={(e) => startRelativeJump(1)}
				     onMouseEnter={(e) => setHoveredFwd(true)}
				     onMouseLeave={(e) => setHoveredFwd(false)}
				>
					<svg className="sliderButton" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M 10 70 L 40 40 L 10 10"
							transform="rotate(-90 40 40)"
							strokeOpacity={hoveredFwd ? 1 : 0.6}
							fill="transparent"
							stroke={hoveredFwd ? "darkgray" : "lightgray"}
							strokeWidth="12"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div style={{position: 'absolute',
					left: isMo1 && !isLandscape ? '85vw' : '70vw',
					top: 30, width: 30}}
				     onClick={(e) => startRelativeJump(-1)}
				     onMouseEnter={(e) => setHoveredBack(true)}
				     onMouseLeave={(e) => setHoveredBack(false)}
				>
					<svg className="sliderButton" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M 10 70 L 40 40 L 10 10"
							transform="rotate(90 40 40)"
							strokeOpacity={hoveredBack ? 1 : 0.6}
							fill="transparent"
							stroke={hoveredBack ? "darkgray" : "lightgray"}
							strokeWidth="12"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</div>
//		</Html>
	)
})
