import React, {Suspense, useState, useRef, useEffect} from 'react'
import {useStore} from './store';
import * as d3 from "d3";
import {useWindowDimensions} from "../utils/hooks";
import {useSpring, a, config} from '@react-spring/web';
import {isMobileOnly, BrowserTypes, isIOS, isSafari, isMobile, deviceType, isChrome} from 'react-device-detect';
import { useMatomo } from '@datapunt/matomo-tracker-react'

export const TransitionControl = React.memo(() => {

		const { trackPageView, trackEvent } = useMatomo();

		const {height, width} = useWindowDimensions();

		const isMo = useStore(state => state.isMobile);
		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);
		const isLandscape = useStore(state => state.isLandscape);


		const [hovered, setHovered] = useState(false);
		const [clicked, setClicked] = useState(false);

		const transition = useStore(state => state.transition);
		const setTransition = useStore(state => state.setTransition);

		const {rotate} = useSpring({
			from: {rotate: 0},
			to: {rotate: transition ? 180 : 0},
			delay: transition ? 100 : 100,
			config: {
				duration: transition ? 800 : 800,
			}
		})

		const offset = isMo1 ? isLandscape ? 45 : 45 : 60;

		const {s} = useSpring({
			from: {s: width - 50},
			to: {s: transition ?
					(isMo1 ?
						isLandscape ? (isSafari ?  width - offset +15 : width - offset)
							: width - 5 : width - offset)
					: width - offset},
			delay: transition ? 100 : 100,
//			config: {
//				duration: transition ? 800 : 800,
//			}
		})


		useEffect(() => {
				d3.select(".sliderButtonBackground").selectAll("rect").attr("fill-opacity", d => hovered ? 0.5 : isMo1 ? 0.5 : 0.3);
				d3.select(".sliderButton").selectAll("*").style("stroke-opacity", d => hovered ? 1 : isMo1 ? 0.7 : 0.2);
		}, [hovered])

		return (
			<a.div
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				onClick={() => {
					setTransition(transition ? false : true)
					trackEvent({ category: transition ? 'sliding to timeline' : 'sliding to sunburst' , action: 'click-event' })
				}}
				style={{
					position: 'absolute',
					width: isMo1 && !isLandscape ? 65 : 75,
					height: 100,
					top: !isLandscape && isMo1 ? height * 0.4 : isSafari ? height * 0.15 : height / 2,
					left: s,
					zIndex: 101,
					overscroll: 'hidden'
				}}>
				<div>
					<svg className="sliderButtonBackground" viewBox="0 0 80 100" xmlns="http://www.w3.org/2000/svg">
						<rect
							x={10} y={10} rx={10} ry={10}
							width={isMo1 ? isLandscape ? 35 : 40 : 50}
							height={isMo1 ? isLandscape ? 55 : 60 : 70}
							fill="black"
							fillOpacity={isMo1 ? 0.5 : 0.3}
						/>
					</svg>
				</div>
				<a.div
					style={{
						position: 'relative',
						top: isMo1 ? isLandscape ? -80 : -72 : -80,
						width: isMo1 ? isLandscape ? 30 : 35 : 45,
						left: isMo1 ? isLandscape ? 15 : 14 : 18,
						transformOrigin: '30%',
						transform: rotate.to(rotate => `rotate3d(0,1,0,${rotate}deg)`)
					}}
				>
					<svg className="sliderButton" viewBox="0 0 70 80" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M 10 70 L 40 40 L 10 10"
							strokeOpacity={isMo1 ? 1 : 0.7}
							fill="transparent"
							stroke="white"
							strokeWidth="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</a.div>
			</a.div>
		)
	}
)
