import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback} from 'react'
import "../css/popup.css";
import extLink from '../assets/extlink.png';
//import {ReactComponent as ExtLink} from '../assets/extlink.svg';
import {useStore} from './store';
import {Html, Line} from "@react-three/drei";
import {hexToRgbA} from "../utils/helpers";
import {useInterval, useWindowDimensions} from "../utils/hooks";
import {useFrame} from '@react-three/fiber';
import * as THREE from "three";
import ItemDescription from "./description";
import Lightbox from 'react-image-lightbox';
//import '../css/lightbox.css'; // This only needs to be imported once in your app
//import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

export default function Museum(d) {

	const museumId = parseInt(d.val.museum);

	const museum=d.museumdata;

	const {height, width} = useWindowDimensions();

	const itemsList = useStore(state => state.items);
	const museumsList = useStore(state => state.museums);

	const [showMuseum, setShowMuseum] = useState(true);

	function striplinks(text) {
		var re = /<a\s.*?href=[\"\'](.*?)[\"\']*?>(.*?)<\/a>/g;
		var str = text;
		var subst = '$2';
		var result = str.replace(re, subst);
		return result;
	}
	
	
	const ext = "<img src=\".logo192.png\" height=13 style='verticalAlign: bottom' alt='external Link'/>";
	if (museum.id > -1) {
		return (
			<>
				<p><b>Museum:</b> {museum.bezeichnung}</p>
				{/*{*/}
				{/*	!showMuseum &&*/}
				{/*	<div className={"link"} onClick={() => setShowMuseum(true)}>*/}
				{/*		mehr Info zum Museum*/}
				{/*	</div>*/}
				{/*}*/}
				{
					showMuseum && <div>
						{/*<p><b>Öffnungszeiten:</b>*/}
						{/*	<br></br>*/}
						{/*	{museum.open.split('\n').map((line, i, arr) => {*/}
						{/*		let lineOut;*/}
						{/*		if (line.search(/<a.href=/) > -1) {*/}
						{/*			if (line.search(/>Öffnungszeiten</) > -1) {*/}
						{/*				let out = "siehe "*/}
						{/*					+ line.replace(/>Öffnungszeiten</, ' target=\'_blank\'>homepage<')*/}
						{/*					+ "<img src='extlink.png' height='12' style='vertical-align: bottom'/>"*/}
						{/*					+ " des Museums";*/}
						{/*				lineOut = <span key={i} dangerouslySetInnerHTML={{__html: out}}></span>;*/}
						{/*			} else {*/}
						{/*				lineOut = <span key={i} dangerouslySetInnerHTML={{__html: line}}></span>;*/}
						{/*			}*/}
						
						{/*			return lineOut;*/}
						{/*		} else {*/}
						{/*			lineOut = <span key={i}>{line}</span>;*/}
						{/*		}*/}
						{/*		if (i === arr.length - 1) {*/}
						{/*			return lineOut;*/}
						{/*		} else {*/}
						{/*			return [lineOut, <br key={i + 'br'}/>];*/}
						{/*		}*/}
						{/*	})}*/}
						{/*</p>*/}

						<p style={{flexDirection: 'row', justifyContent: 'start'}}>
							<div>
								<b>Link zum Museum: </b>
							</div>
							<a href={'https://'+striplinks(museum.open)} target='_bank'>
								{striplinks(museum.open)}
								<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
							</a>

						</p>
						<p style={{flexDirection: 'row', justifyContent: 'space-between'}}>
							<b>Info zum Museum: </b>
							<a href={museum.homepageSlrg} target='_bank'>
								salzburg.gv.at
								<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
							</a>

						</p>
						<p>
							<b>Anfahrt: </b>
							<a href={museum.directions} target='_bank'>Google Maps
								<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
							</a>
						</p>
						{/*<div className={"link"} onClick={() => setShowMuseum(false)}>*/}
						{/*	weniger Info zum Museum*/}
						{/*</div>*/}
					</div>
				}
			</>)
	} else return (<></>)
}
