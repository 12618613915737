import React, {Suspense, useMemo, useRef, useState, useLayoutEffect} from "react";
import {BufferAttribute} from 'three';
import {useStore} from "./store";

//return object: array with height data from img, img height & width
function getHeightData(img, scale) {

	if (scale == undefined) scale = 0.65;

	var canvas = document.createElement('canvas');
	canvas.width = img.width;
	canvas.height = img.height;
	var context = canvas.getContext('2d');

	var size = img.width * img.height;
	var pixelArray = new Float32Array(size);

	context.drawImage(img, 0, 0);

	for (let i = 0; i < size; i++) {
		pixelArray[i] = 0
	}

	var image = context.getImageData(0, 0, img.width, img.height);
	var pix = image.data;

	var j = 0;
	for (let i = 0; i < pix.length; i += 4) {
		var all = pix[i] + pix[i + 1] + pix[i + 2];
		pixelArray[j++] = all / (12 * scale);
	}

	return ({data: pixelArray, width: canvas.width, height: canvas.height});
}

const generateTerrain = (geo, heightmap) => {

	let elevation = heightmap.data;
	let width = heightmap.width;
	let height = heightmap.height;
	let pos = [];
	let i = 0;
	let scaleWidth = 0.75;
	let scaleHeight = 0.22;
	let exaggeration = 0.14;
	let every = 2;

	for (let row = height; row > 1; row--) {
		for (let col = width; col > 1; col--) {
			i++;
			// omit black (elevation === 0)
			if (elevation[i] > 0 && i % every === 0 && row % every === 0) {
				pos.push(20 - row * scaleWidth + (Math.random() * 0.3));
				pos.push(20 - col * scaleHeight + (Math.random() * 0.3));
				pos.push(-3 + elevation[i] * exaggeration)
			}
		}
		i++;
	}
	return new Float32Array(pos)
}


const Land = () => {

	const points = useRef();

	const [init, setInit] = useState(false);

	var img = new Image();
	img.src = "elevation8.png";

	img.onload = () => {
		var heightmap = getHeightData(img);
		let current = points.current;

		if (points.current) {
			points.current.setAttribute(
				'position',
				new BufferAttribute(
					generateTerrain(current, heightmap),
					3
				));
		}
		setInit(true);
//		console.log("points", points.current);
	}

	const pointsColor = useStore(state => state.landscapeColor);

	const pointsObject = useRef();

	useLayoutEffect(() => {
		if (pointsObject.current && init) {
			pointsObject.current.frustumCulled = false;
//			pointsObject.current.frustumCulled = true;
//			pointsObject.current.updateMatrix();
//			pointsObject.current.geometry.computeBoundingSphere(1);
			pointsObject.current.elementsNeedUpdate = true;
		}
	}, [pointsObject, init]);

	const [pointCount, setPointCount] = useState(1); // needed to init

	const [positions, colors] = useMemo(() => {
		let positions = [1, 1, 1], colors = [1, 1, 1];
		return [new Float32Array(positions), new Float32Array(colors)]
	}, [pointCount])

	const attrib = useRef();

	return (
		<group position={[40, -5, 0]} rotation={[0, 0, -0.015 - Math.PI / 2]}>
			<Suspense fallback={<div center className="loading" children="Loading..."/>}>
				<points ref={pointsObject}>
					<bufferGeometry ref={points} attach="geometry">
						<bufferAttribute attachObject={["attributes", "position"]} count={positions.length / 3}
						                 array={positions} itemSize={3}/>
						<bufferAttribute ref={attrib} attachObject={["attributes", "color"]} count={colors.length / 3}
						                 array={colors} itemSize={3}/>
					</bufferGeometry>
					<pointsMaterial
						transparent={false}
						attach="material"
						color={pointsColor}
						//						opacity={0.8}
						size={0.15}
						sizeAttenuation={true}/>
				</points>
			</Suspense>
		</group>
	);
};

export const Landscape = React.memo(() => {
		return (
			<Suspense fallback={null}>
				<Land/>
			</Suspense>
		);
	}
)
