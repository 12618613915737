import React, {Suspense, useEffect, useLayoutEffect, useState, useRef} from "react";
import {useWindowDimensions} from "../utils/hooks";
import {useStore} from "./store";
import {Html} from "@react-three/drei";
import ReactPlayer from 'react-player/lazy'
import {Help} from "./help"
import {Tracker} from "./tracker";
import {mtrack} from "../utils/helpers";
import Museum from "./museum";
import ItemDescription from "./description";
import {InfoPanel} from "./infopanel";

export const MediaOverlay = React.memo(() => {

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const setShowMediaOverlay = useStore(state => state.setShowMediaOverlay);
	const showMediaOverlay = useStore(state => state.showMediaOverlay);

	const showVideo = useStore(state => state.showVideo);
	const showAudio = useStore(state => state.showAudio);
	const show3D = useStore(state => state.show3D);
	const setShow3D = useStore(state => state.setShow3D);

	const showTour = useStore(state => state.showTour);
	const showInfo = useStore(state => state.showInfo);
	const showInfo2 = useStore(state => state.showInfo2);
	const showImpressum = useStore(state => state.showImpressum);


	const closePopup = (e) => {
//		console.log("clicked close");
		e.stopPropagation();
		setShowMediaOverlay(false);
		setShow3D(false);
	}

	const diffclose = (e) => {
		e.stopPropagation();
		if (showInfo || showInfo2) {
			setShowMediaOverlay(false);
			setShow3D(false);
		}
	}

	return (
//		<Html>

		<div width='100%' onClick={diffclose}>

			{!showInfo && !showInfo2 &&
				<div className="closeXX"
				     style={{
					     left: isMo ? isLandscape ? '92vw' : '87vw' : '96vw',
					     color: showImpressum ? 'black' : 'white',
					     zIndex: 202
				     }}
				     onClick={closePopup}
				>
					&times;
				</div>
			}

			{showVideo &&
				<div width='100%' style={{
					position: 'absolute',
					left: width / 2 - width / 2 * 0.8,
					top: height / 2 - height / 2 * 0.9
				}}>
					<ReactPlayer
						width={width * 0.8}
						height={height * 0.9}
						playing={true}
						controls={true}
						playing={showMediaOverlay}
						//						url='https://youtu.be/uD4izuDMUQA'
						url='https://youtu.be/gEOa793e_dU'
					/>
				</div>
			}

			{showAudio &&
				<div width='100%' style={{position: 'absolute', left: width / 2 - 200, top: height / 2 - 25}}>
					<ReactPlayer
						width="400px"
						height="50px"
						playing={true}
						controls={true}
						playing={showMediaOverlay}
						url='https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3'
					/>
				</div>
			}


			{show3D &&
				<SketchfabGallery/>
			}

			{
				showInfo &&
				<Help topic={'timeline'}></Help>
			}

			{
				showInfo2 &&
				<div style={{position: 'absolute', left: width + 100, opacity: 1}}>
					<Help topic={'sunburst'}></Help>
				</div>
			}


			{
				showImpressum &&
				<div style={{margin: '100p', backgroundColor: 'white'}}>

					<Impressum></Impressum>

				</div>
			}

		</div>
//		</Html>
	)
})


const Impressum = () => {

	const {height, width} = useWindowDimensions()

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const setShowMediaOverlay = useStore(state => state.setShowMediaOverlay);

	return (
		<div className={'impressum'} style={{ }}>
			<h2>Impressum</h2>
			<h3>Medieninhaber, Herausgeber und Hersteller</h3>

			<h4>Landesverband Salzburger Museen und Sammlungen</h4>
				Zugallistraße 10, 5020 Salzburg
			<br/>Telefon: +43 662 8042 2993
			<br/>E-Mail: museen@salzburgervolkskultur.at
			<br/><a href={'https://www.salzburgermuseen.at'}>www.salzburgermuseen.at</a>
			<br/>
			<br/>Vorsitzende/Obfrau: Dr.<sup>in</sup> Andrea Dillinger
			<br/>Zuständigkeit: Bundespolizeidirektion Salzburg
			<br/>ZVR-Zahl: 619362697
			<br/>
			<br/>Mitglied im Forum Salzburger Volkskultur
			<br/>UID-Nummer: ATU 33983400
			<br/>
			<br/>
			<img className={'logo1'} style={{width: isLandscape ? isMo1 ? width/3 : width/4 : width/3}} src={'VK_13 Logo-4c.jpg'} alt={'logo1'}></img>
			<img className={'logo2'} style={{width: isLandscape ? isMo1 ? width/4 : width/6 : width/4}} src={'Co-Branding Ld Sbg Gef von_pos.jpg'} alt={'logo2'}></img>
			<br/>
			<br/>

			<h4>Regionalmuseumsreferentin</h4>
			Mag.<sup>a</sup> Monika Brunner-Gaurek
			<br/>Referat 2/03, Volkskultur, kulturelles Erbe und Museen
			<br/>Telefon: +43 662 8042 3064
			<br/>E-Mail: monika.brunner@salzburg.gv.at


			<h4>Redaktionelle Koordination und fachliche Fragen</h4>
			Dr.<sup>in</sup> Hemma Ebner
			<br/>Telefon: + 43 650 3512305
			<br/>E-Mail: hemma.ebner@salzburg.gv.at

			<h4>Konzept & Evaluation Userinterface</h4>
				Universität für Weiterbildung Krems
			<br/>Department für Kunst- und Kulturwissenschaften
			<br/>Dr.<sup>in</sup> Eva Mayr und Dr. Florian Windhager
			<br/>Dr.-Karl-Dorrek-Straße 30, 3500 Krems
			<br/>E-Mail: eva.mayr@donau-uni.ac.at

			<h4>Programmierung und Mediendesign</h4>
				mindfactor IT solutions eU
			<br/>Mag. Michael Smuc
			<br/>E-Mail: office@mindfactor.at
			<br/><a href={'https://mindfactor.at'}>mindfactor.at</a>

			<h4>Fotos</h4>
				Landesverband Salzburger Museen und Sammlungen
			<br/>Bergbau- und Gotikmuseum Leogang
			<br/>Torf Glas Ziegel Museum Bürmoos
			<br/>Museum Schloss Ritzen Saalfelden
			<br/>Museum Tauernbahn Schwarzach
			<br/>Die Bachschmiede Wals
			<br/>Felberturmmuseum Mittersill
			<br/>Sigl. Haus St. Georgen
			<br/>Museum Burg Golling
			<br/>Pfarr-, Wallfahrts- und Stille Nacht Museum Mariapfarr

			<br/>
			<br/>Das Design des Webauftritts orientiert sich u.a. am Interface der Landessammlungen Niederösterreich und des British Museum.

			<h2>Rechtliche Hinweise</h2>
			<p>
				<strong>§ 1 – Haftungsausschluss:</strong> Alle auf dieser Website enthaltenen Informationen wurden mit großer Sorgfalt
			 Landesverband Salzburger Museen und Sammlungen übernimmt jedoch keine Garantie für die
			 der bereitgestellten Informationen. Es wird ausdrücklich darauf hingewiesen,
			 anderen Nutzern (widerrechtlich) heruntergeladen bzw. anderweitig verbreitet werden können.
			 liegt nicht beim Landesverband Salzburger Museen und Sammlungen. Überdies wird
			 die rechtswidrige Verwendung von geschütztem Material (im Besonderen Urheber-, Marken-
			und Patentrechte) keine Haftung oder Verantwortlichkeit des Landesverbandes Salzburger Museen und Sammlungen
			auslösen.
			</p>
			<p>
			<strong>§ 2 – Urheberrecht:</strong> Alle Inhalte auf dieser Website sind urheberrechtlich geschützt. Alle Rechte,
			insbesondere das Recht der Vervielfältigung und Verbreitung, vorbehalten. Kein Teil der Website (eines hier
			publizierten Werkes) darf in irgendeiner Form ohne schriftliche Genehmigung des Landesverbandes Salzburger
			Museen und Sammlungen reproduziert oder unter Verwendung elektronischer Systeme gespeichert, verarbeitet,
			vervielfältigt oder verbreitet werden.
			</p>
			<p>
			<strong>§ 3 – Datenschutz:</strong> Die Nutzung der im Rahmen des Impressums veröffentlichen Kontaktdaten des Landesverbandes
			Salzburger Museen und Sammlungen sowie der projektbeteiligten Institutionen und Personen (Postanschrift,
			Telefon- und Faxnummer sowie E-Mail-Adressen) zur Übersendung von nicht ausdrücklich angeforderten
			Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei
			Verstößen gegen dieses Verbot sind ausdrücklich vorbehalten. (Rechtsgrundlage: § 107 TKG; § 7 ECG)
			</p>
			<p>
			<strong>§ 4 – Links:</strong> Die Nutzung aller auf dieser Website angebotenen Links geschieht auf eigene Gefahr und führt
			unter Umständen zum Verlassen der Internetseite. Der Landesverband Salzburger Museen und Sammlungen ist
			nicht verpflichtet, darauf hinzuweisen, wenn seine Seite verlassen wird. Für die vorgefundenen Inhalte
			externer Links übernimmt der Landesverband Salzburger Museen und Sammlungen keinerlei Haftung und Gewähr.
			</p>
			<p>
			<strong>§ 5 – Analyse des Nutzerverhaltens und Cookies:</strong> Diese Webseite verwendet ausschließlich funktionale Cookies.
			Zur statistischen Auswertung der Zugriffe wird Matomo, eine Open-Source-Software verwendet. Um die Analyse
			der Webseitennutzung zu ermöglichen, werden Logdateien ausgewertet. Das Nutzerverhalten wird nicht
			analysiert, wenn im browser "do not track" eingestellt ist. IP-Adressen werden nur anonymisiert
			(pseudonymisiert) gespeichert, es werden somit keine personenbezogenen Daten in diesen Auswertungen
			gespeichert.
			</p>
			<p>
			<strong>§ 6 – Rechtswirksamkeit der Hinweise in §§ 1 – 5:</strong> Dieser Haftungsausschluss bezieht sich auf sämtliche
			Inhalte der Website https://salzburgerregionalmuseen.at/. Sofern Teile oder einzelne Formulierungen der §§ 1
			– 5 der geltenden Rechtslage nicht, nicht mehr oder nicht mehr vollständig entsprechen sollten, bleiben die
			übrigen Teile des Dokuments, ihrem Inhalt und Gültigkeit nach, unberührt. [Salvatorische Klausel]
			</p>

		</div>


	)

}


const SketchfabGallery = () => {

	const {height, width} = useWindowDimensions()

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const setShowMediaOverlay = useStore(state => state.setShowMediaOverlay);

	const hoveredModel3D = useStore(state => state.hoveredModel3D);
	const setHoveredModel3D = useStore(state => state.setHoveredModel3D);

	const media3D = useStore(state => state.media3D)
	const items = useStore(state => state.items)

	const [model, setModel] = useState(0)
	const [url, setUrl] = useState(0)

	const item = items.find((d) => d.id_inventar === media3D[model].id_inventar);

	const max = media3D.length;
//	const max = 20

	useEffect(() => {
		setModel(hoveredModel3D)
	}, [hoveredModel3D])

	useEffect(() => {
		setUrl("https://sketchfab.com/models/" + media3D[model].sid + "/embed")
	}, [model])

	const handleNextButton = (e) => {
		e.stopPropagation()
		mtrack('3Dmodel next: ' + (model + 1))
//		console.log('+++3Dmodel: ' + model)
		setModel(model < media3D.length - 1 ? model + 1 : 0)
		setHoveredModel3D(model < media3D.length - 1 ? model + 1 : 0)
		if (model === max - 1) {
			setShowMediaOverlay(false);
		}
	}

	const handleNextButton2 = (e) => {
		e.stopPropagation()
		mtrack('3Dmodel next: ' + (model + 1))
//		console.log('+++3Dmodel: ' + model)
		setModel(model < media3D.length - 1 ? model + 1 : model)
		setHoveredModel3D(model < media3D.length - 1 ? model + 1 : model)
	}


	const handlePrevButton = (e) => {
		e.stopPropagation()
		mtrack('3Dmodel next: ' + (model - 1))
//		console.log('+++3Dmodel: ' + model)
		setModel(model > 1 ? model - 1 : 0)
		setHoveredModel3D(model > 1 ? model - 1 : 0)
//		if (model === max - 1) {
//			setShowMediaOverlay(false);
//		}
	}


	return (
		<div width='100%'
		     style={{
			     position: 'absolute',
			     left: isMo1 ? isLandscape ? width / 2 - width / 2 * 0.75 : width / 2 - width / 2 * 0.70 : width / 2 - width / 2 * 0.80,
			     top: isMo ? isLandscape ? height / 2 - height / 2 * 0.95 : height / 2 - height / 2 * 0.90 : height / 2 - height / 2 * 0.95,
		     }}>
			{/*{item && <ShowMuseum val={item}></ShowMuseum>}*/}
			{/*{item && <ShowDescription val={item}></ShowDescription>}*/}
			{item && <InfoPanel val={item}></InfoPanel>}

			<div className="sketchfab-embed-wrapper">
				<div style={{
					padding: '1em',
					color: 'white',
					fontSize: isMo1 ? isLandscape ? '1em' : '1em' : '1.2em'
				}}>{item.title}, {item.objectdate}</div>
				<iframe title={item.title}
				        width={width * 0.8}
				        height={isMo ? isLandscape ? height * 0.65 : height * 0.75 : height * 0.75}
				        frameBorder="0" allowFullScreen
				        mozallowfullscreen="true"
				        webkitallowfullscreen="true"
				        allow="autoplay; fullscreen; xr-spatial-tracking"
				        xr-spatial-tracking="true"
				        execution-while-out-of-viewport="true"
					//					        executionwhileoutofviewport="true"
					    execution-while-not-rendered="true"
					//					        executionwhilenotrendered="true"
					    web-share="true"
					    src={url}
					//						src="https://sketchfab.com/models/089d74f15ea84445b2039fbbcd36ab4d/embed"
				></iframe>
				{max <= (isMo1 ? isLandscape ? 20 : 10 : 20) ? (
						<div className={'trackerContainer'}
						     style={{width: isMo1 ? isLandscape ? 'auto' : '90%' : 'auto'}}>
							<div style={{
								padding: '10px',
								alignSelf: 'baseline',
								width: 'auto',
							}}>
								<Tracker
									onClick={(e) => {e.stopPropagation()}}
									//						stations={max}
									stations={max}
								/>
							</div>
							<div className={'helpnext'} onClick={handleNextButton}
							     style={{
								     alignSelf: 'center',
								     fontSize: isMo1 ? isLandscape ? '1.2em' : '1.2em' : '1.5em'
							     }}
							>weiter
							</div>
						</div>
					)
					:
					(
						<div className={'trackerContainer'}
						     style={{width: isMo1 ? isLandscape ? 'auto' : '90%' : 'auto'}}>
							<div className={'helpnext'} onClick={handlePrevButton}
							     style={{
								     alignSelf: 'center',
								     fontSize: isMo1 ? isLandscape ? '1.2em' : '1.2em' : '1.5em',
								     opacity: model < 1 ? 0 : 1
							     }}
							> &lt;
							</div>
							<div style={{
								padding: '10px',
								alignSelf: 'baseline',
								width: 'auto',
							}}>
								{model + 1}/{max}
							</div>
							<div className={'helpnext'} onClick={handleNextButton2}
							     style={{
								     alignSelf: 'center',
								     fontSize: isMo1 ? isLandscape ? '1.2em' : '1.2em' : '1.5em',
								     opacity: model >= max - 1 ? 0 : 1,
							     }}
							> &gt;
							</div>
						</div>
					)
				}

			</div>
		</div>
	)
}


